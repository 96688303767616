import { Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { toast } from "react-toastify"
import Loader from "../../../../modules/common/Loader"
import { INote } from "../../reducers"
import { Close as CloseIcon, Save as SaveIcon } from "@mui/icons-material"

interface IProps {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    patientID: number
    userID: number
    postNewPatientNote: (note: INote) => Promise<unknown>
    getPatient: (id: string) => Promise<unknown>
}

const ModalNotes = (props: IProps) => {

    const [isLoading, setIsLoading] = useState(false)
    const [note, setNote] = useState('')

    const addPatientNote = async () => {
        setIsLoading(true)
        try {
            await props.postNewPatientNote({ note, patientID: props.patientID, userID: props.userID })
            props.getPatient(props.patientID.toString())
            toast.success('Note ajoutée')
            props.setIsOpen(false)
        } catch {
            toast.error('Erreur lors de l\'ajout de la note')
        } finally {
            setIsLoading(false)
        }
    }

    return <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={props.isOpen}
        onClose={() => props.setIsOpen(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
            style: { borderRadius: 20 }
        }}
    >
        <Loader text='Enregistrement de la note' open={isLoading} />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex', position: 'relative', justifyContent: 'center', background: 'linear-gradient(60deg, #84c2ce, #96dcea)', color: 'white', fontWeight: '700', fontSize: '24px' }}>
                {"Ajouter une note"}
                <IconButton sx={{ position: 'absolute', right: '20px' }} onClick={() => props.setIsOpen(false)}>
                    <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                    <TextField
                        name="note"
                        multiline
                        variant="standard"
                        required
                        onChange={(e) => setNote(e.target.value)}
                        sx={{ width: '80%' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '15px' }}>
                    <IconButton onClick={addPatientNote} sx={{ alignSelf: 'right' }}>
                        <SaveIcon />
                    </IconButton>
                </Box>
            </DialogContent>
        </div>
    </Dialog>
}

export default ModalNotes