import DoctorsContainer from '../containers/DoctorsContainer'
import * as doctorsRoutes from './doctors'

export const DoctorsRoutes = [
  {
    key: 'doctors',
    exact: true,
    path: doctorsRoutes.DOCTORS,
    component: DoctorsContainer,
  },
]
