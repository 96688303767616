function AbbottLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 18">
      <g>
        <path
          fill="#159BD7"
          d="M19.078.262H.262v2.906H18.14v.004c.515 0 .93.418.93.937v9.762l-.005.008a.93.93 0 01-.93.93H4.087a.938.938 0 01-.938-.938V9.926c0-.52.422-.938.938-.938h12.086V6.086H3.145A2.886 2.886 0 00.262 8.98v5.836a2.89 2.89 0 002.883 2.899h15.933a2.89 2.89 0 002.883-2.899V3.16A2.892 2.892 0 0019.078.262"
        ></path>
        <path d="M29.793 9.32h1.957l-.969-2.918h-.027zm2.852-4.937l2.253 6.781c.27.824.457 1.016.926 1.016h.461v1.195h-4.922V12.18h.403c.402 0 .742-.051.742-.2 0-.14-.016-.289-.07-.492l-.282-.976h-2.75l-.164.543c-.148.476-.203.675-.203.867 0 .219.473.258.84.258h.387v1.195H26.3V12.18h.46c.473 0 .657-.192.93-1.016l2.254-6.781zm0 0M39.309 10.2c0 1.386.308 2.21 1.062 2.21.762 0 1.066-.824 1.066-2.21 0-1.384-.304-2.208-1.066-2.208-.754 0-1.062.824-1.062 2.207m-2.243-4.148c0-.383-.093-.582-.484-.582h-.621V4.383h3.348v3.375c.496-.57 1.132-.934 1.738-.934 2.023 0 2.777 1.59 2.777 3.375 0 1.79-.754 3.375-2.777 3.375-.852 0-1.606-.297-2.078-1.043l-.39.844h-1.513zm0 0M46.875 10.2c0 1.386.313 2.21 1.066 2.21.758 0 1.067-.824 1.067-2.21 0-1.384-.309-2.208-1.067-2.208-.754 0-1.066.824-1.066 2.207m-2.242-4.148c0-.383-.094-.582-.48-.582h-.622V4.383h3.344v3.375c.5-.57 1.133-.934 1.742-.934 2.024 0 2.778 1.59 2.778 3.375 0 1.79-.754 3.375-2.778 3.375-.851 0-1.61-.297-2.078-1.043l-.394.844h-1.512zm0 0M54.43 10.2c0 1.6.285 2.292 1.093 2.292.809 0 1.09-.691 1.09-2.293 0-1.597-.281-2.289-1.09-2.289-.808 0-1.093.692-1.093 2.29m4.574 0c0 2.171-1.473 3.374-3.48 3.374-2.012 0-3.481-1.203-3.481-3.375 0-2.168 1.469-3.375 3.48-3.375 2.008 0 3.48 1.207 3.48 3.375M68.621 10.309v1.058c0 .621-.133.992-.644.992-.313 0-.457-.234-.457-.52V8.114h1.656V7.027H67.52v-2.52h-1.34c-.11 1.126-.457 2.52-1.809 2.52H62.27v-2.52h-1.336c-.11 1.126-.461 2.52-1.809 2.52v1.086h.906v3.403c0 1.382.766 2.058 2.157 2.058 1.62 0 2.265-.73 2.265-2.058v-1.207h-1.078v1.058c0 .621-.137.992-.645.992-.316 0-.46-.234-.46-.52V8.114h3.011v3.403c0 1.382.766 2.058 2.156 2.058 1.618 0 2.266-.73 2.266-2.058v-1.207zm0 0"></path>
      </g>
    </svg>
  )
}

export default AbbottLogo
