import {
  GET_BILLING,
  GET_BILLABLE_SUMMARY,
  GET_BILLABLE_PATIENT,
  CREATE_BILL,
} from '../constants'
import { BillStatus } from '../services'

export function getBilling(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return { type: GET_BILLING, size, page, resolve, reject }
}

export function getBillablePatient(
  limit: number,
  page: number,
  status: BillStatus,
  resolve: any,
  reject: any,
) {
  return { type: GET_BILLABLE_PATIENT, limit, page, status, resolve, reject }
}

export function getBillableSummary(resolve: any, reject: any) {
  return { type: GET_BILLABLE_SUMMARY, resolve, reject }
}

export function createBill(patientId: number, resolve: any, reject: any) {
  return { type: CREATE_BILL, patientId, resolve, reject }
}
