import { Refresh, ViewColumn, PersonAdd } from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import PersonIcon from '@mui/icons-material/Person'
import { AiOutlineCheckSquare } from 'react-icons/ai'
import {
  Tooltip,
  IconButton,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material'
import AddNewPatientModal from '../AddNewPatientModal'
import { useState } from 'react'
import { ICreatePatient } from '../../reducers'
import { ToolbarInput } from './../../../../utils/ToolbarInput'
import {
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from 'src/modules/doctors/reducers'
import { ICompany } from '../../../center/reducers'
import { gammesList } from '../../../protheses/constants'
import { prosthesisGammeName } from '../../../../utils/patientInfos'
import { companyIcons } from '../../../../utils/constants'
import { canViewPatientData } from '../../../auth/selectors/authSelector'
import { useSelector } from 'react-redux'

export type ToolbarProps = {
  title: string
  onRefresh: () => void
  postAddPatient?: (patient: ICreatePatient) => Promise<number>
  getDoctors: (size: number, page: number) => Promise<unknown>
  addPatientCenter: (id: number) => Promise<unknown>
  doctorId: number
  canCreatePatient: boolean
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  getDoctorAutocomplete: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getCountries: () => Promise<unknown>
  addDoctor: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
  selectedGammes: string[]
  setSelectedGammes: (value: string[]) => void
  makers: ICompany[]
  setMakerId: (value: number) => void
  selectedMakerIds: number[]
  setSelectedMakerIds: (value: number[]) => void
}

export function Toolbar({
  title,
  onRefresh,
  postAddPatient,
  getDoctors,
  addPatientCenter,
  doctorId,
  canCreatePatient,
  searchText,
  setSearchText,
  getDoctorAutocomplete,
  getCountries,
  addDoctor,
  setSelectedGammes,
  makers,
  selectedGammes,
  selectedMakerIds,
  setSelectedMakerIds,
}: ToolbarProps) {
  const apiRef = useGridApiContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openUserDialog, setOpenUserDialog] = useState(false)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openModal = () => {
    setOpenUserDialog(true)
  }

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field === event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }

  const resetAllFilter = () => {
    setSelectedGammes([])
    setSelectedMakerIds([])
    setSearchText('')
  }
  const currentFilter =
    selectedGammes.length > 0 ||
    selectedMakerIds.length > 0 ||
    searchText.length > 0

  const canViewPatientInfos = useSelector(canViewPatientData)

  return (
    <GridToolbarContainer
      sx={{
        background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
        borderRadius: '0px 15px 0px 0px',
      }}
    >
      {openUserDialog && postAddPatient && (
        <AddNewPatientModal
          isOpen={openUserDialog}
          setIsOpen={setOpenUserDialog}
          postAddPatient={postAddPatient}
          getDoctors={getDoctors}
          addPatientCenter={addPatientCenter}
          doctorId={doctorId}
          getCountries={getCountries}
          getDoctorAutocomplete={getDoctorAutocomplete}
          addDoctor={addDoctor}
        />
      )}

      <Box
        ml={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ pr: 10 }}
      >
        <PersonIcon
          sx={{
            marginRight: '1rem',
            padding: '0px 10px 0px 0px',
            color: 'white',
            width: '10% ',
            height: '10%',
          }}
        />

        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 'bold', color: 'white' }}
        >
          {title}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport /> */}
      {/* <GridToolbarFilterButton /> */}
      {!!canCreatePatient && (
        <Tooltip title={'Inscrire un patient'} style={{ marginRight: '5px' }}>
          <IconButton onClick={openModal}>
            <PersonAdd />
          </IconButton>
        </Tooltip>
      )}
      {currentFilter && (
        <Tooltip title={'Filtrer'}>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={resetAllFilter}
          >
            Supprimer filtre(s)
          </Button>
        </Tooltip>
      )}
      <FormControl sx={{ m: 1 }}>
        {!selectedGammes.length && (
          <InputLabel
            shrink={false}
            sx={{
              transform: 'translate(24px, 8px) scale(1)',
            }}
          >
            Type de prothèse
          </InputLabel>
        )}
        <Select
          onChange={(ev) => setSelectedGammes(ev.target.value as string[])}
          style={{
            width: 200,
            height: 40,
            backgroundColor: '#B5E5F0',
            borderWidth: 0,
            borderColor: 'white !important',
            marginRight: 6,
            borderRadius: 8,
            color: 'black',
          }}
          value={selectedGammes}
          renderValue={(selected) => {
            // Map the selected IDs to their corresponding names.
            return selected
              .map((gamme) => prosthesisGammeName(gamme))
              .join(', ')
          }}
          multiple
        >
          {gammesList.map((gamme) => (
            <MenuItem key={gamme.id} value={gamme.name}>
              {selectedGammes.includes(gamme.name) && (
                <AiOutlineCheckSquare
                  size={20}
                  style={{ marginRight: '5px' }}
                />
              )}
              {prosthesisGammeName(gamme.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        {!selectedMakerIds.length && (
          <InputLabel
            shrink={false}
            sx={{
              transform: 'translate(24px, 8px) scale(1)',
            }}
          >
            Fabricant(s)
          </InputLabel>
        )}
        <Select
          multiple
          onChange={(ev) => setSelectedMakerIds(ev.target.value as number[])}
          style={{
            width: 200,
            height: 40,
            backgroundColor: '#B5E5F0',
            borderWidth: 0,
            borderColor: 'white !important',
            marginRight: 6,
            borderRadius: 8,
            color: 'black',
          }}
          value={selectedMakerIds}
          renderValue={(selected) => {
            // Map the selected IDs to their corresponding names.
            return selected
              .map((id) => makers.find((maker) => maker.id === id)?.companyName)
              .join(', ')
          }}
        >
          {makers.map((maker) => {
            return (
              <MenuItem key={maker.id} value={maker.id}>
                {selectedMakerIds.includes(maker.id) && (
                  <AiOutlineCheckSquare
                    size={20}
                    style={{ marginRight: '5px' }}
                  />
                )}
                {companyIcons[maker.id]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <ToolbarInput
        searchText={searchText}
        setSearchText={setSearchText}
        label={
          !canViewPatientInfos
            ? 'ID patient, N° série…'
            : 'ID patient, Nom/Prénom, N° série…'
        }
      />
      <Tooltip title={'Colonnes'}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((column, key) => (
          <MenuItem key={key} onClick={handleHide} id={column.field}>
            <FormControlLabel
              control={<Checkbox checked={!column.hide} />}
              label={column.headerName as string}
            />
          </MenuItem>
        ))}
      </Menu>

      <Tooltip title={'Rafraîchir'}>
        <IconButton onClick={onRefresh}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}
