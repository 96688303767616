import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* getBilling(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.billingService,
      api_key,
      action.page,
      action.size,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_BILLING_SUCCESS,
      Constants.GET_BILLING_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_BILLING_FAILED, error })
  }
}

function* getBillableSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.billableSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_BILLABLE_SUMMARY_SUCCESS,
      Constants.GET_BILLABLE_SUMMARY_FAILED,
    )
    response.data.error ? action.reject(response.data.error) : action.resolve()
  } catch (error) {
    yield put({ type: Constants.GET_BILLABLE_SUMMARY_FAILED, error })
  }
}

function* getBillablePatient(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.billablePatient,
      api_key,
      action.page,
      action.limit,
      action.status,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_BILLABLE_PATIENT_SUCCESS,
      Constants.GET_BILLABLE_PATIENT_FAILED,
    )
    response.data.error ? action.reject(response.data.error) : action.resolve()
  } catch (error) {
    yield put({ type: Constants.GET_BILLABLE_PATIENT_FAILED, error })
  }
}

export function* createBill(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.createBill,
      api_key,
      action.patientId,
    )

    yield call(
      parseResponse,
      response,
      Constants.CREATE_BILL_SUCCESS,
      Constants.CREATE_BILL_FAILED,
    )
    response.data.error
      ? action.reject(response.data.error)
      : action.resolve(response.data)
  } catch (error) {
    yield put({ type: Constants.CREATE_BILL_FAILED, error })
  }
}

export const billingSagas = [
  takeLatest(Constants.GET_BILLING, getBilling),
  takeLatest(Constants.GET_BILLABLE_SUMMARY, getBillableSummary),
  takeLatest(Constants.GET_BILLABLE_PATIENT, getBillablePatient),
  takeLatest(Constants.CREATE_BILL, createBill),
]
