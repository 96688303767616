import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getProsthesisReferences,
  getGammeRange,
  getTransmitterReference,
} from '../actions'
import ProtheseComponent from '../components/ProtheseComponent'
import { getCompany } from '../../../modules/center/actions'
import { getCountry } from '../../../modules/patients/actions'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    makers: state.centersReducer.makers,
    country: state.prosthesisReducer.country,
    prosthesisReferences: state.prosthesisReducer.prosthesisReferences,
    gammeRange: state.prosthesisReducer.gammeRange,
    transmitterReference: state.prosthesisReducer.transmitterReference,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCompany: (
      size: number,
      page: number,
      searchText: string,
      isImplantCenter: boolean,
      type?: string,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getCompany(
            size,
            page,
            searchText,
            isImplantCenter,
            type,
            resolve,
            reject,
          ),
        )
      })
    },
    getCountry: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCountry(resolve, reject))
      })
    },
    getProsthesisReference: (
      searchText: string,
      selectedGammes: string[],
      selectedMakerIds: number[],
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getProsthesisReferences(
            searchText,
            selectedGammes,
            selectedMakerIds,
            resolve,
            reject,
          ),
        )
      })
    },
    getGammeRange: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getGammeRange(size, page, resolve, reject))
      })
    },
    getTransmitterReference: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getTransmitterReference(size, page, resolve, reject))
      })
    },
    getMakers: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCompany(1000, 0, '', false, 'MAKER', resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxProtheses = ConnectedProps<typeof connector>

export default withRouter(connector(ProtheseComponent))
