import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ManufacturersComponent from '../components/ManufacturersComponent'
import { getManufacturers, setFilters } from '../actions'
import { ManufacturerFilter } from '../reducers'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    userSettings: state.alertsReducer.userSettings,
    data: state.manufacturerReducer.data,
    filter: state.manufacturerReducer.filter,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getData: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getManufacturers(resolve, reject))
      })
    },
    setFilters: (filter: ManufacturerFilter) => dispatch(setFilters(filter)),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxManufacturers = ConnectedProps<typeof connector>

export default withRouter(connector(ManufacturersComponent))
