export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function createSearchParams(obj: {
  [key: string]: string | string[]
}): URLSearchParams {
  const params = new URLSearchParams()

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      const value = obj[key] as string[]
      value.forEach((value) => {
        params.append(`${key}[]`, value)
      })
    } else {
      params.append(key, obj[key] as string)
    }
  }

  return params
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (...args: Parameters<T>) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => func(...args), wait)
  }
}
