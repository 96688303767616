import * as Constants from '../constants'
import { IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { parseResponse } from '../../../store/saga/responseParser'

function* getAlerts(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.alertsByPageService,
      api_key,
      action.size,
      action.page,
      action.alertType,
      action.search,
      action.filterState,
      action.gammeIds,
      action.makerIds,
      action.centerIds,
      action.sort,
      action.patientId,
    )

    yield call(
      parseResponse,
      response,
      Constants.TOGGLE_ALERT_SUCCESS,
      Constants.TOGGLE_ALERT_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_ALERT_FAILED, error })
  }
}

function* getAlertCount(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.getAlertCount,
      api_key,
      action.alertType,
      action.search,
      action.filterState,
      action.gammeIds,
      action.makerIds,
      action.centerIds,
      action.patientId,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_ALERT_COUNT_SUCCESS,
      Constants.GET_ALERT_COUNT_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_ALERT_COUNT_FAILED, error })
  }
}

function* getAlertsSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.getAlertsSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_ALERTS_SUMMARY_SUCCESS,
      Constants.GET_ALERTS_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_ALERTS_SUMMARY_FAILED, error })
  }
}

function* getAlertsHistoric(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.alertsHistoricService,
      api_key,
      action.size,
      action.page,
    )

    yield call(
      parseResponse,
      response,
      Constants.TOGGLE_ALERT_HISTORIC_SUCCESS,
      Constants.TOGGLE_ALERT_HISTORIC_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_ALERT_HISTORIC_FAILED, error })
  }
}

function* getTransmission(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.transmissionService,
      api_key,
      action.size,
      action.page,
    )

    yield call(
      parseResponse,
      response,
      Constants.TOGGLE_TRANSMISSION_SUCCESS,
      Constants.TOGGLE_TRANSMISSION_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_TRANSMISSION_FAILED, error })
  }
}

function* getUserSettings() {
  yield put({ type: Constants.TOGGLE_ALERT_USER_SETTINGS_SUCCESS })
}

function* patientExportAction(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  const getUserId = (state: RootState) => state.authReducer.user.id

  try {
    const api_key: string = yield select(getApiKey)
    const userId: number = yield select(getUserId)
    const response: IResponseStatus = yield call(
      Services.patientExportAction,
      api_key,
      action.exportAction,
      action.patientExportId,
      userId,
      action.exportActionComment,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* getPdf(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.getPDF,
      api_key,
      action.exportId,
      action.reportId,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* setPDFViewed(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.setPDFViewed,
      api_key,
      action.exportId,
      action.reportId,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* getAlertActions(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.getAlertActions,
      api_key,
      action.alertId,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_ALERT_ACTIONS_SUCCESS,
      Constants.GET_ALERT_ACTIONS_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_ALERT_ACTIONS_FAILED, error })
  }
}

export const alertSagas = [
  takeLatest(Constants.TOGGLE_ALERT, getAlerts),
  takeLatest(Constants.GET_ALERTS_SUMMARY, getAlertsSummary),
  takeLatest(Constants.TOGGLE_ALERT_HISTORIC, getAlertsHistoric),
  takeLatest(Constants.TOGGLE_TRANSMISSION, getTransmission),
  takeLatest(Constants.TOGGLE_ALERT_USER_SETTINGS, getUserSettings),
  takeLatest(Constants.PATIENT_EXPORT_ACTION, patientExportAction),
  takeLatest(Constants.GET_PDF, getPdf),
  takeLatest(Constants.SET_PDF_VIEWED, setPDFViewed),
  takeLatest(Constants.GET_ALERT_ACTIONS, getAlertActions),
  takeEvery(Constants.GET_ALERT_COUNT, getAlertCount),
]
