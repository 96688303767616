import {
  CreateCompanyDto,
  CreateConstructorKeyDto,
  CreateUserAddressDto,
  CreateUserDto,
  CreateUserRoleDto,
} from 'src/helpers/dto'
import { CORE_URL, REFACTOR_URL } from '../../../helpers/env'

export const companyService = async (
  token: string,
  page: number,
  size: number,
  searchText: string,
  isImpCenter?: boolean,
  type?: string,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/companies?page=${page}&limit=${size}${
    isImpCenter ? '&isImplantCenter=true' : ''
  }${type ? '&type=' + type : '&type=CENTER'}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  const res = await fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })

  return { data: res, type, status: res?.status }
}

export const getCompaniesSummary = (token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/companies/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const getCompanyById = (companyId: number, token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/companies/${companyId}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const createCompany = (token: string, body: CreateCompanyDto) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/company`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const updateCompany = (
  token: string,
  id: number,
  body: CreateCompanyDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/company`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body, id }),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const deleteCompany = (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/company/${id}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }
  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status

      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const createCenterUser = (token: string, body: CreateUserDto) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const updateCenterUser = (
  token: string,
  id: number,
  body: CreateUserDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body, id }),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      console.log(data)
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const deleteUser = (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }
  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

// TODO : create user module and move this function there
export const findRoles = (token: string) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user_roles`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const findCountries = (token: string) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/country`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const createUserRole = (
  token: string,
  userId: number,
  body: CreateUserRoleDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${userId}/role`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      console.log(response)
      status = response.status
      return response.json()
    })
    .then((data) => {
      console.log(data)
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const createUserAddress = (
  token: string,
  userId: number,
  body: CreateUserAddressDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${userId}/address`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      console.log(response)
      status = response.status
      return response.json()
    })
    .then((data) => {
      console.log(data)
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const updateUserAddress = (
  token: string,
  userId: number,
  body: CreateUserAddressDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${userId}/address`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body }),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const findContacts = (
  token: string,
  centerId: number,
  page: number = 0,
  imit: number = 100,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/users?compId=${centerId}&limit=${imit}&page=0`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }
  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => ({ data, status }))
    .catch((e) => {
      console.log(e)
    })
}

export const createConstructorKey = (
  token: string,
  body: CreateConstructorKeyDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/exports_keys`

  const formData = new FormData()

  formData.append('file', body.file)
  formData.append('keyPassword', body.filePassword)
  formData.append('centerId', body.centerId.toString())
  formData.append('makerId', body.makerId.toString())
  formData.append('clientSecret', body.clientSecret)
  formData.append('clientId', body.clientId.toString())

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: formData,
  }
  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const updateConstructorKey = (
  token: string,
  id: number,
  body: CreateConstructorKeyDto,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/exports_keys`

  const formData = new FormData()

  formData.append('file', body.file)
  formData.append('keyPassword', body.filePassword)
  formData.append('centerId', body.centerId.toString())
  formData.append('makerId', body.makerId.toString())
  formData.append('clientSecret', body.clientSecret)
  formData.append('exportsKeyId', id.toString())
  formData.append('clientId', body.clientId.toString())

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: formData,
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      console.log(response)
      return response.json()
    })
    .then((data) => {
      console.log(data)
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const findConstructorKeys = (token: string, centerId: number) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/exports_keys?centerId=${centerId}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      console.log(response)
      status = response.status
      return response.json()
    })
    .then((data) => {
      console.log(data)
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const deleteConstructorKey = (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/exports_keys/${id}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }
  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      console.log(response)
      status = response.status
      return response.json()
    })
    .then((data) => {
      console.log(data)
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}
