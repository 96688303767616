import AbbottLogo from '../assets/icon/AbbottLogo'
import BiotronikLogo from '../assets/icon/BiotronikLogo'
import MedTronicLogo from '../assets/icon/MedTronicLogo'
import MicroportLogo from '../assets/icon/MicroportLogo'
import BostonLogo from '../modules/manufacturers/components/assets/BostonLogo'

export const companyIcons: { [key: number]: React.ReactElement } = {
  2: <AbbottLogo width={70} height={40} />,
  3: <BiotronikLogo width={70} height={40} />,
  4: <BostonLogo width={70} height={40} />,
  5: <MedTronicLogo width={70} height={40} />,
  6: <MicroportLogo width={70} height={40} />,
}

export const companyIconsNameMap: { [key: string]: React.ReactElement } = {
  MICROPORT: <MicroportLogo width={70} height={40} />,
  MEDTRONIC: <MedTronicLogo width={70} height={40} />,
  BOSTON: <BostonLogo width={70} height={40} />,
  BIOTRONIK: <BiotronikLogo width={70} height={40} />,
  ABBOTT: <AbbottLogo width={70} height={40} />,
}

export const companyUrls: { [key: string]: string } = {
  MICROPORT: 'https://www.microport-smartview.com/BOWebUI/Public/login.aspx',
  MEDTRONIC: 'https://europe.medtroniccarelink.net/carelink.web/about',
  BOSTON:
    'https://www.latitudenxt.bostonscientific-international.com/clinic/login',
  BIOTRONIK:
    'https://www.biotronik-homemonitoring.com/hmsc_guiWeb/user/site/SignIn.jsf',
  ABBOTT: 'https://www.merlin.net/web/chakravyuh/login?#!',
}

export const companyClickableIconsNameMap: {
  [key: string]: React.ReactElement
} = {
  MICROPORT: (
    <a
      href={companyUrls['MICROPORT']}
      target="_blank"
      rel="noopener noreferrer"
    >
      <MicroportLogo width={70} height={40} />
    </a>
  ),
  MEDTRONIC: (
    <a
      href={companyUrls['MEDTRONIC']}
      target="_blank"
      rel="noopener noreferrer"
    >
      <MedTronicLogo width={70} height={40} />
    </a>
  ),
  BOSTON: (
    <a href={companyUrls['BOSTON']} target="_blank" rel="noopener noreferrer">
      <BostonLogo width={70} height={40} />
    </a>
  ),
  BIOTRONIK: (
    <a
      href={companyUrls['BIOTRONIK']}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BiotronikLogo width={70} height={40} />
    </a>
  ),
  ABBOTT: (
    <a href={companyUrls['ABBOTT']} target="_blank" rel="noopener noreferrer">
      <AbbottLogo width={70} height={40} />
    </a>
  ),
}

export const defaultCenterAdminPw = 'Promant!sExt3rna1'
