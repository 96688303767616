import { Dialog, DialogContent, DialogTitle, IconButton, Box, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { IAlertPatient } from '../../reducers';
import Loader from '../../../common/Loader';
import { toast } from 'react-toastify';
import { Send as SendIcon } from '@mui/icons-material';

interface IProps {
    alert: IAlertPatient,
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    patientExportAction: (exportAction: 'VIEW' | 'COMMENT' | 'CHECK', patientExportId: number, exportActionComment?: string) => Promise<unknown>
}

const WritingModal = (props: IProps) => {

    const [loading, setLoading] = useState(false);
    const [exportActionComment, setExportActionComment] = useState('');

    const saveComment = async () => {
        setLoading(true)
        try {
            await props.patientExportAction('COMMENT', props.alert.id, exportActionComment)
            toast.success('Commentaire enregistré')
            props.setIsOpen(false)
        } catch {
            toast.error('Impossible d\'enregistrer le commentaire')
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        props.setIsOpen(false);
    }

    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
            style: { borderRadius: 20 }
        }}
    >

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex', position: 'relative', justifyContent: 'center', background: 'linear-gradient(60deg, #84c2ce, #96dcea)', color: 'white', fontWeight: '700', fontSize: '24px' }}>
                {"Ajouter un commentaire"}
                <IconButton sx={{ position: 'absolute', right: '20px' }} onClick={handleClose}>
                    <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '20px 30px', height: '100%' }}>
                <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                    <Loader open={loading} text="Enregistrement du commentaire" />

                    <TextField
                        autoFocus
                        multiline
                        value={exportActionComment}
                        onChange={(event) => setExportActionComment(event.target.value)}
                        sx={{ width: '100%' }}
                        rows={6}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: '15px' }}>
                        <IconButton onClick={saveComment}>
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>
        </div >
    </Dialog >
}

export default WritingModal