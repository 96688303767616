import { RootState } from '../../../store'
import { getFullPdf } from '../actions'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FullPdfComponent from '../components/FullPdfComponent'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  console.log(`mapDispatchToProps container`)
  return {
    getFullPdf: (path: string, url: string) => {
      return new Promise((resolve: (pdf: Blob) => void, reject) => {
        dispatch(getFullPdf(path, url, resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxFullPdf = ConnectedProps<typeof connector>

export default withRouter(connector(FullPdfComponent))
