import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoginComponent from '../components/LoginComponent'
import { logIn, loginPSC } from '../actions'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    company: state.authReducer.company,
    doctor: state.authReducer.doctor,
    token: state.authReducer.token,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logIn: (mail: string, pwd: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(logIn(mail, pwd, resolve, reject))
      })
    },
    loginPSC: (code: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(loginPSC(code, resolve, reject))
      })
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxLogin = ConnectedProps<typeof connector>

export default withRouter(connector(LoginComponent))
