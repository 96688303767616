import { composeWithDevTools } from 'redux-devtools-extension'
import { applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import history from '../routes/history'
import rootReducer from './reducers'
import { rootSaga } from './saga'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader', 'router', 'context'],
}

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const composedEnhancer = composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk),
  )

  return {
    ...createStore(persistedReducer, composedEnhancer),
    run: sagaMiddleware.run,
  }
}

export const store = configureStore()
store.run(rootSaga)

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
