import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../../../common/Loader'
import { IImplantationList } from '../../reducers'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedImplantation: IImplantationList
  updateReasons: (implantationId: number, reasons: number[]) => Promise<unknown>
}

const ModalModifyIndications = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [indications, setIndications] = useState<number[]>([])

  useEffect(() => {
    // Create a map to hold the most recent reason for each reason_id
    const mostRecentReasons = new Map()

    // Filter out deleted reasons, sort them by created_at, and update the map
    props.selectedImplantation.implantReasons
      .filter((reason) => !reason.deleted_at)
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      ) // Sort in descending order of creation time
      .forEach((reason) => {
        // Only update the map if this is the first time we're seeing this reason_id
        // This ensures we're only keeping the most recent reason for each id
        if (!mostRecentReasons.has(reason.reason_id)) {
          mostRecentReasons.set(reason.reason_id, reason)
        }
      })

    // Convert the map back into an array of reason_ids
    const reasonsTemp = Array.from(mostRecentReasons.values()).map(
      (reason) => reason.reason_id,
    )

    // Update state
    setIndications(reasonsTemp)
  }, [props.selectedImplantation])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      props.updateReasons(props.selectedImplantation.id, indications)
      toast.success('Indications modifiées')
      props.setIsOpen(false)
    } catch {
      toast.error("Erreur lors de l'enregistrement des modifications")
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      props.updateReasons(props.selectedImplantation.id, [])
      toast.success('Indications supprimées')
      setModalDeleteOpen(false)
      props.setIsOpen(false)
    } catch {
      toast.error('Erreur lors de la suppression des indications')
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeIndications = (indication: number) => {
    setIndications([indication])
  }

  const ModalDelete = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
              color: 'white',
              fontWeight: '700',
              fontSize: '24px',
            }}
          >
            {'Confirmer'}
            <IconButton
              sx={{ position: 'absolute', right: '20px' }}
              onClick={() => setModalDeleteOpen(false)}
            >
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ paddingTop: '20px' }}>
              Êtes-vous sûr de vouloir supprimer les indications ?
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: '30px',
                height: '30px',
              }}
            >
              <Button
                className="group-button"
                onClick={() => setModalDeleteOpen(false)}
              >
                Retour
              </Button>
              <Button className="group-button-success" onClick={handleDelete}>
                Confirmer
              </Button>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement des modifications" open={isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          Modifier les indications
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div style={{ marginTop: '20px' }}>
            <span>Indications :</span>

            {/* prosthesis.gamme.stim.cardiac */}
            {props.selectedImplantation.prosthesisObject?.reference
              ?.prosthesisGamme?.string_key ===
              'prosthesis.gamme.stim.cardiac' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '20px',
                  marginTop: '10px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(11)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Insuffisance cardiaque"
                  checked={indications.includes(11)}
                  value={11}
                  onChange={() => handleChangeIndications(11)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(1)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="BAV de haut degré"
                  checked={indications.includes(1)}
                  value={1}
                  onChange={() => handleChangeIndications(1)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(2)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Dysfonction Sinusale Symptomatique"
                  checked={indications.includes(2)}
                  value={2}
                  onChange={() => handleChangeIndications(2)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(3)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Maladie Rythmique Atriale"
                  checked={indications.includes(3)}
                  value={3}
                  onChange={() => handleChangeIndications(3)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(4)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Brady-arythmie"
                  checked={indications.includes(4)}
                  value={4}
                  onChange={() => handleChangeIndications(4)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(5)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Autre"
                  checked={indications.includes(5)}
                  value={5}
                  onChange={() => handleChangeIndications(5)}
                />
              </Box>
            )}

            {/* prosthesis.gamme.defib.cardiac */}
            {props.selectedImplantation.prosthesisObject?.reference
              ?.prosthesisGamme?.string_key ===
              'prosthesis.gamme.defib.cardiac' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '20px',
                  marginTop: '10px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(6)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Prévention primaire"
                  checked={indications.includes(6)}
                  value={6}
                  onChange={() => handleChangeIndications(6)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(7)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Prévention secondaire"
                  checked={indications.includes(7)}
                  value={7}
                  onChange={() => handleChangeIndications(7)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(12)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Autre"
                  checked={indications.includes(12)}
                  value={12}
                  onChange={() => handleChangeIndications(12)}
                />
              </Box>
            )}

            {/* prosthesis.gamme.monit.implant */}
            {props.selectedImplantation.prosthesisObject?.reference
              ?.prosthesisGamme?.string_key ===
              'prosthesis.gamme.monit.implant' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '20px',
                  marginTop: '10px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(8)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Lypothymies(s), syncope(s)"
                  checked={indications.includes(8)}
                  value={8}
                  onChange={() => handleChangeIndications(8)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(9)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="AVC cryptogénique(s)"
                  checked={indications.includes(9)}
                  value={9}
                  onChange={() => handleChangeIndications(9)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: indications.includes(10)
                          ? '#84c2ce !important'
                          : '',
                      }}
                    />
                  }
                  label="Autre"
                  checked={indications.includes(10)}
                  value={10}
                  onChange={() => handleChangeIndications(10)}
                />
              </Box>
            )}
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <IconButton onClick={() => setModalDeleteOpen(true)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={handleSubmit}>
              <SaveIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
      <ModalDelete />
    </Dialog>
  )
}

export default ModalModifyIndications
