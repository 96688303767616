import {
  TOGGLE_FORGOT_PWD,
  TOGGLE_LOGIN,
  TOGGLE_LOGIN_PSC,
  TOGGLE_LOGOUT,
  TOGGLE_NEWPWD,
} from '../constants'

export function logIn(mail: string, pwd: string, resolve: any, reject: any) {
  return {
    type: TOGGLE_LOGIN,
    mail,
    pwd,
    resolve,
    reject,
  }
}

export function loginPSC(code: string, resolve: any, reject: any) {
  return {
    type: TOGGLE_LOGIN_PSC,
    code,
    resolve,
    reject,
  }
}

export function forogtPwd(mail: string, resolve: any, reject: any) {
  return {
    type: TOGGLE_FORGOT_PWD,
    mail,
    resolve,
    reject,
  }
}

export function logOut(resolve: any, reject: any) {
  return {
    type: TOGGLE_LOGOUT,
    resolve,
    reject,
  }
}

export function newPwd(mail: string, pwd: string, resolve: any, reject: any) {
  return {
    type: TOGGLE_NEWPWD,
    mail,
    pwd,
    resolve,
    reject,
  }
}
