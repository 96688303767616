import { GET_FULL_PDF } from '../constants'

export function getFullPdf(
  path: string,
  url: string,
  resolve: any,
  reject: any,
) {
  return { type: GET_FULL_PDF, resolve, reject, path, url }
}
