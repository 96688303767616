import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {RootState} from 'src/store';
import {IDoctor} from '../../reducers';
import {DataGridPro, GridColumns, GridValueGetterParams} from '@mui/x-data-grid-pro';
import Loader from '../../../common/Loader';
import {Box, Tabs, Tab, Tooltip} from '@mui/material';
import {ToolbarInput} from '../../../../utils/ToolbarInput';
import {DoctorType, IDoctorSummary, IFoundDoctor, INewDoctorAddress, INewDoctorInfos, INewDoctorStep1} from '../../../../modules/doctors/reducers';
import {getDoctorType} from '../../../../utils/addPatient';
import PaginationComponent from '../../../../modules/common/PaginationComponent';
import {CustomTooltip} from '../../../../utils/CustomTooltip';
import AddNewDoctorModal from '../../../doctors/components/AddNewDoctorModal';
import {IconButton} from '@mui/material';
import {PersonAdd} from '@mui/icons-material';

interface IProps {
    getDoctors: (size: number, page: number, doctorType: DoctorType, search: string) => Promise<unknown>;
    handleReceiverSelection: (mail: string) => void;
    getDoctorAutocomplete: (name: string, page: number, limit: number) => Promise<IFoundDoctor[]>;
    getCountries: () => Promise<unknown>;
    addDoctor: {
        step1: (doctor: INewDoctorStep1) => Promise<number>;
        step2: (id: number) => Promise<unknown>;
        step3: (id: number, address: INewDoctorAddress) => Promise<unknown>;
        step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>;
    };
}

const DoctorsGrid = (props: IProps) => {

    const doctors: {data: IDoctor[], totalPages: number} = useSelector((state: RootState) => state.doctorsReducer.doctors ? state.doctorsReducer.doctors : {data: [], totalPages: 0});
    const summary: IDoctorSummary = useSelector((state: RootState) => state.doctorsReducer.summary ? state.doctorsReducer.summary : {billingCount: 0, cardiologCount: 0, implantCount: 0, doctorCount: 0});
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(20);
    const [activePage, setActivePage] = useState(1);
    const [tab, setTab] = useState<0 | 1 | 2 | 3>(0);
    const [searchText, setSearchText] = useState('');
    const [openAddDoctor, setOpenAddDoctor] = useState(false);

    const getType = () => {
        switch (tab) {
            case 0: return 'Implant'
            case 1: return 'Cardiolog'
            case 2: return 'FamilyDoctor'
            case 3: return 'Billing'
            default: return 'Implant'
        }
    }

    const fetchDatas = (loader: boolean) => {
        if (loader) setLoading(true)
        try {
            props.getDoctors(rows, activePage - 1, getType(), searchText)
        } catch {
            toast.error('Impossible de charger les médecins')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDatas(true)
    }, [tab, activePage, rows]) //eslint-disable-line

    useEffect(() => {
        setActivePage(1)
        fetchDatas(false)
    }, [searchText]) //eslint-disable-line

    const columns: GridColumns = [
        {
            field: 'col1',
            headerName: 'Fonction',
            flex: 0.8,
            renderCell: (params) => params.row.doctor ?
                <Box sx={{display: 'flex', flexDirection: 'column', padding: '7px 0'}}>
                    {getDoctorType(params.row.doctor).map((type) => {
                        return <span style={{margin: '7px 0'}}>{type}</span>
                    })}
                </Box> : ''
        },
        {
            field: 'col2',
            headerName: 'Nom Prénom',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.firstName && params.row.userName) ? `${params.row.userName} ${params.row.firstName}` : ''
        },
        {
            field: 'col3',
            headerName: 'Adresse',
            flex: 1,
            renderCell: (params) =>
                <Box sx={{padding: '15px 0', minHeight: '52px'}}>
                    {params.row.addresses && params.row.addresses.map((address: any) =>
                        <CustomTooltip text={<p style={{margin: 0, fontSize: '13px'}}>{`${address.street ? address.street : ''} ${address.zipCode ? address.zipCode : ''} ${address.city ? address.city : ''}`}</p>}>
                            <Box sx={{margin: '5px 0'}}>
                                {`${address.street ? address.street : ''} ${address.zipCode ? address.zipCode : ''} ${address.city ? address.city : ''}`}
                            </Box>
                        </CustomTooltip>
                    )}
                </Box>
        },
        {
            field: 'col4',
            headerName: 'Téléphone',
            flex: 0.7,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.phone ? `${params.row.phone}` : '',
        },
        {
            field: 'col5',
            headerName: 'Email',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.mail ? `${params.row.mail}` : ''
        },
    ]

    function a11yProps (index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <Box sx={{height: 'calc(100% - 100px)', width: '100%'}}>
            <Loader open={loading} text="Chargement des médecins" />
            <Tabs
                TabIndicatorProps={{
                    style: {
                        backgroundColor: '#ffa726',
                        display: 'flexWrap',
                    },
                }}
                onChange={(_, newTab) => setTab(newTab)}
                textColor="primary"
                value={tab}
            >
                <Tab
                    label={`Cardiologues implanteurs : ${summary.implantCount}`}
                    {...a11yProps(0)}
                    wrapped={false}
                />
                <Tab
                    label={`Cardiologues traitants : ${summary.cardiologCount}`}
                    {...a11yProps(0)}
                    wrapped={false}
                />
                <Tab
                    label={`médecins traitants : ${summary.doctorCount}`}
                    {...a11yProps(0)}
                    wrapped={false}
                />
                <Tab
                    label={`Télésurveillance : ${summary.billingCount}`}
                    {...a11yProps(0)}
                    wrapped={false}
                />
            </Tabs>
            <Box sx={{padding: '3px 15px', height: '100%'}}>
                <Box sx={{padding: '10px', display: 'flex', justifyContent: 'end', background: 'linear-gradient(60deg, #84c2ce, #96dcea)', borderRadius: '15px 15px 0px 0px'}}>
                    <Tooltip title={'Inscrire un médecin'} style={{marginRight: '5px'}}>
                        <IconButton onClick={() => setOpenAddDoctor(true)}>
                            <PersonAdd />
                        </IconButton>
                    </Tooltip>
                    <ToolbarInput searchText={searchText} setSearchText={setSearchText} />
                </Box>
                <DataGridPro
                    rows={doctors.data}
                    columns={columns}
                    onRowClick={(selected) => props.handleReceiverSelection(selected.row.mail)}
                    getRowHeight={() => 'auto'}
                    hideFooter
                    disableColumnMenu
                    disableSelectionOnClick
                    sx={{
                        border: 'none',
                        cursor: 'pointer',
                        height: 'calc(100% - 150px)'
                    }}
                />
                <PaginationComponent pages={doctors.totalPages} activePage={activePage} setActivePage={setActivePage} rowsPerPage={rows} setRowsPerPage={setRows} />
            </Box>
            <AddNewDoctorModal
                isOpen={openAddDoctor}
                setIsOpen={setOpenAddDoctor}
                getDoctorAutocomplete={props.getDoctorAutocomplete}
                getCountries={props.getCountries}
                addDoctor={props.addDoctor}
            />
        </Box>
    )
}

export default DoctorsGrid