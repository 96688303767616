import { API_URL } from '../constants'
import { REFACTOR_URL } from '../../../helpers/env'
export enum BillStatus {
  TO_BILL = 'TO_BILL',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export const billingService = (token: string, page: number, size: number) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/billable?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const billablePatient = (
  token: string,
  limit: number,
  page: number,
  billStatus: BillStatus,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/billable/patient?page=${page}&limit=${limit}&status=${billStatus}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const billableSummary = (token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/billing/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const createBill = (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/billing`

  const parameters: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_URL}`,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ id }),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      if (response.body !== null) {
        return response.json()
      }
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
