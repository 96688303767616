import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PatientsComponent from '../components/PatientsComponent'
import {
  getPatient,
  getCountry,
  getProsthesisGammeRange,
  postAddPatient,
  addPatientCenter,
  getPatientsSummary,
} from '../actions'
import { ICreatePatient, PatientType } from '../reducers'
import {
  addDoctorStep1,
  addDoctorStep2,
  addDoctorStep3,
  addDoctorStep4,
  getDoctorAutocomplete,
  getDoctors,
  getDoctorsSummary,
} from '../../doctors/actions'
import {
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from 'src/modules/doctors/reducers'
import { getCompany } from '../../center/actions'
import { GridSortDirection } from '@mui/x-data-grid-pro'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    userSettings: state.alertsReducer.userSettings,
    patients: state.patientsReducer.patients,
    country: state.patientsReducer.country,
    company: state.patientsReducer.company,
    summary: state.patientsReducer.summary,
    prosthesisGammeRange: state.patientsReducer.prosthesisGammeRange,
    waiting: state.patientsReducer.waiting,
    archived: state.patientsReducer.archived,
    toComplete: state.patientsReducer.toComplete,
    doctorCount: state.doctorsReducer.summary.doctorCount,
    makers: state.centersReducer.makers.data,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPatient: (
      size: number,
      page: number,
      patientsType: PatientType,
      searchText: string,
      gammeIds: string[],
      makerIds: number[],
      sort: {
        field: string
        sort: GridSortDirection
      } | null,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getPatient(
            size,
            page,
            patientsType,
            searchText,
            gammeIds,
            makerIds,
            sort,
            resolve,
            reject,
          ),
        )
      })
    },
    getPatientsSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getPatientsSummary(resolve, reject))
      })
    },
    getCountry: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCountry(resolve, reject))
      })
    },
    getProsthesisGammeRange: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getProsthesisGammeRange(size, page, resolve, reject))
      })
    },
    postAddPatient: (patient: ICreatePatient) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(postAddPatient(patient, resolve, reject))
      })
    },
    getDoctors: (
      size: number,
      page: number,
      doctorType: string,
      search: string,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctors(size, page, doctorType, search, resolve, reject))
      })
    },
    addPatientCenter: (id: number) => {
      return new Promise((resolve: (id: number) => void, reject: any) => {
        dispatch(addPatientCenter(id, resolve, reject))
      })
    },
    getDoctorsSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctorsSummary(resolve, reject))
      })
    },
    getDoctorAutocomplete: (name: string, page: number, limit: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctorAutocomplete(name, page, limit, resolve, reject))
      })
    },
    getCountries: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCountry(resolve, reject))
      })
    },
    addDoctor: {
      step1: (doctor: INewDoctorStep1) => {
        return new Promise((resolve: (id: number) => void, reject: any) => {
          dispatch(addDoctorStep1(doctor, resolve, reject))
        })
      },
      step2: (id: number) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep2(id, resolve, reject))
        })
      },
      step3: (id: number, address: INewDoctorAddress) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep3(id, address, resolve, reject))
        })
      },
      step4: (id: number, doctorInfos: INewDoctorInfos) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep4(id, doctorInfos, resolve, reject))
        })
      },
    },
    getMakers: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCompany(1000, 0, '', false, 'MAKER', resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxPatients = ConnectedProps<typeof connector>

export default withRouter(connector(PatientsComponent))
