import { IHistoryList, ITreatmentList } from 'src/modules/patients/reducers'
import AbbottLogo from '../assets/icon/AbbottLogo.png'
import BiotronikLogo from '../assets/icon/BiotronikLogo.png'
import BostonLogo from '../assets/icon/BostonLogo.png'
import MedTronicLogo from '../assets/icon/MedTronicLogo.png'
import MicroportLogo from '../assets/icon/MicroportLogo.png'

export const statusName = (value: string) => {
  switch (value) {
    case 'CRM':
      return 'Patient suivi pour prothèse cardiaque'
    case 'ARCHIVED':
      return 'Patient Archivé'
    case 'DIED':
      return 'Patient décédé'
    case 'HF':
      return 'Patrient suivi pour une insuffisance cardiaque'
    case 'CRM_HF':
      return 'Patient suivi pour une prothèse et une insuffisance cardiaque'
    case 'NOT_FOLLOWED':
      return 'Patient non suivi'
  }
}
export const prosthesisRangeName = (value: string) => {
  switch (value) {
    case 'prosthesis.range.simple.chamber':
      return 'Simple Chambre'
    case 'prosthesis.range.dual.chamber':
      return 'Double Chambre'
    case 'prosthesis.range.triple.chamber':
      return 'Triple Chambre'
    case 'prosthesis.range.under.skin':
      return 'Sous Cutané'
    case 'prosthesis.range.without.lead':
      return 'Sans Sonde'
    case 'prosthesis.range.null':
      return 'Segment non défini'
  }
}

export const prosthesisGammeName = (value: string) => {
  switch (value) {
    case 'prosthesis.gamme.defib.cardiac':
      return 'Défibrillateur'
    case 'prosthesis.gamme.monit.implant':
      return 'Moniteur'
    case 'prosthesis.gamme.stim.cardiac':
      return 'Stimulateur'
    case 'prosthesis.gamme.null':
      return 'Gamme non définie'
  }
}

export const transmitterAgreement = (value: boolean) => {
  switch (value) {
    case true:
      return 'Oui'
    case false:
      return 'Non'
  }
}

export const implantReasonSub = (value: number) => {
  switch (value) {
    case 6:
      return 'Prévention primaire'
    case 7:
      return 'Prévention secondaire'
    case 4:
      return 'Brady-arythmie'
    case 5:
      return 'Autre'
    case 12:
      return 'Autre'
    case 10:
      return 'Autre'
    case 8:
      return 'Lipothymie(s), syncope(s)'
    case 11:
      return 'Insuffisance cardiaque'
    case 2:
      return 'Dysfonction Sinusale Symptomatique'
    case 1:
      return 'BAV de haut degré'
    case 9:
      return 'AVC cryptogénique(s)'
    case 3:
      return 'Maladie Rythmique Atriale'
    default:
      return ''
  }
}

export const countryName = (value: number) => {
  switch (value) {
    case 1:
      return 'France'
    case 2:
      return 'Allemagne'
  }
}

export const getHistoryName = (history: IHistoryList): string => {
  switch (history.historyTypeId) {
    // case 1: return 'Fibrillation atriale'
    case 2:
      return 'Tachycardie Atriale'
    case 3:
      return 'Flutter Atrial'
    case 4:
      return 'Tachycardie Ventriculaire'
    case 5:
      return 'Fibrillation Ventriculaire'
    case 6:
      return 'Coronaropathie'
    case 7:
      return 'Cardiopathie dilatée'
    case 8:
      return 'Hypertension Artérielle'
    case 9:
      return 'Diabète'
    case 11:
      return 'Stimulo-dépendance'
    case 12:
      return 'Aucun antécédent'
    case 13:
      return 'Fibrillation atriale persistante'
    case 14:
      return 'Fibrillation atriale paroxystique permanente'
    case 18:
      return 'Fibrillation atriale paroxistique'
    default:
      return ''
  }
}

export const getTreatmentName = (treatment: ITreatmentList): string => {
  switch (treatment.treatmentTypeId) {
    // case 1: return 'Anticoagulants's
    case 2:
      return 'Anti-agrégants plaquettaires'
    case 3:
      return 'Bêta-bloquants'
    case 4:
      return 'Inhibiteurs calciques bradycardisants'
    case 6:
      return 'Anticoagulants AVK'
    case 7:
      return 'Aucun traitement'
    case 8:
      return 'Digoxine'
    case 9:
      return 'Amiodarone'
    case 10:
      return 'Anti-arythmique classe Ic'
    case 11:
      return 'Sotalol'
    case 12:
      return 'Anticoagulants AOD'
    case 13:
      return 'Autres anticoagulants'
    case 14:
      return 'Autre(s) anti-arythmique(s)'
    default:
      return ''
  }
}

export const getMakerImage = (maker: string) => {
  switch (maker) {
    case 'ABBOTT':
      return (
        <img
          src={AbbottLogo}
          style={{ display: 'block', width: '100%', height: '100%' }}
          alt="Abbott logo"
        />
      )
    case 'BIOTRONIK':
      return (
        <img
          src={BiotronikLogo}
          style={{ display: 'block', width: '100%', height: '100%' }}
          alt="Biotronik logo"
        />
      )
    case 'BOSTON':
      return (
        <img
          src={BostonLogo}
          style={{ display: 'block', width: '100%', height: '100%' }}
          alt="Boston logo"
        />
      )
    case 'MEDTRONIC':
      return (
        <img
          src={MedTronicLogo}
          style={{ display: 'block', width: '100%', height: '100%' }}
          alt="MedTronic logo"
        />
      )
    case 'MICROPORT':
      return (
        <img
          src={MicroportLogo}
          style={{ display: 'block', width: '100%', height: '100%' }}
          alt="Microport logo"
        />
      )
    default:
      return <></>
  }
}
