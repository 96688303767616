/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Loader from '../../../../modules/common/Loader'
import { CreateUserAddressDto, CreateUserDto } from '../../../../helpers/dto'
import { useAppSelector } from '../../../../store'
import { createUser, findCountries, findRoles, updateUser } from '../../thunks'
import { defaultCenterAdminPw } from '../../../../utils/constants'
import { useDispatch } from 'react-redux'
import { IUser } from 'src/modules/messaging/reducers'

const centerContactSchema = yup.object().shape({
  job: yup.string().required('Fonction est obligatoire'),
  firstName: yup.string().required('Prénom est obligatoire'),
  lastName: yup.string().required('Nom est obligatoire'),
  email: yup.string().email('Email invalide').required('Email est obligatoire'),
  phone: yup
    .string()
    .required('Téléphone est obligatoire')
    .matches(
      /(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})\s?(\d{2})/,
      'Téléphone invalide',
    ),
  address: yup.string().required('Adresse est obligatoire'),
  zipCode: yup
    .string()
    .required('Code postal est obligatoire')
    .matches(/^\d{5}$/, 'Code postal doit contenir 5 chiffres'),
  city: yup.string().required('Ville est obligatoire'),
  country: yup.number().required('Pays est obligatoire'),
})

interface IProps {
  open: boolean
  setOpen: (isOpen: boolean) => void
  centerId: number
  editingUser?: IUser
}

export default function ResponsiveDialog(props: IProps) {
  const dispatch = useDispatch()

  const countries = useAppSelector(
    ({ centersReducer }) => centersReducer.countries,
  )
  const roles = useAppSelector(({ centersReducer }) => centersReducer.roles)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(centerContactSchema),
    mode: 'onChange',
    defaultValues: props.editingUser && {
      job: props.editingUser.title,
      firstName: props.editingUser.firstName,
      lastName: props.editingUser.userName,
      email: props.editingUser.mail,
      phone: props.editingUser.phone,
      address: props.editingUser.addresses[0].street,
      zipCode: props.editingUser.addresses[0].zipCode,
      city: props.editingUser.addresses[0].city,
      country: props.editingUser.addresses[0].countryId.toString(),
    },
  })

  const onSubmit = (values: any) => {
    const userDto: CreateUserDto = {
      title: values.job,
      firstName: values.firstName,
      userName: values.lastName,
      mail: values.email,
      phone: values.phone,
      pwd: defaultCenterAdminPw,
      compId: props.centerId,
    }

    const addressDto: CreateUserAddressDto = {
      zipCode: values.zipCode,
      city: values.city,
      countryId: values.country,
      street: values.address,
      id: props.editingUser?.addresses[0].id,
    }
    const role = roles.find((role) => role.roleName === 'CenterAdmin')
    if (!role) {
      console.error('Role CenterAdmin not found')
      return
    }
    if (props.editingUser) {
      dispatch(
        updateUser({ id: props.editingUser.id, userDto, addressDto }) as any,
      )
        .unwrap()
        .then(() => {
          props.setOpen(false)
        })
    } else {
      dispatch(
        createUser({
          userDto,
          addressDto,
          roleDto: role,
        }) as any,
      )
        .unwrap()
        .then(() => {
          props.setOpen(false)
        })
    }
  }

  useEffect(() => {
    if (!props.open) {
      reset()
    }
  }, [props.open, reset])

  useEffect(() => {
    dispatch(findCountries() as any)
    dispatch(findRoles() as any)
  }, [])

  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = event.target.value
      .replace(/\D/g, '')
      .replace(/(\d{2})/g, '$1 ')
      .trim()
    setValue('phone', formattedPhoneNumber as any, { shouldValidate: true })
  }

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: '50%',
        },
      }}
    >
      <Loader text="Enregistrement du contact" open={false} />
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          color: 'white',
          fontWeight: '700',
          fontSize: '20px',
        }}
      >
        {"Ajout d'un nouveau contact"}
        <IconButton
          sx={{ position: 'absolute', right: '20px' }}
          onClick={() => props.setOpen(false)}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            sx={{
              width: '100%',
              padding: '20px',
            }}
          >
            <TextField
              {...register('job')}
              error={Boolean(errors.job)}
              helperText={errors.job?.message}
              fullWidth
              margin="dense"
              label="Fonction"
              variant="outlined"
            />

            <TextField
              {...register('firstName')}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message}
              fullWidth
              margin="dense"
              label="Prénom"
              variant="outlined"
            />
            <TextField
              {...register('lastName')}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message}
              fullWidth
              margin="dense"
              label="Nom"
              variant="outlined"
            />
            <TextField
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              fullWidth
              margin="dense"
              label="Email"
              variant="outlined"
            />
            <TextField
              {...register('phone')}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              fullWidth
              margin="dense"
              label="Téléphone"
              variant="outlined"
              onChange={handlePhoneInput}
            />
            <TextField
              {...register('address')}
              error={Boolean(errors.address)}
              helperText={errors.address?.message}
              fullWidth
              margin="dense"
              label="Adresse"
              variant="outlined"
            />
            <TextField
              {...register('zipCode')}
              error={Boolean(errors.zipCode)}
              helperText={errors.zipCode?.message}
              fullWidth
              margin="dense"
              label="Code postal"
              variant="outlined"
            />
            <TextField
              {...register('city')}
              error={Boolean(errors.city)}
              helperText={errors.city?.message}
              fullWidth
              margin="dense"
              label="Ville"
              variant="outlined"
            />

            <FormControl style={{ marginTop: 20 }}>
              <InputLabel id="country">Pays</InputLabel>
              <Select
                {...register('country')}
                fullWidth
                margin="dense"
                id="country"
                placeholder="Sélectionner un pays"
                defaultValue={props.editingUser?.addresses[0].countryId.toString()}
                variant="outlined"
              >
                {(countries || []).map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <IconButton type="submit">
                <SaveIcon
                  sx={{
                    fontSize: '36px',
                    color: '#40D172',
                  }}
                />
              </IconButton>
            </Box>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  )
}
