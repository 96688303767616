import { GridSortItem } from '@mui/x-data-grid-pro'
import {
  GET_PDF,
  PATIENT_EXPORT_ACTION,
  SET_PDF_VIEWED,
  TOGGLE_ALERT,
  TOGGLE_ALERT_HISTORIC,
  TOGGLE_ALERT_USER_SETTINGS,
  TOGGLE_TRANSMISSION,
  GET_ALERTS_SUMMARY,
  GET_ALERT_ACTIONS,
  GET_ALERT_COUNT,
  SET_ALERT_PATIENT,
} from '../constants'
import { AlertFilter, AlertType } from '../reducers'

export function getAlerts(
  size: number,
  page: number,
  alertType: AlertType,
  search: string,
  filterState: AlertFilter,
  gammeIds: string[],
  makerIds: number[],
  centerIds: number[],
  sort: GridSortItem | null,
  patientId: number | null,
  resolve: any,
  reject: any,
) {
  return {
    type: TOGGLE_ALERT,
    size,
    page,
    alertType,
    search,
    filterState,
    gammeIds,
    makerIds,
    centerIds,
    sort,
    patientId,
    resolve,
    reject,
  }
}

export function getAlertCount(
  alertType: AlertType,
  search: string,
  filterState: AlertFilter,
  gammeIds: string[],
  makerIds: number[],
  centerIds: number[],
  patientId: number | null,
  resolve: any,
  reject: any,
) {
  return {
    type: GET_ALERT_COUNT,
    alertType,
    search,
    filterState,
    gammeIds,
    makerIds,
    centerIds,
    patientId,
    resolve,
    reject,
  }
}

export function getAlertsSummary(resolve: any, reject: any) {
  return { type: GET_ALERTS_SUMMARY, resolve, reject }
}

export function getAlertsHistoric(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return { type: TOGGLE_ALERT_HISTORIC, size, page, resolve, reject }
}

export function getTransmissions(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return { type: TOGGLE_TRANSMISSION, size, page, resolve, reject }
}

export function getUserSettings(resolve: any, reject: any) {
  return { type: TOGGLE_ALERT_USER_SETTINGS, resolve, reject }
}

export function patientExportAction(
  resolve: any,
  reject: any,
  exportAction: 'VIEW' | 'COMMENT' | 'CHECK',
  patientExportId: number,
  exportActionComment?: string,
) {
  return {
    type: PATIENT_EXPORT_ACTION,
    resolve,
    reject,
    exportAction,
    patientExportId,
    exportActionComment,
  }
}

export function getPDF(
  exportId: number,
  reportId: number,
  resolve: any,
  reject: any,
) {
  return { type: GET_PDF, resolve, reject, exportId, reportId }
}

export function setPDFViewed(
  exportId: number,
  reportId: number,
  resolve: any,
  reject: any,
) {
  return { type: SET_PDF_VIEWED, resolve, reject, exportId, reportId }
}

export function getAlertActions(alertId: number, resolve: any, reject: any) {
  return { type: GET_ALERT_ACTIONS, resolve, reject, alertId }
}

export function setAlertPatient(patientId: number) {
  return { type: SET_ALERT_PATIENT, patientId }
}
