import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  GET_USER_ROLES_SUCCESS,
  GET_MESSAGES_SUCCESS,
  GET_USER_SUCCESS,
  // SET_MESSAGE_DETAILS,
  SET_MESSAGE_DETAILS_SUCCESS,
} from '../constants'

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface IRole {
  id: number
  roleName: string
  description: string
}

export interface IUserRoles {
  IRole: []
}

export interface IMessageEntity {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  uuid: string
  senderEmail: string
  title: string
  messageContent: string
  responsePreviousId: number
  transferPreviousId: number
}

export interface IPatientRecord {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  patientExportId: number
  record_file: string
  record_path: string
  viewed_user_id: number
  viewedDate: Date
  status: boolean
  is_episode: boolean
  is_viewed: boolean
}

export interface IStoredFileEntity {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  displayName: string
  mineType: string
  storeName: string
}
export interface IMessageReceiver {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  messageId: number
  email: string
  copy: boolean
  read: boolean
}

export interface IMessageObject {
  id: number | null
  createdAt: Date | null
  modifiedAt: Date | null
  deletedAt: Date | null
  uuid: string | null
  senderEmail: string | null
  title: string | null
  messageContent: string | null
  responsePreviousId: number | null
  transferPreviousId: number | null
  sendTo: IMessageReceiver[] | null
  copyTo: IMessageReceiver[] | null
  storedFiles: IStoredFileEntity[] | null
  patientRecords: IPatientRecord[] | null
  entity: IMessageEntity[] | null
}

export interface IDoctor {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  rppsNumber: string
  cardioImplant: boolean
  billing: boolean
  cardioAttent: boolean
  doctorAttent: boolean
}

export interface IAddressObject {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  street: string
  zipCode: string
  city: string
  countryId: Number
  contry: ICountry[]
}

export interface ICountry {
  id: Number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  countryName: string
  lang: string
  phonePrefix: number
}

export interface IUserRole {
  id: number
  roleName: string
  description: string
}

export interface ICompany {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  companyName: string
  address: string
  zipCode: string
  city: string
  countryId: number
  phone: string
  type: ['CENTER', 'MAKER', 'OWNER ']
  logoFileId: number
}

export interface IUser {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  mail: string
  pwd: string
  userName: string
  firstName: string
  compId: number
  phone: string
  title: string
  company: ICompany[]
  roles: IUserRole[]
  addresses: IAddressObject[]
  doctor: IDoctor[]
}

export interface ICompany {
  id: number
  createdAt: Date
  modifiedAt: Date
  deletedAt: Date
  companyName: string
  address: string
  zipCode: string
  city: string
  countryId: number
  phone: string
  type: ['CENTER', 'MAKER', 'OWNER ']
  logoFileId: number
}

export interface IInterlocutor {
  email: string
  copy: boolean
  read: boolean
}

export interface IMessage {
  copyTo: IInterlocutor[]
  sendTo: IInterlocutor[]
  messageContent: string
  title: string
}

export interface IMessagingOject {
  message: IMessageObject[]
  user?: IUser[]
  company?: ICompany[]
}

// const initialStateMessage = {
//   //eslint-disable-line
//   id: null,
//   createdAt: null,
//   modifiedAt: null,
//   deletedAt: null,
//   uuid: null,
//   senderEmail: null,
//   title: null,
//   messageContent: null,
//   responsePreviousId: null,
//   transferPreviousId: null,
//   sendTo: null,
//   copyTo: null,
//   storedFiles: null,
//   patientRecords: null,
//   entity: null,
//   messageDetails: null,
// } as IMessageObject

const initialState = {
  // message = initialStateMessage,
  // user = null,
  // company = null,
} as IMessagingOject

const messagingReducer = produce((draft = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_USER_ROLES_SUCCESS:
      draft.usersRoles = action.payload.data
      break
    case GET_MESSAGES_SUCCESS:
      draft.message = action.payload.data
      break
    case GET_USER_SUCCESS:
      draft.data.user = action.payload.data
      break
    case SET_MESSAGE_DETAILS_SUCCESS:
      draft.messageDetails = action.payload.data
      break
    default:
      return draft
  }
})

export default messagingReducer
