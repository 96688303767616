/* eslint-disable no-restricted-globals */
import { useCallback, useEffect, useRef, useState } from 'react'
import { PropsFromReduxLogin } from '../containers/LoginContainer'
import Logo from '../../../assets/icon/logo-seul.png'
import Name from '../../../assets/icon/my-promantis-brand.png'
import BadgePSC from '../../../assets/icon/ProSanteConnect_COULEURS.png'

import { Alert, Box, Button, Grid, Paper, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { OpenIdService } from '../services/openId'
import { useQuery } from '../../../hooks/useQuery'

const LoginComponent = (props: PropsFromReduxLogin) => {
  const [validated, setValidated] = useState(false) //eslint-disable-line
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [mail, setMail] = useState('')
  const [pwd, setPwd] = useState('')
  const query = useQuery()
  const initialized = useRef(false)

  const handleSubmit = (event: any) => {
    setError('')
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      return
    }
    props
      .logIn(mail, pwd)
      .catch(() => setError(`L'email ou le mot de passe est incorrect`))
    setValidated(true)
  }

  const handlePSC = useCallback(() => {
    try {
      OpenIdService.auth()
    } catch (e) {
      setError('Erreur lors de la connexion à ProSantéConnect')
    }
  }, [])

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      if (location && location.search) {
        const session_state = query.get('session_state')
        const code = query.get('code')
        if (code && session_state) {
          props.loginPSC(code)
        }
      }
    }
  }, [props, query])

  return (
    <>
      <div className="container">
        <Box
          sx={{
            backgroundColor: '#F8F9F9',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex ',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="Logo"
          >
            <img src={Logo} alt="" />
            <img src={Name} alt="" />
          </Box>

          <Paper
            sx={{
              display: 'flex ',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
              borderRadius: '5px',
              margin: '15px',
              padding: '35px',
            }}
          >
            <div>
              <Paper
                sx={{
                  display: 'flex ',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'linear-gradient(to right, #85C4D0, #9FDEEC)',
                  borderRadius: '5px',
                  width: '100%',
                  maxHeight: '40px',
                  margin: '-50px 15px 20px auto',
                  color: 'white',
                }}
              >
                <h3>Avec mon identifiant</h3>
              </Paper>
              <form
                onSubmit={handleSubmit}
                onChange={(e) => setValidated(e.currentTarget.checkValidity())}
              >
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  sx={{
                    minWidth: '350px',
                    width: '100%',
                  }}
                >
                  <Grid item>
                    <TextField
                      type="email"
                      placeholder="Email"
                      fullWidth
                      name="mail"
                      variant="outlined"
                      onChange={(e) => setMail(e.target.value)}
                      required
                      autoFocus
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      fullWidth
                      name="pwd"
                      onChange={(e) => setPwd(e.target.value)}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      className="ps-0"
                      sx={{
                        fontSize: '10px',
                        margin: '0 0 5px 0',
                        padding: '0',
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword
                        ? 'Masquer le mot de passe'
                        : 'Afficher le mot de passe'}
                    </Button>
                  </Grid>
                  {error !== '' && <Alert severity="error">{error}</Alert>}
                  <Grid
                    sx={{
                      paddingTop: '16px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ background: '#9FDEEC' }}
                    >
                      Connexion
                    </Button>
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="forgotpswd">
                      <Link to="/forgot-password">Mot de passe oublié</Link>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
            <div style={{ width: 30 }}> </div>

            <div>
              <Paper
                sx={{
                  display: 'flex ',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'linear-gradient(to right, #85C4D0, #9FDEEC)',
                  borderRadius: '5px',
                  width: '100%',
                  maxHeight: '40px',
                  margin: '-50px 15px 20px auto',
                  color: 'white',
                }}
              >
                <h3>S'identifier avec</h3>
              </Paper>
              <div style={{ minHeight: 263 }}>
                <Button
                  type="button"
                  aria-label="login-pro-sante-connect"
                  fullWidth
                  onClick={handlePSC}
                  style={{ padding: 20 }}
                  startIcon={
                    <img
                      src={BadgePSC}
                      alt="pro-sante-connect"
                      style={{ width: 220 }}
                    />
                  }
                />
              </div>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default LoginComponent
