import HomeContainer from "../containers/HomeContainer"
import * as homeRoutes from './home'

export const HomeRoutes = [
    {
        key: "home",
        exact: true,
        path: homeRoutes.HOME,
        component: HomeContainer,
    }
]