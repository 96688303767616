import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AlertsComponent from '../components/AlertsComponent'
import {
  getAlertActions,
  getAlertCount,
  getAlerts,
  getAlertsHistoric,
  getAlertsSummary,
  getPDF,
  getTransmissions,
  getUserSettings,
  patientExportAction,
} from '../actions'
import { getProsthesisGammeRange } from '../../../modules/patients/actions'
import { AlertFilter, AlertType } from '../reducers'
import { getCompany } from '../../center/actions'
import { GridSortItem } from '@mui/x-data-grid-pro'
import { CompanyType } from '../../../common/interfaces'

const mapStateToProps = (state: RootState) => {
  return {
    token: state.authReducer.token,
    alerts: state.alertsReducer.alerts,
    totalPages: state.alertsReducer.totalPages,
    alertsHistoric: state.alertsReducer.alertsHistoric,
    transmissions: state.alertsReducer.transmission,
    userSettings: state.alertsReducer.userSettings,
    summary: state.alertsReducer.summary,
    alertActions: state.alertsReducer.alertActions,
    prosthesisGammeRange: state.patientsReducer.prosthesisGammeRange,
    makers: state.centersReducer.makers?.data || [],
    centers: state.centersReducer.centers.data,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAlerts: (
      size: number,
      page: number,
      alertType: AlertType,
      search: string,
      filterState: AlertFilter,
      selectedGamme: string[],
      makerIds: number[],
      selectedCenterIds: number[],
      sort: GridSortItem | null,
      patientId: number | null,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getAlerts(
            size,
            page,
            alertType,
            search,
            filterState,
            selectedGamme,
            makerIds,
            selectedCenterIds,
            sort,
            patientId,
            resolve,
            reject,
          ),
        )
      })
    },
    getAlertsSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getAlertsSummary(resolve, reject))
      })
    },
    getTransmissions: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getTransmissions(size, page, resolve, reject))
      })
    },
    getAlertsHistoric: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getAlertsHistoric(size, page, resolve, reject))
      })
    },
    getUserSettings: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getUserSettings(resolve, reject))
      })
    },
    getPDF: (exportId: number, reportId: number) => {
      return new Promise((resolve: (pdf: Blob) => void, reject: any) => {
        dispatch(getPDF(exportId, reportId, resolve, reject))
      })
    },
    patientExportAction: (
      exportAction: 'VIEW' | 'COMMENT' | 'CHECK',
      patientExportId: number,
      exportActionComment?: string,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          patientExportAction(
            resolve,
            reject,
            exportAction,
            patientExportId,
            exportActionComment,
          ),
        )
      })
    },
    getProsthesisGammeRange: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getProsthesisGammeRange(size, page, resolve, reject))
      })
    },
    getAlertActions: (alertId: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getAlertActions(alertId, resolve, reject))
      })
    },
    getMakers: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getCompany(1000, 0, '', false, CompanyType.MAKER, resolve, reject),
        )
      })
    },
    getCenters: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getCompany(1000, 0, '', false, CompanyType.CENTER, resolve, reject),
        )
      })
    },
    getAlertCount: (
      alertType: AlertType,
      search: string,
      filterState: AlertFilter,
      selectedGamme: string[],
      makerIds: number[],
      selectedCenterIds: number[],
      patientId: number | null,
    ) => {
      return new Promise<number>((resolve, reject) => {
        dispatch(
          getAlertCount(
            alertType,
            search,
            filterState,
            selectedGamme,
            makerIds,
            selectedCenterIds,
            patientId,
            resolve,
            reject,
          ),
        )
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxAlerts = ConnectedProps<typeof connector> & {
  patientId?: number
}

export default withRouter(connector(AlertsComponent) as any) as any as (props: {
  patientId?: number
}) => JSX.Element
