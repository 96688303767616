import { CORE_URL, REFACTOR_URL } from '../../../helpers/env'
import {
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../reducers'

export const getDoctors = (
  token: string,
  page: number,
  size: number,
  doctorType: string,
  search: string,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/users?page=${page}&limit=${size}${doctorType ? `&doctorType=${doctorType}` : ''}&search=${search}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const doctorAutocomplete = (
  name: string,
  page: number,
  limit: number,
) => {
  const ENDPOINT: RequestInfo = `https://directory.ether.healthcare/doctor/autocomplete/name?name=${name}&page=${page}&limit=${limit}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const addDoctorStep1 = (token: string, doctor: INewDoctorStep1) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({ ...doctor, pwd: 'Promant!sExt3rna1' }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
export const updateDoctorStep1 = async (
  id: number,
  token: string,
  doctor: INewDoctorStep1,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({ ...doctor, id }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
export const addDoctorStep2 = async (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}/role`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({
      id: 8,
      roleName: 'Doctor',
      description: 'A praticien',
    }),
  }

  let status = 0

  try {
    const response = await fetch(ENDPOINT, parameters)
    status = response.status
    const json = await response.json()
    return { data: json, status }
  } catch (e) {
    console.error(e)
  }
}

export const addDoctorStep3 = (
  token: string,
  id: number,
  address: INewDoctorAddress,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}/address`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(address),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updateDoctorStep3 = async (
  token: string,
  id: number,
  addressId: number,
  address: INewDoctorAddress,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}/address`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({ ...address, id: addressId }),
  }

  let status = 0

  try {
    const response = await fetch(ENDPOINT, parameters)
    status = response.status
    const json = await response.json()
    return { data: json, status }
  } catch (e) {
    console.error(e)
  }
}

export const addDoctorStep4 = (
  token: string,
  id: number,
  doctorInfos: INewDoctorInfos,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}/doctor`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(doctorInfos),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updateDoctorStep4 = (
  token: string,
  id: number,
  doctorId: number,
  doctorInfos: INewDoctorInfos,
) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}/doctor`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({ ...doctorInfos, id: doctorId }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const deleteDoctor = async (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${CORE_URL}/user/${id}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Host: `${CORE_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
    })
    .then(() => {
      return { status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getDoctorsSummary = (token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/users/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((json) => {
      return { data: json, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
