import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { prothesisSchema } from '../../../schemas'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { getCompany } from '../../center/actions'
import { RootState } from 'src/store'
import { ICreateProsthesis } from '../reducers'
import { createProsthesis } from '../actions'
import { gammesList, rangesList } from '../constants'
import {
  prosthesisGammeName,
  prosthesisRangeName,
} from '../../../utils/patientInfos'
import React from 'react'
import { AiOutlineEuroCircle } from 'react-icons/ai'

interface IProps {
  isOpen: boolean
  handleClose: () => void
  getMakers: () => void
}

const AddProtheseModal = ({ isOpen, handleClose }: IProps) => {
  const [isBillableChecked, setIsBillableChecked] = useState(false)
  const dispatch = useDispatch()

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors, isValid },
  } = useForm<ICreateProsthesis>({
    resolver: yupResolver(prothesisSchema),
  })

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(getCompany(1000, 0, '', false, 'MAKER', resolve, reject))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const makers = useSelector((state: RootState) => state.centersReducer.makers)

  const handleSave = async (values: ICreateProsthesis) => {
    new Promise((resolve, reject) => {
      dispatch(
        createProsthesis(
          {
            ...values,
            facturation: values.facturation
              ? parseInt(values.facturation?.toString())
              : undefined,
          },
          resolve,
          reject,
        ),
      )
    })
      .then(() => {
        handleClose()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (isBillableChecked) {
      setValue('facturation', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBillableChecked])

  const { gammeId, makerId } = watch()
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '18px',
          }}
        >
          {"Ajout d'une référence de prothèse"}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container spacing={3} padding={10}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Select fullWidth name="deviceType" defaultValue={1}>
                    <MenuItem value={1}>Prothese</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.model}>
                  <TextField
                    {...register('model')}
                    fullWidth
                    name="model"
                    label="Modele"
                    helperText={errors.model?.message}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    error={!!errors.reference}
                    {...register('reference')}
                    fullWidth
                    name="reference"
                    label="Reference"
                    helperText={errors.reference?.message}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.makerId}>
                  {!makerId && (
                    <InputLabel
                      shrink={true}
                      sx={{
                        transform: 'translate(14px, 18px) scale(1)',
                      }}
                    >
                      Fabricant
                    </InputLabel>
                  )}
                  <Select
                    {...register('makerId')}
                    fullWidth
                    name="makerId"
                    error={!!errors.makerId}
                  >
                    {makers.data.map((maker: any) => (
                      <MenuItem key={maker.id} value={maker.id}>
                        {maker.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors.gammeId}>
                  {!gammeId && (
                    <InputLabel
                      shrink={true}
                      sx={{
                        transform: 'translate(14px, 18px) scale(1)',
                      }}
                    >
                      Type de dispositif
                    </InputLabel>
                  )}
                  <Select
                    error={!!errors.gammeId}
                    {...register('gammeId')}
                    fullWidth
                    name="gammeId"
                  >
                    {gammesList.map((gamme) => (
                      <MenuItem key={gamme.id} value={gamme.id}>
                        {prosthesisGammeName(gamme.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {gammeId === 2 && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={!!errors.gammeId}>
                    <InputLabel
                      shrink={true}
                      sx={{
                        transform: 'translate(14px, 18px) scale(1)',
                      }}
                    >
                      Segment
                    </InputLabel>
                    <Select
                      {...register('rangeId')}
                      fullWidth
                      name="rangeId"
                      error={!!errors.rangeId}
                    >
                      {rangesList.map((range) => (
                        <MenuItem key={range.id} value={range.id}>
                          {prosthesisRangeName(range.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormLabel>Facturable?</FormLabel>
                <Checkbox
                  checked={isBillableChecked}
                  onChange={(_, checked) => setIsBillableChecked(checked)}
                />
                {isBillableChecked && (
                  <FormControl fullWidth error={!!errors.facturation}>
                    <TextField
                      inputProps={{
                        endAdornment: (
                          <React.Fragment>
                            <AiOutlineEuroCircle size={20} color="black" />
                          </React.Fragment>
                        ),
                      }}
                      label="Montant (€)"
                      type="number"
                      {...register('facturation')}
                      helperText={errors.facturation?.message}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={handleSubmit(handleSave)}
            sx={{
              alignSelf: 'right',
              color: isValid ? '#40D172' : 'default',
            }} // Conditional styling
          >
            <SaveIcon
              sx={{
                fontSize: '36px',
              }}
            />
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default AddProtheseModal
