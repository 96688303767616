import { REFACTOR_URL } from '../../../helpers/env'
import { REFACTOR_API_URL } from '../../auth/constants'
import { API_URL } from '../constants'
import { ICreateProsthesis, IUpdatePatientProsthesis } from '../reducers'

export const getProsthesisReferences = (
  token: string,
  search: string,

  gammeIds: string[],
  makerIds: number[],
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/prosthesis/reference?page=0&size=1000&search=${search}${gammeIds
    .map((id) => '&prosthesisTypes[]=' + id)
    .join('')}${makerIds.map((id) => '&makerIds[]=' + id).join('')}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const transmitterReferenceService = (
  token: string,
  page: number,
  size: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/transmitter-reference?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const gammeRangeService = (
  token: string,
  page: number,
  size: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/prosthesis/gamme-range?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePatientProsthesis = (
  token: string,
  infos: IUpdatePatientProsthesis,
  method: string,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/prosthesis`

  const parameters: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(infos),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const deletePatientProsthesis = (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${API_URL}/prosthesis/${id}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const createProsthesis = (token: string, data: ICreateProsthesis) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/prosthesis/reference`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
