import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PrescriptionComponent from '../components/PrescriptionComponent'
import {
  getPrescriptionPdf,
  getPrescriptions,
  getPrescriptionsSummary,
  setPrescriptionUserSettings,
  updatePrescriptionMakerReaded,
} from '../actions'
import { PrescriptionsState } from '../services'
import { IUserSettings } from '../reducers'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    prescriptionsSummary: state.prescriptionsReducer?.prescriptionsSummary,
    prescriptions: state.prescriptionsReducer?.prescriptions,
    userSettings: state.prescriptionsReducer?.userSettings,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPrescriptionsSummary: () => {
      return new Promise((resolve, reject) => {
        dispatch(getPrescriptionsSummary(resolve, reject))
      })
    },
    getPrescriptions: (
      limit: number,
      page: number,
      state: PrescriptionsState,
      read: boolean,
    ) => {
      console.log(`State  ${state}`)
      return new Promise((resolve, reject) => {
        dispatch(getPrescriptions(limit, page, state, read, resolve, reject))
      })
    },
    getPrescriptionPdf: (pdfName: string | null) => {
      return new Promise((resolve: (pdf: Blob) => void, reject: any) => {
        if (pdfName) {
          dispatch(getPrescriptionPdf(pdfName, resolve, reject))
        }
      })
    },
    setPrescriptionUserSettings: (settings: IUserSettings) => {
      dispatch(setPrescriptionUserSettings(settings))
    },
    updatePrescriptionMakerReaded: (
      prescription_id: string,
      maker_id: number,
    ) => {
      return new Promise((resolve, reject) => {
        dispatch(
          updatePrescriptionMakerReaded(
            prescription_id,
            true,
            maker_id,
            resolve,
            reject,
          ),
        )
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxPrescriptions = ConnectedProps<typeof connector>

export default withRouter(connector(PrescriptionComponent))
