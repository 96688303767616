import { IAuthState } from '../reducers'

export type RootState = {
  authReducer: IAuthState
}

const hasPermission = (state: RootState, allowedRoles: string[]): boolean => {
  const roles = state.authReducer.user?.roles.map((role) => role.roleName) || []
  return roles.some((role) => allowedRoles.includes(role))
}

// Define role-based access control for each action
export const canAddModifyDeletePatientData = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer le statut patient (6 statuts : "télésuivi", "à compléter", "à valider le dispositif", "déconnecté", "archivé")
export const canAddModifyDeletePatientStatus = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer une alerte & transmission patient
export const canAddModifyDeletePatientAlert = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    // 'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer une notification patient
export const canAddModifyDeletePatientNotification = (
  state: RootState,
): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}

// voir infos patient
export const canViewPatientData = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
    'MakerAdmin',
  ]
  return hasPermission(state, allowedRoles)
}

export const canViewPatientAlert = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}
export const canViewPatientBilling = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
    'MakerAdmin',
  ]
  return hasPermission(state, allowedRoles)
}

export const canViewPatientPrescription = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
    'MakerAdmin',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer une facturation patient
export const canAddModifyDeletePatientBilling = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromDoctor',
    // 'PromNurse',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer une référence ("prothèse cardiaque", "sonde(s) implantable(s)", "télétransmetteur", "autre dispositif " ex: balances connectées ...)
export const canAddModifyDeleteReference = (state: RootState): boolean => {
  const allowedRoles = ['SuperAdmin', 'PromAdmin']
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer un télétransmetteur ("en attente de télétransmetteur", "commandé", "livraison en cours", "transmission", "déconnecté")
export const canAddModifyDeleteTransmitter = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    //'PromSales',
    'CenterDoctor',
    'CenterAdmin',
    // 'CenterUser',
    'MakerAdmin',
    //'Contact',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer un médecin
export const canAddModifyDeleteDoctor = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'PromSales',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer un centre
export const canAddModifyDeleteCenter = (state: RootState): boolean => {
  const allowedRoles = ['SuperAdmin', 'PromAdmin', 'PromSales']
  return hasPermission(state, allowedRoles)
}

export const canAddContactToCenter = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromSales',
    'CenterAdmin',
    'CenterDoctor',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer un fabricant
export const canAddModifyDeleteManufacturer = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'PromSales',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer un contact fabricant
export const canAddModifyDeleteManufacturerContact = (
  state: RootState,
): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'PromSales',
  ]
  return hasPermission(state, allowedRoles)
}

// consulter le menu "statistiques" générals (= "tous les centres" Promantis cumulés)
// TODO : Makes no sense to me that everyone can see that, but it's what the specs say, so I'm leaving it here
export const canViewGeneralStatistics = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'PromSales',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
    'MakerAdmin',
    'Contact',
  ]
  return hasPermission(state, allowedRoles)
}

// consulter le menu "statistiques" d'un centre spécifique (ex: "Clairval")
// TODO : Makes no sense to me that everyone can see that, but it's what the specs say, so I'm leaving it here
export const canViewSpecificCenterStatistics = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'PromSales',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
    'MakerAdmin',
    'Contact',
  ]
  return hasPermission(state, allowedRoles)
}

// ajouter/modifier/supprimer un message sur my-Promantis ("messagerie interne" Promantis + "messagerie MS Santé")
// TODO : I guess everyone can do that, for himsleft that is...
export const canAddModifyDeleteMessage = (state: RootState): boolean => {
  const allowedRoles = [
    'SuperAdmin',
    'PromAdmin',
    'PromDoctor',
    'PromNurse',
    'PromSales',
    'CenterDoctor',
    'CenterAdmin',
    'CenterUser',
    'MakerAdmin',
    'Contact',
  ]
  return hasPermission(state, allowedRoles)
}
