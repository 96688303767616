import { Refresh, ViewColumn } from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {
  Tooltip,
  IconButton,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
  Select,
  InputLabel,
  FormControl,
  Button,
} from '@mui/material'
import { useState } from 'react'
import { ToolbarInput } from '../../../../utils/ToolbarInput'
import { gammesList } from '../../../protheses/constants'
import { ICompany } from '../../../center/reducers'
import { prosthesisGammeName } from '../../../../utils/patientInfos'
import { AiOutlineCheckSquare } from 'react-icons/ai'
import { companyIcons } from '../../../../utils/constants'
import { useSelector } from 'react-redux'
import { canViewPatientData } from '../../../auth/selectors/authSelector'

export type ToolbarProps = {
  title: string
  onRefresh: () => void
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  selectedGammes: string[]
  setSelectedGammes: (value: string[]) => void
  makers: ICompany[]
  setMakerId: (value: number) => void
  selectedMakerIds: number[]
  setSelectedMakerIds: (value: number[]) => void
  centers: ICompany[]
  selectedCenterIds: number[]
  setSelectedCenterIds: (value: number[]) => void
}

export const Toolbar = ({
  title,
  onRefresh,
  searchText,
  setSearchText,
  selectedGammes,
  setSelectedGammes,
  makers,
  selectedMakerIds,
  setSelectedMakerIds,
  centers,
  selectedCenterIds,
  setSelectedCenterIds,
}: ToolbarProps) => {
  const apiRef = useGridApiContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field === event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }

  const resetAllFilter = () => {
    setSelectedGammes([])
    setSelectedMakerIds([])
    setSearchText('')
    setSelectedCenterIds([])
  }

  const currentFilter =
    selectedGammes.length > 0 ||
    selectedMakerIds.length > 0 ||
    searchText.length > 0 ||
    selectedCenterIds.length > 0

  const canViewPatientInfos = useSelector(canViewPatientData)

  return (
    <GridToolbarContainer
      sx={{
        background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
        borderRadius: '0px 15px 0px 0px',
      }}
    >
      <Box
        ml={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ pr: 10 }}
      >
        <NotificationsIcon
          sx={{
            marginRight: '1rem',
            padding: '0px 10px 0px 0px',
            color: 'white',
            width: '10% ',
            height: '10%',
          }}
        />

        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 'bold', color: 'white' }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {currentFilter && (
        <Tooltip title={'Filtrer'}>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={resetAllFilter}
          >
            Supprimer filtre(s)
          </Button>
        </Tooltip>
      )}
      <FormControl sx={{ m: 1 }}>
        {!selectedGammes.length && (
          <InputLabel
            shrink={false}
            sx={{
              transform: 'translate(24px, 8px) scale(1)',
            }}
          >
            Type de prothèse
          </InputLabel>
        )}
        <Select
          onChange={(ev) => setSelectedGammes(ev.target.value as string[])}
          style={{
            width: 200,
            height: 40,
            backgroundColor: '#B5E5F0',
            borderWidth: 0,
            borderColor: 'white !important',
            marginRight: 6,
            borderRadius: 8,
            color: 'black',
          }}
          value={selectedGammes}
          renderValue={(selected) => {
            // Map the selected IDs to their corresponding names.
            return selected
              .map((gamme) => prosthesisGammeName(gamme))
              .join(', ')
          }}
          multiple
        >
          {gammesList.map((gamme) => (
            <MenuItem key={gamme.id} value={gamme.name}>
              {selectedGammes.includes(gamme.name) && (
                <AiOutlineCheckSquare
                  size={20}
                  style={{ marginRight: '5px' }}
                />
              )}
              {prosthesisGammeName(gamme.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        {!selectedMakerIds.length && (
          <InputLabel
            shrink={false}
            sx={{
              transform: 'translate(24px, 8px) scale(1)',
            }}
          >
            Fabricant(s)
          </InputLabel>
        )}
        <Select
          multiple
          onChange={(ev) => setSelectedMakerIds(ev.target.value as number[])}
          style={{
            width: 200,
            height: 40,
            backgroundColor: '#B5E5F0',
            borderWidth: 0,
            borderColor: 'white !important',
            marginRight: 6,
            borderRadius: 8,
            color: 'black',
          }}
          value={selectedMakerIds}
          renderValue={(selected) => {
            // Map the selected IDs to their corresponding names.
            return selected
              .map((id) => makers.find((maker) => maker.id === id)?.companyName)
              .join(', ')
          }}
        >
          {makers.map((maker) => {
            return (
              <MenuItem key={maker.id} value={maker.id}>
                {selectedMakerIds.includes(maker.id) && (
                  <AiOutlineCheckSquare
                    size={20}
                    style={{ marginRight: '5px' }}
                  />
                )}
                {companyIcons[maker.id]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        {!selectedCenterIds.length && (
          <InputLabel
            shrink={false}
            sx={{
              transform: 'translate(24px, 8px) scale(1)',
            }}
          >
            Centres
          </InputLabel>
        )}
        <Select
          multiple
          onChange={(ev) => setSelectedCenterIds(ev.target.value as number[])}
          style={{
            width: 200,
            height: 40,
            backgroundColor: '#B5E5F0',
            borderWidth: 0,
            borderColor: 'white !important',
            marginRight: 6,
            borderRadius: 8,
            color: 'black',
          }}
          value={selectedCenterIds}
          renderValue={(selected) => {
            // Map the selected IDs to their corresponding names.
            return selected
              .map(
                (id) => centers.find((center) => center.id === id)?.companyName,
              )
              .join(', ')
          }}
        >
          {centers.map((center) => {
            return (
              <MenuItem key={center.id} value={center.id}>
                {selectedCenterIds.includes(center.id) && (
                  <AiOutlineCheckSquare
                    size={20}
                    style={{ marginRight: '5px' }}
                  />
                )}
                {center.companyName}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <ToolbarInput
        searchText={searchText}
        setSearchText={setSearchText}
        label={
          canViewPatientInfos
            ? 'Id patient, Nom/Prénom, Id Alerte'
            : 'Id patient, Id Alerte'
        }
      />
      <Tooltip title={'Colonnes'}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((column, key) => (
          <MenuItem key={key} onClick={handleHide} id={column.field}>
            <FormControlLabel
              control={<Checkbox checked={!column.hide} />}
              label={column.headerName as string}
            />
          </MenuItem>
        ))}
      </Menu>
      <Tooltip title={'Rafraîchir'}>
        <IconButton onClick={onRefresh}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}
