import { GET_MANUFACTURERS } from '../constants'
import { ManufacturerFilter } from '../reducers'
import { SET_MANUFACTURER_FILTER } from '../constants'

export function getManufacturers(resolve: any, reject: any) {
  return { type: GET_MANUFACTURERS, resolve, reject }
}

export function setFilters(filter: ManufacturerFilter) {
  return { type: SET_MANUFACTURER_FILTER, filter }
}
