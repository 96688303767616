import { ConnectedProps, connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { RootState } from '../../../store'
import { getMessages } from '../actions'
import { SET_MESSAGE_DETAILS } from '../constants'
import MessageDetailsComponent from '../components/MessageDetailsComponent'

const mapStateToProps = (state: RootState) => {
  return {
    messages: state.messagingReducer.message,
    messageDetails: state.messagingReducer.messageDetails,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMessages: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getMessages(size, page, resolve, reject))
      })
    },
    setMessageDetails: (messageId: number) => {
      return new Promise((resolve, reject) => {
        dispatch({ type: SET_MESSAGE_DETAILS, id: messageId, resolve, reject })
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxMessaging = ConnectedProps<typeof connector>

export default withRouter(connector(MessageDetailsComponent))
