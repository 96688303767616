import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  createBill,
  getBillablePatient,
  getBillableSummary,
  getBilling,
} from '../actions'
import BillingComponent from '../components/BillingComponent'
import { BillStatus } from '../services'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    homeData: state.homeDataReducer,
    billablePatient: state.billingReducer.billablePatient,
    billableSummary: state.billingReducer.billableSummary,
    pagination: state.billingReducer.pagination,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBilling: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getBilling(size, page, resolve, reject))
      })
    },
    getBillableSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getBillableSummary(resolve, reject))
      })
    },
    getBillablePatient: (limit: number, page: number, status: BillStatus) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getBillablePatient(limit, page, status, resolve, reject))
      })
    },
    createBill: (patientId: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(createBill(patientId, resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxBilling = ConnectedProps<typeof connector>

export default withRouter(connector(BillingComponent))
