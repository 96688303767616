import {
  GET_DATA_PATIENT_EXPORT_SUMMARY,
  GET_DATA_TRANSMITTER_SUMMARY,
  GET_DATA_PATIENT_SUMMARY,
  GET_DATA_BILLING_SUMMARY,
  GET_DATA_PRESCRIPTION_SUMMARY,
  REFRESH_TOKEN,
} from '../constants'

export function patientExportSummary(token: string, resolve: any, reject: any) {
  return {
    type: GET_DATA_PATIENT_EXPORT_SUMMARY,
    token,
    resolve,
    reject,
  }
}
export function transmitterSummary(token: string, resolve: any, reject: any) {
  return {
    type: GET_DATA_TRANSMITTER_SUMMARY,
    token,
    resolve,
    reject,
  }
}
export function patientSummary(token: string, resolve: any, reject: any) {
  return {
    type: GET_DATA_PATIENT_SUMMARY,
    token,
    resolve,
    reject,
  }
}
export function billingSummary(token: string, resolve: any, reject: any) {
  return {
    type: GET_DATA_BILLING_SUMMARY,
    token,
    resolve,
    reject,
  }
}
export function prescriptionsSummary(token: string, resolve: any, reject: any) {
  return {
    type: GET_DATA_PRESCRIPTION_SUMMARY,
    token,
    resolve,
    reject,
  }
}

export function refreshToken(token: string, resolve: any, reject: any) {
  return {
    type: REFRESH_TOKEN,
    token,
    resolve,
    reject,
  }
}
