import PatientsContainer from '../containers/PatientsContainer'
import PatientsInfoContainer from '../containers/PatientsInfoContainer'
import * as patientsRoutes from './patients'

export const PatientsRoutes = [
  {
    key: 'patients',
    exact: true,
    path: patientsRoutes.PATIENTS,
    component: PatientsContainer,
  },
  {
    key: 'patientsinfo',
    exact: true,
    path: patientsRoutes.PATIENTSINFO,
    component: PatientsInfoContainer,
  },
]
