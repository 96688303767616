import { CORE_URL } from '../../../helpers/env'

export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED'

export const GET_PROSTHESIS_REFERENCES = 'GET_PROSTHESIS_REFERENCES'
export const GET_PROSTHESIS_REFERENCES_SUCCESS =
  'GET_PROSTHESIS_REFERENCES_SUCCESS'
export const GET_PROSTHESIS_REFERENCES_FAILED =
  'GET_PROSTHESIS_REFERENCES_FAILED'

export const GET_GAMME_RANGE = 'GET_GAMME_RANGE'
export const GET_GAMME_RANGE_SUCCESS = 'GET_GAMME_RANGE_SUCCESS'
export const GET_GAMME_RANGE_FAILED = 'GET_GAMME_RANGE_FAILED'

export const GET_TRANSMITTER_REFERENCE = 'GET_TRANSMITTER_REFERENCE'
export const GET_TRANSMITTER_REFERENCE_SUCCESS =
  'GET_TRANSMITTER_REFERENCE_SUCCESS'
export const GET_TRANSMITTER_REFERENCE_FAILED =
  'GET_TRANSMITTER_REFERENCE_FAILED'

export const UPDATE_PATIENT_PROSTHESIS = 'UPDATE_PATIENT_PROSTHESIS'
export const DELETE_PATIENT_PROSTHESIS = 'DELETE_PATIENT_PROSTHESIS'

export const CREATE_PROSTHESIS = 'CREATE_PROSTHESIS'
export const CREATE_PROSTHESIS_SUCCESS = 'CREATE_PROSTHESIS_SUCCESS'
export const CREATE_PROSTHESIS_FAILED = 'CREATE_PROSTHESIS_FAILED'

export const API_URL = CORE_URL

export const gammesList = [
  {
    id: 1,
    name: 'prosthesis.gamme.null',
  },
  {
    id: 2,
    name: 'prosthesis.gamme.defib.cardiac',
  },
  {
    id: 3,
    name: 'prosthesis.gamme.monit.implant',
  },
  {
    id: 4,
    name: 'prosthesis.gamme.stim.cardiac',
  },
]

export const rangesList = [
  {
    id: 1,
    name: 'prosthesis.range.null',
  },
  {
    id: 2,
    name: 'prosthesis.range.simple.chamber',
  },
  {
    id: 3,
    name: 'prosthesis.range.dual.chamber',
  },
  {
    id: 4,
    name: 'prosthesis.range.triple.chamber',
  },
  {
    id: 5,
    name: 'prosthesis.range.under.skin',
  },
  {
    id: 6,
    name: 'prosthesis.range.without.lead',
  },
]
