import { Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IAlertAction, IAlertPatient } from '../../reducers';

interface IProps {
    alert: IAlertPatient,
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    patientExportAction: (exportAction: 'VIEW' | 'COMMENT' | 'CHECK', patientExportId: number, exportActionComment?: string) => Promise<unknown>
    alertActions: IAlertAction[]
}

const ReadingModal = (props: IProps) => {

    const handleClose = () => {
        props.setIsOpen(false);
    }

    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
            style: { borderRadius: 20 }
        }}
    >

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex', position: 'relative', justifyContent: 'center', background: 'linear-gradient(60deg, #84c2ce, #96dcea)', color: 'white', fontWeight: '700', fontSize: '24px' }}>
                {"Liste des commentaires"}
                <IconButton sx={{ position: 'absolute', right: '20px' }} onClick={handleClose}>
                    <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '0 30px 10px 30px', marginTop: '10px', height: 'calc(80vh - 134px)' }}>
                <Box sx={{ width: '100%' }}>
                    {[...props.alertActions].reverse().map((action, i) => {
                        const actionDate = new Date(action.createdAt)
                        return <>
                            {action.action === 'VIEW' ?
                                <Box key={i + 'view'} className='alert-comment'>
                                    <p style={{ marginBottom: 0 }}>Vu par: {action.user.firstName} {action.user.userName}</p>
                                    <p style={{ marginTop: '5px' }}>le: {actionDate.toLocaleDateString()} à {actionDate.getHours() > 9 ? actionDate.getHours() : '0' + actionDate.getHours()}:{actionDate.getMinutes() > 9 ? actionDate.getMinutes() : '0' + actionDate.getMinutes()}</p>
                                </Box>
                                :
                                <Box key={i + 'comment'} className='alert-comment'>
                                    <p style={{ fontWeight: '600' }}>{action.actionComment}</p>
                                    <p style={{ marginBottom: 0 }}>Commentaire ajouté par: {action.user.firstName} {action.user.userName}</p>
                                    <p style={{ marginTop: '5px' }}>le: {actionDate.toLocaleDateString()} à {actionDate.getHours() > 9 ? actionDate.getHours() : '0' + actionDate.getHours()}:{actionDate.getMinutes() > 9 ? actionDate.getMinutes() : '0' + actionDate.getMinutes()}</p>

                                </Box>
                            }
                        </>
                    })}
                </Box>
            </DialogContent>
        </div >
    </Dialog >
}

export default ReadingModal