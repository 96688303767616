import ProtheseContainer from '../containers/ProtheseContainer'
import * as protheseRoutes from './prothese'

export const ProtheseRoutes = [
  {
    key: 'references',
    exact: true,
    path: protheseRoutes.PROTHESE,
    component: ProtheseContainer,
  },
]
