import { AnyAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { fetchManufacturers } from '../services'
import { IResponseStatus } from '../../patients/reducers'
import { parseResponse } from '../../../store/saga/responseParser'
import * as Constants from '../constants'
import { ManufacturerFilter } from '../reducers'

function* getManufacturers(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  const filters = (state: RootState) => state.manufacturerReducer.filter

  const f: ManufacturerFilter = yield select(filters)

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      fetchManufacturers,
      api_key,
      f.page,
      f.size,
      f.makerId,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_MANUFACTURERS_SUCCESS,
      Constants.GET_MANUFACTURERS_FAILED,
    )
  } catch (err) {
    yield put({ type: Constants.GET_MANUFACTURERS_FAILED, err })
  }
}

export const manufacturerSaga = [
  takeLatest(Constants.GET_MANUFACTURERS, getManufacturers),
]
