import { INewDoctor } from 'src/modules/doctors/reducers'
import { ICreatePatient } from 'src/modules/patients/reducers'

export const handleChangePhone = (
  phone: string,
  value: string,
  handleChangePatient: (type: string, value: any) => void,
  patient: ICreatePatient | INewDoctor,
) => {
  //@ts-ignore
  if (value.length > patient[phone].length) {
    if (value.charAt(value.length - 1).match(/\d/))
      switch (value.length) {
        case 5:
          handleChangePatient(phone, value + ' ')
          break
        case 8:
          handleChangePatient(phone, value + ' ')
          break
        case 11:
          handleChangePatient(phone, value + ' ')
          break
        case 14:
          handleChangePatient(phone, value + ' ')
          break
        default:
          handleChangePatient(phone, value)
      }
  } else if (value.length > 3) handleChangePatient(phone, value)
}

export const handleChangeSINumber = (
  value: string,
  handleChangePatient: (type: string, value: any) => void,
  patient: ICreatePatient,
) => {
  if (value.length > patient.socialInsuranceNumber.length) {
    if (value.charAt(value.length - 1).match(/\d/)) {
      switch (value.length) {
        case 1:
          handleChangePatient(
            'socialInsuranceNumber',
            value === '1' || value === '2' ? value + ' ' : '',
          )
          break
        case 4:
          handleChangePatient('socialInsuranceNumber', value + ' ')
          break
        case 7:
          handleChangePatient('socialInsuranceNumber', value + ' ')
          break
        case 10:
          handleChangePatient('socialInsuranceNumber', value + ' ')
          break
        case 14:
          handleChangePatient('socialInsuranceNumber', value + ' ')
          break
        case 18:
          handleChangePatient('socialInsuranceNumber', value + ' ')
          break
        default:
          handleChangePatient('socialInsuranceNumber', value)
      }
    }
  } else handleChangePatient('socialInsuranceNumber', value)
}

export const getDoctorType = (doctor: any) => {
  let types: string[] = []
  if (doctor.cardioAttent === true) types.push('Cardiologue traitant')
  if (doctor.cardioImplant === true) types.push('Cardiologue implanteur')
  if (doctor.doctorAttent === true) types.push('Médecin traitant')
  return types
}

export const getCenterType = (centerType: any) => {
  switch (centerType) {
    case 'HOSPITAL':
      return 'Hôpital'
    case 'CLINIC':
      return 'Clinique'
    case 'CABINET':
      return 'Cabinet'
    default:
      return centerType
  }
}
