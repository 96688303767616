import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  GET_PRESCRIPTIONS_SUCCESS,
  GET_PRESCRIPTIONS_SUMMARY_SUCCESS,
  SET_PRESCRIPTION_USER_SETTINGS,
} from '../constants'

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface IPrescriptions {
  id: string
  document_ref: string
  checked: boolean
  prescription_id: string
  maker_id: number
}

export interface IUserSettings {
  activeTab: number
  activePage: number
  rowsPerPage: number
}

export interface IStatePrescriptions {
  prescriptionsSummary: IPrescriptionsSummary
  prescriptions: IPrescriptions[]
  userSettings: IUserSettings
}

export interface IPrescriptionsSummary {
  signed: number
  refused: number
  generated: number
  missingData: number
  read?: number
  toRead?: number
}

const initialState = {
  prescriptionsSummary: {
    signed: 0,
    refused: 0,
    generated: 0,
    missingData: 0,
  },
  prescriptions: [],
  userSettings: {
    activeTab: 0,
    activePage: 1,
    rowsPerPage: 100,
  },
} as IStatePrescriptions

const prescriptionsReducer = produce(
  (draft = initialState, action: AnyAction) => {
    switch (action.type) {
      case GET_PRESCRIPTIONS_SUMMARY_SUCCESS:
        draft.prescriptionsSummary = action.payload.data
        break
      case GET_PRESCRIPTIONS_SUCCESS:
        draft.prescriptions = action.payload.data
        break
      case SET_PRESCRIPTION_USER_SETTINGS:
        draft.userSettings = action.userSettings
        break
      default:
        return draft
    }
  },
)

export default prescriptionsReducer
