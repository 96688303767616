import { CreateCompanyDto } from 'src/helpers/dto'
import {
  CREATE_COMPANY,
  GET_COMPANIES_SUMMARY,
  GET_COMPANY,
  UPDATE_COMPANY,
} from '../constants'

export function getCompany(
  size: number,
  page: number,
  searchText: string,
  isImplantCenter: boolean | undefined = undefined,
  type: string | undefined,
  resolve: any,
  reject: any,
) {
  return {
    type: GET_COMPANY,
    size,
    page,
    searchText,
    isImplantCenter,
    companyType: type,
    resolve,
    reject,
  }
}

export function getSummary(resolve: any, reject: any) {
  return {
    type: GET_COMPANIES_SUMMARY,
    resolve,
    reject,
  }
}

export function createCompany(
  company: CreateCompanyDto,
  resolve: any,
  reject: any,
) {
  return {
    type: CREATE_COMPANY,
    company,
    resolve,
    reject,
  }
}

export function updateCompany(
  id: number,
  company: CreateCompanyDto,
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_COMPANY,
    id,
    company,
    resolve,
    reject,
  }
}
