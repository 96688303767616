import FullPdfContainer from '../containers/FullPdfContainer'

export const FullPdfRoutes = [
  {
    key: 'pdf viewer',
    exact: true,
    path: '/full-pdf',
    component: FullPdfContainer,
  },
]
