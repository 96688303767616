import LoginContainer from "../containers/LoginContainer"
import ForgotPswdContainer from "../containers/ForgotPswdContainer"
import * as authRoutes from './auth'
import ParamsContainer from "../containers/ParamsContainer"

export const AuthRoutes = [
    {
        key: "login",
        exact: true,
        path: authRoutes.LOGIN,
        component: LoginContainer,
    },
    {
        key: "forgot-paswword",
        exact: true,
        path: authRoutes.FORGOTPSWD,
        component: ForgotPswdContainer,
    },
    {
        key: "params",
        exact: true,
        path: authRoutes.PARAMS,
        component: ParamsContainer,
    }
]