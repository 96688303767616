import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SaveIcon from '@mui/icons-material/Save'
import { IconButton, NativeSelect, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import {
  IFoundDoctor,
  INewDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../reducers'
import { ICountry } from 'src/modules/patients/reducers'
import { toast } from 'react-toastify'
import { RppsAutocompleteByNameInput } from './Input/RppsAutocompleteByNameInput'
import { doctorSchema } from '../../../schemas'
import Loader from '../../../modules/common/Loader'
import {
  updateDoctorStep1Action,
  updateDoctorStep2Action,
  updateDoctorStep4Action,
} from '../actions'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  getDoctorAutocomplete: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getCountries: () => Promise<unknown>
  addDoctor: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
  editingDoctor?: any | null
  onSuccess?: () => void
}

const useStyles = makeStyles(() => ({
  asterix: {
    color: 'red',
    fontWeight: 'bold',
  },
  dynamicList: {
    backgroundColor: 'red',
  },
}))

export default function ResponsiveDialog(props: IProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const countries: ICountry[] = useSelector(
    (state: RootState) => state.patientsReducer.country,
  )
  const [doctor, setDoctor] = useState<INewDoctor>({
    cardioImplant: false,
    cardioAttent: false,
    doctorAttent: false,
    userName: '',
    firstName: '',
    street: '',
    zipCode: '',
    city: '',
    country: {
      id: 1,
      createdAt: '2020-10-28T10:24:24.000+0000',
      modifiedAt: null,
      deletedAt: null,
      countryName: 'France',
      lang: 'fr_FR',
      phonePrefix: 33,
    },
    rppsNumber: '',
    phone: '',
    mail: '',
    billing: false,
  })

  useEffect(() => {
    props
      .getCountries()
      .catch(() => toast.error('Erreur lors du chargement des pays'))
  }, []) //eslint-disable-line
  useEffect(() => {
    if (props.editingDoctor) {
      const addr = props.editingDoctor.addresses.length
        ? props.editingDoctor.addresses[0]
        : null

      setDoctor({
        ...doctor,
        userName: props.editingDoctor.userName || '',
        firstName: props.editingDoctor.firstName || '',
        street: addr?.street || '',
        zipCode: addr?.zipCode || '',
        city: addr?.city || '',
        country: addr?.country || '',
        rppsNumber: props.editingDoctor.doctor.rppsNumber || '',
        phone: props.editingDoctor.phone || '',
        mail: props.editingDoctor.mail || '',
        cardioImplant: props.editingDoctor.doctor.cardioImplant,
        cardioAttent: props.editingDoctor.doctor.cardioAttent,
        doctorAttent: props.editingDoctor.doctor.doctorAttent,
        billing: props.editingDoctor.billing,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editingDoctor])

  const RequiredField = () => {
    return <span className={classes.asterix}>*</span>
  }

  const handleSelectedDoctor = (selectedDoctor: IFoundDoctor) => {
    setDoctor({
      ...doctor,
      userName:
        selectedDoctor.lastName.charAt(0).toUpperCase() +
        selectedDoctor.lastName.slice(1),
      firstName:
        selectedDoctor.firstName.charAt(0).toUpperCase() +
        selectedDoctor.firstName.slice(1),
      rppsNumber: selectedDoctor.rppsNumber,
    })
  }

  const handleChangeDoctor = (type: string, value: string) => {
    setDoctor({ ...doctor, [type]: value })
  }

  const handleChangeProfile = (
    type: 'cardioAttent' | 'doctorAttent' | 'cardioImplant',
  ) => {
    switch (type) {
      case 'cardioAttent':
        setDoctor({ ...doctor, cardioAttent: !doctor.cardioAttent })
        break
      case 'cardioImplant':
        setDoctor({ ...doctor, cardioImplant: !doctor.cardioImplant })
        break
      case 'doctorAttent':
        setDoctor({ ...doctor, doctorAttent: !doctor.doctorAttent })
        break
    }
  }

  const handleChangeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDoctor({
      ...doctor,
      country: countries.filter(
        (c) => c.id === parseInt(event.target.value),
      )[0],
    })
  }
  const [isCompleted, setIsCompleted] = useState(false)

  const fieldsValidator = async () => {
    ;(await doctorSchema.validate(doctor))
      ? setIsCompleted(true)
      : setIsCompleted(false)
  }

  useEffect(() => {
    fieldsValidator()
  }, [doctor]) //eslint-disable-line

  const handleSave = async () => {
    if (
      doctor.cardioImplant !== false ||
      doctor.doctorAttent !== false ||
      doctor.cardioAttent !== false
    ) {
      try {
        await doctorSchema.validate(doctor)
        if (props.editingDoctor) {
          await new Promise((resolve, reject) => {
            dispatch(
              updateDoctorStep1Action(
                props.editingDoctor.id,
                {
                  mail: doctor.mail,
                  userName: doctor.userName,
                  firstName: doctor.firstName,
                  title: 'Médecin',
                  phone: doctor.phone,
                },
                resolve,
                reject,
              ),
            )
          })
          const addr = props.editingDoctor.addresses.length
            ? props.editingDoctor.addresses[0]
            : null
          await new Promise(async (resolve, reject) => {
            if (addr) {
              dispatch(
                updateDoctorStep2Action(
                  props.editingDoctor.id,
                  addr?.id,
                  {
                    street: doctor.street,
                    city: doctor.city,
                    zipCode: doctor.zipCode,
                    countryId: doctor.country.id,
                  },
                  resolve,
                  reject,
                ),
              )
            } else {
              await props.addDoctor.step3(props.editingDoctor.id, {
                street: doctor.street,
                city: doctor.city,
                zipCode: doctor.zipCode,
                countryId: doctor.country.id,
              })
              resolve(null)
            }
          })

          await new Promise((resolve, reject) => {
            dispatch(
              updateDoctorStep4Action(
                props.editingDoctor.id,
                props.editingDoctor.doctor.id,
                {
                  cardioAttent: doctor.cardioAttent,
                  cardioImplant: doctor.cardioImplant,
                  doctorAttent: doctor.doctorAttent,
                  rppsNumber: doctor.rppsNumber,
                  billing: doctor.billing,
                },
                resolve,
                reject,
              ),
            )
          })
        } else {
          const newDoctorId = await props.addDoctor.step1({
            mail: doctor.mail,
            userName: doctor.userName,
            firstName: doctor.firstName,
            title: 'Médecin',
            phone: doctor.phone,
          })
          await props.addDoctor.step2(newDoctorId)
          await props.addDoctor.step3(newDoctorId, {
            street: doctor.street,
            city: doctor.city,
            zipCode: doctor.zipCode,
            countryId: doctor.country.id,
          })
          await props.addDoctor.step4(newDoctorId, {
            cardioAttent: doctor.cardioAttent,
            cardioImplant: doctor.cardioImplant,
            doctorAttent: doctor.doctorAttent,
            rppsNumber: doctor.rppsNumber,
            billing: doctor.billing,
          })
        }
        toast.success('Médecin enregistré')
        props.setIsOpen(false)

        if (props.onSuccess) {
          props.onSuccess()
        }
      } catch (err: any) {
        console.log(err)
        toast.error(err.errors[0] ? err.errors[0] : err.message)
      } finally {
        setIsLoading(false)
      }
    } else toast.error('Veuillez sélectionner un profil')
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement du médecin" open={isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          {"Ajout d'un nouveau médecin"}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: '20px 100px', marginTop: '15px' }}>
          <FormControl sx={{ width: '100%' }}>
            <RppsAutocompleteByNameInput
              handleSelectedDoctor={handleSelectedDoctor}
              getDoctorAutocomplete={props.getDoctorAutocomplete}
            />

            <FormLabel component="legend" sx={{ marginTop: '20px' }}>
              Profil <RequiredField /> :
            </FormLabel>
            <div>
              <FormControlLabel
                control={<Checkbox />}
                label="Cardiologue implanteur"
                checked={!!doctor.cardioImplant}
                onChange={() => handleChangeProfile('cardioImplant')}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Cardiologue traitant"
                checked={!!doctor.cardioAttent}
                onChange={() => handleChangeProfile('cardioAttent')}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Médecin traitant"
                checked={!!doctor.doctorAttent}
                onChange={() => handleChangeProfile('doctorAttent')}
              />
            </div>
            <div style={{ width: '100%' }}>
              <TextField
                sx={{ width: '50%', margin: '8px 0px', paddingRight: '5px' }}
                margin="dense"
                label="Nom"
                variant="standard"
                disabled
                value={doctor.userName}
                onChange={(e) =>
                  handleChangeDoctor(
                    'userName',
                    e.target.value.match(/^(\s*|[aA-zZÜ-ü]+)$/)
                      ? e.target.value
                      : doctor.userName,
                  )
                }
              />
              <TextField
                sx={{ width: '50%', margin: '8px 0px' }}
                margin="dense"
                disabled
                label="Prénom"
                variant="standard"
                value={doctor.firstName}
                onChange={(e) =>
                  handleChangeDoctor(
                    'firstName',
                    e.target.value.match(/^(\s*|[aA-zZÜ-ü]+)$/)
                      ? e.target.value
                      : doctor.firstName,
                  )
                }
              />
              <br />
            </div>
            <div>
              <TextField
                margin="dense"
                label="Adresse"
                fullWidth
                variant="standard"
                value={doctor.street}
                onChange={(e) => handleChangeDoctor('street', e.target.value)}
              />
              <br />
              <TextField
                sx={{ width: '50%', margin: '8px 0px', paddingRight: '5px' }}
                margin="dense"
                label="Code Postal"
                variant="standard"
                value={doctor.zipCode}
                onChange={(e) => handleChangeDoctor('zipCode', e.target.value)}
              />
              <TextField
                sx={{ width: '50%', margin: '8px 0px' }}
                margin="dense"
                label="Ville"
                variant="standard"
                value={doctor.city}
                onChange={(e) =>
                  handleChangeDoctor(
                    'city',
                    e.target.value.match(/^(\s|[aA-zZÜ-ü]+|-)*$/)
                      ? e.target.value
                      : doctor.city,
                  )
                }
              />
            </div>
            <br />
            <FormControl>
              <NativeSelect
                value={doctor.country.id}
                onChange={(event) => handleChangeCountry(event)}
                sx={{ width: '50%' }}
              >
                <option value={1}>France</option>
                <option value={2}>Allemagne</option>
              </NativeSelect>
            </FormControl>
            <div>
              <TextField
                sx={{ width: '50%', margin: '8px 0px' }}
                margin="dense"
                label="RPPS"
                disabled
                fullWidth
                variant="standard"
                value={doctor.rppsNumber}
                onChange={(e) =>
                  handleChangeDoctor('rppsNumber', e.target.value)
                }
              />
            </div>
            <div>
              <TextField
                margin="dense"
                inputProps={{ maxLength: doctor.country.id === 1 ? 17 : 16 }}
                label={<>Téléphone</>}
                variant="standard"
                value={doctor.phone}
                onChange={(e) =>
                  handleChangeDoctor(
                    'phone',
                    e.target.value.match(/^([0-9])*$/)
                      ? e.target.value
                      : doctor.phone,
                  )
                }
                sx={{ width: '50%', paddingRight: '5px' }}
              />
              <TextField
                sx={{ width: '50%', paddingRight: '5px' }}
                margin="dense"
                label="Email"
                variant="standard"
                value={doctor.mail}
                onChange={(e) => handleChangeDoctor('mail', e.target.value)}
              />
            </div>

            <FormControlLabel
              value={doctor.billing}
              onChange={(e) =>
                setDoctor({ ...doctor, billing: !doctor.billing })
              }
              control={<Checkbox />}
              label="Cardiologue facturant la télésurveillance :"
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0',
                paddingTop: '15px',
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleSave}>
            <SaveIcon
              sx={{
                fontSize: '36px',
                color: isCompleted ? '#40D172' : 'lightgrey',
              }}
            />
          </IconButton>
        </DialogActions>
      </div>
    </Dialog>
  )
}
