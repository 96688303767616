import { PropsFromReduxFullPdf } from '../containers/FullPdfContainer'
import { useEffect, useState } from 'react'
import history from '../../../routes/history'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
import { Box, Button } from '@mui/material'

interface LocationState {
  state: {
    path: string
    url: string
  }
}

const FullPdfComponent = (props: PropsFromReduxFullPdf) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pdf, setPdf] = useState<string | null>(null)
  const location = history.location as LocationState
  const path = location.state.path
  const url = location.state.url

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const result: Blob = await props.getFullPdf(path, url)
      setPdf(URL.createObjectURL(result))
    } catch {
      toast.error('Impossible de récupérer le fichier PDF')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (path && url) {
      fetchData()
    }
  }, [path, url]) //eslint-disable-line

  return (
    <>
      {isLoading && <Loader text="Chargement du fichier" open={isLoading} />}
      <Box>
        <Button className="btn-back-pdf" onClick={() => history.goBack()}>
          Retour
        </Button>
        <div className="div-pdf-viewer">
          {path && url && pdf ? (
            <>
              <object
                aria-labelledby="report-pdf"
                type="application/pdf"
                data={pdf}
                style={{ width: '100%', height: 'calc(100vh - 140px)' }}
              ></object>
            </>
          ) : (
            <p>Aucun document retrouvé</p>
          )}
        </div>
      </Box>
    </>
  )
}

export default FullPdfComponent
