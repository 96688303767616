import { PropsFromReduxDoctors } from '../containers/DoctorsContainer'
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { Toolbar } from './Table/Toolbar'
import Loader from '../../common/Loader'
import { Box, Button, Tab, Tabs } from '@mui/material'
import { getDoctorType } from '../../../utils/addPatient'
import { toast } from 'react-toastify'
import PaginationComponent from './../../common/PaginationComponent'
import { CustomTooltip } from '../../../utils/CustomTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { canAddModifyDeleteDoctor } from '../../auth/selectors/authSelector'
import AddNewDoctorModal from './AddNewDoctorModal'
import { deleteDoctorAction } from '../actions'
import DeleteIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/EditOutlined'

const DoctorsComponent = (props: PropsFromReduxDoctors) => {
  const [tab, setTab] = useState<0 | 1 | 2 | 3>(0)
  const [activePage, setActivePage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [openUserDialog, setOpenUserDialog] = useState(false)
  const [editingDoctor, seteditingDoctor] = useState<any>(null)

  const dispatch = useDispatch()

  const canCreateDoctor = useSelector(canAddModifyDeleteDoctor)

  const getType = () => {
    switch (tab) {
      case 0:
        return 'Implant'
      case 1:
        return 'Cardiolog'
      case 2:
        return 'FamilyDoctor'
      case 3:
        return 'Billing'
    }
  }

  const fetchDatas = async (loader: boolean) => {
    if (loader) setIsLoading(true)
    try {
      await props.getDoctors(rowsPerPage, activePage - 1, getType(), searchText)
    } catch {
      toast.error('Impossible de charger les médecins')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDisplay = async () => {
    setIsLoading(true)
    try {
      await props.getDoctorsSummary()
    } catch {
      toast.error('Impossible de charger les médecins')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDatas(true)
  }, [rowsPerPage, activePage, tab]) //eslint-disable-line

  useEffect(() => {
    fetchDatas(false)
  }, [searchText, openUserDialog]) //eslint-disable-line

  useEffect(() => {
    fetchDisplay()
  }, []) //eslint-disable-line

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const onDoctorSaved = () => {
    fetchDatas(false)
    fetchDisplay()
  }

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: 'Fonction',
      flex: 0.8,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.doctor ? (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', padding: '7px 0' }}
          >
            {getDoctorType(params.row.doctor).map((type) => {
              return <span style={{ margin: '7px 0' }}>{type}</span>
            })}
          </Box>
        ) : (
          ''
        ),
    },
    {
      field: 'col2',
      headerName: 'Nom Prénom',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <CustomTooltip
          text={
            <p style={{ margin: 0, fontSize: '13px', overflow: 'hidden' }}>{`${
              params.row.userName ? params.row.userName : ''
            } ${params.row.firstName ? params.row.firstName : ''}`}</p>
          }
        >
          <Box>
            {`${params.row.userName ? params.row.userName : ''} ${
              params.row.firstName ? params.row.firstName : ''
            }`}
          </Box>
        </CustomTooltip>
      ),
    },
    {
      field: 'col3',
      headerName: 'Adresse',
      flex: 1.2,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ padding: '15px 0', minHeight: '52px' }}>
          {params.row.addresses &&
            params.row.addresses.map((address: any) => (
              <CustomTooltip
                text={
                  <p style={{ margin: 0, fontSize: '13px' }}>{`${
                    address.street ? address.street : ''
                  } ${address.zipCode ? address.zipCode : ''} ${
                    address.city ? address.city : ''
                  }`}</p>
                }
              >
                <Box sx={{ margin: '5px 0' }}>
                  {`${address.street ? address.street : ''} ${
                    address.zipCode ? address.zipCode : ''
                  } ${address.city ? address.city : ''}`}
                </Box>
              </CustomTooltip>
            ))}
        </Box>
      ),
    },
    {
      field: 'col4',
      headerName: 'Téléphone',
      flex: 0.7,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.phone ? params.row.phone : '',
    },
    {
      field: 'col5',
      headerName: 'Email',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.mail ? params.row.mail : '',
    },
    {
      field: 'col6',
      headerName: 'RPPS',
      flex: 0.7,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.doctor.rppsNumber ? params.row.doctor.rppsNumber : '',
    },
    {
      field: 'col7',
      headerName: 'Actions',
      flex: 0.7,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CustomTooltip text="Modifier">
            <Button
              onClick={() => {
                seteditingDoctor(params.row)
                setOpenUserDialog(true)
              }}
            >
              <EditIcon style={{ color: '#8ED0DD' }} />
            </Button>
          </CustomTooltip>
          <CustomTooltip text="Supprimer">
            <Button
              onClick={() => {
                handleDeleteDoctor(params.row)
              }}
            >
              <DeleteIcon style={{ color: 'darkpink' }} />
            </Button>
          </CustomTooltip>
        </Box>
      ),
    },
  ]

  const handleDeleteDoctor = async (doctor: any) => {
    const isConfirmed = window.confirm(
      `Voulez-vous vraiment supprimer ce Dr. ${doctor.firstName} ${doctor.userName}?`,
    )
    if (isConfirmed) {
      setIsLoading(true)
      try {
        await new Promise((resolve, reject) => {
          dispatch(deleteDoctorAction(doctor.id, resolve, reject))
        })
      } catch (err) {
      } finally {
        setIsLoading(false)
      }
      fetchDatas(false)
      fetchDisplay()
    }
  }

  return (
    <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
      <Loader open={isLoading} text="Chargement des médecins" />
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ffa726',
            display: 'flexWrap',
          },
        }}
        onChange={(_, newTab) => {
          setActivePage(1)
          setTab(newTab)
        }}
        textColor="primary"
        value={tab}
      >
        <Tab
          label={`Cardiologues implanteurs : ${
            props.doctorsSummary?.implantCount
              ? props.doctorsSummary.implantCount
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Cardiologues traitants : ${
            props.doctorsSummary?.cardiologCount
              ? props.doctorsSummary.cardiologCount
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`médecins traitants : ${
            props.doctorsSummary?.doctorCount
              ? props.doctorsSummary.doctorCount
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Télésurveillance : ${
            props.doctorsSummary?.billingCount
              ? props.doctorsSummary.billingCount
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
      </Tabs>

      <DataGridPro
        rows={props.doctors.data ? props.doctors.data : []}
        columns={columns}
        components={{
          Toolbar,
        }}
        componentsProps={{
          toolbar: {
            title: 'Médecins',
            onRefresh: fetchDatas,
            getDoctorAutocomplete: props.getDoctorAutocomplete,
            getCountries: props.getCountries,
            addDoctor: props.addDoctor,
            canCreateDoctor: canCreateDoctor,
            searchText,
            setSearchText,
            openUserDialog,
            setOpenUserDialog,
          },
        }}
        pageSize={rowsPerPage}
        getRowHeight={() => 'auto'}
        disableColumnMenu
        hideFooter
        disableSelectionOnClick
        sx={{
          border: 'none',
          padding: '3px 15px',
          borderBottom: '1px solid #E0E0E0',
        }}
      />
      <PaginationComponent
        pages={props.doctors.totalPages}
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      {openUserDialog && (
        <AddNewDoctorModal
          isOpen={openUserDialog}
          setIsOpen={setOpenUserDialog}
          getDoctorAutocomplete={props.getDoctorAutocomplete as any}
          getCountries={props.getCountries}
          addDoctor={props.addDoctor}
          editingDoctor={editingDoctor}
          onSuccess={onDoctorSaved}
        />
      )}
    </Box>
  )
}

export default DoctorsComponent
