import SearchIcon from '@mui/icons-material/Search'
import { debounce } from 'lodash'

interface IProps {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  label?: string
}

export const ToolbarInput = (props: IProps) => {
  const setSearch = debounce((value: string) => {
    props.setSearchText(value)
  }, 500)

  return (
    <div style={{ position: 'relative' }}>
      <SearchIcon
        sx={{
          position: 'absolute',
          top: 'calc(50% - 10px)',
          left: '5px',
          fontSize: '20px',
        }}
      />
      <input
        placeholder={props.label || 'Rechercher'}
        defaultValue={props.searchText}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
        className="toolbar-input"
        style={{ width: '280px' }}
      />
    </div>
  )
}
