import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ParamsComponent from '../components/ParamsComponent';
import { newPwd } from '../actions';

const mapStateToProps = (state: RootState) => {
    return {
        token: state.authReducer.token,
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        newPwd: (mail: string, pwd: string) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(newPwd(mail, pwd, resolve, reject));
            });
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export type PropsFromReduxParams = ConnectedProps<typeof connector>

export default withRouter(connector(ParamsComponent))