import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'
import { updateImplant } from '../../../modules/patients/services'
import { IUpdateProsthesisId } from '../../../modules/patients/reducers'

function* getProsthesisReferences(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getProsthesisReferences,
      api_key,
      action.searchText,
      action.selectedGammes,
      action.selectedMakerIds,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_PROSTHESIS_REFERENCES_SUCCESS,
      Constants.GET_PROSTHESIS_REFERENCES_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_PROSTHESIS_REFERENCES_FAILED, error })
  }
}

function* getTransmitterReference(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.transmitterReferenceService,
      api_key,
      action.page,
      action.size,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_TRANSMITTER_REFERENCE_SUCCESS,
      Constants.GET_TRANSMITTER_REFERENCE_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_TRANSMITTER_REFERENCE_FAILED, error })
  }
}

function* getGammeRange(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.gammeRangeService,
      api_key,
      action.page,
      action.size,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_GAMME_RANGE_SUCCESS,
      Constants.GET_GAMME_RANGE_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_GAMME_RANGE_FAILED, error })
  }
}

function* updatePatientProsthesis(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  const getCompanyId = (state: RootState) => state.authReducer.company.id

  try {
    const api_key: string = yield select(getApiKey)
    const centerId: number = yield select(getCompanyId)

    let response: IResponseStatus = yield call(
      Services.updatePatientProsthesis,
      api_key,
      action.infos,
      action.method,
    )
    if (response.status === 200) {
      response = yield call(updateImplant, api_key, {
        prosthesisId: response.data.id,
        id: action.implantationId,
        patientId: action.patientId,
        centerId,
        cardioAttent: action.cardioAttent,
        cardioAttentId: action.cardioAttent.id,
        cardioBilling: action.cardioBilling,
        cardioBillingId: action.cardioBilling.id,
        cardioImplant: action.cardioImplant,
        cardioImplantId: action.cardioImplant.id,
      } as IUpdateProsthesisId)
    }

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* deletePatientProsthesis(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.deletePatientProsthesis,
      api_key,
      action.id,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* doCreateProsthesis(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.createProsthesis,
      api_key,
      action.data,
    )

    yield call(
      parseResponse,
      response,
      Constants.CREATE_PROSTHESIS_SUCCESS,
      Constants.CREATE_PROSTHESIS_FAILED,
    )
    response.status === 201
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.CREATE_PROSTHESIS_FAILED, error })
  }
}

export const prothesesSagas = [
  takeLatest(Constants.GET_PROSTHESIS_REFERENCES, getProsthesisReferences),
  takeLatest(Constants.GET_GAMME_RANGE, getGammeRange),
  takeLatest(Constants.GET_TRANSMITTER_REFERENCE, getTransmitterReference),
  takeLatest(Constants.UPDATE_PATIENT_PROSTHESIS, updatePatientProsthesis),
  takeLatest(Constants.DELETE_PATIENT_PROSTHESIS, deletePatientProsthesis),
  takeLatest(Constants.CREATE_PROSTHESIS, doCreateProsthesis),
]
