import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import history from '../../../routes/history';
import Loader from '../../../modules/common/Loader';
import {PropsFromReduxReportPDF} from '../containers/ReportPDFContainer';
import {toast} from 'react-toastify';

interface LocationState {
    state: {
        exportId: number;
        reportId: number;
    }
}

const ReportPDF = (props: PropsFromReduxReportPDF) => {

    const [isLoading, setIsLoading] = useState(false);
    const [pdf, setPdf] = useState<string | null>(null);
    const location = history.location as LocationState;
    const exportId = location.state.exportId;
    const reportId = location.state.reportId;

    const fetchDatas = async () => {
        setIsLoading(true)
        try {
            const result: Blob = await props.getPDF(exportId, reportId)
            setPdf(URL.createObjectURL(result))
            await props.setPDFViewed(exportId, reportId)
        } catch {
            toast.error('Impossible de récupérer le PDF')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (reportId && exportId) fetchDatas()
    }, [reportId, exportId]) //eslint-disable-line

    return <>
        {!!isLoading && <Loader text='Chargement du fichier' open={isLoading} />}
        <Box>
            <Button className='btn-back-pdf' onClick={() => history.goBack()}>Retour</Button>
            <div className='div-pdf-viewer'>
                {reportId && exportId && pdf ? <>
                    <object aria-labelledby="report-pdf" type='application/pdf' data={pdf} style={{width: '100%', height: 'calc(100vh - 140px)'}}></object>
                </>
                    :
                    <p>Aucun document correspondant</p>
                }
            </div>
        </Box>
    </>
}

export default ReportPDF