import React, {useState} from 'react'
import {PropsFromReduxParams} from '../containers/ParamsContainer'
import {Alert, Button, Grid, ListItem, Paper, TextField} from '@mui/material'
import {makeStyles} from '@mui/styles'
// import TimelineDot from '@mui/lab/TimelineDot';
import {Check, Close} from '@mui/icons-material';


const useStyles = makeStyles(() => ({
    paperCard: {
        borderRadius: '15px !important',
    },
    listCard: {padding: 0},
    spanHeaderCard: {marginRight: '5px'},
}))

const ParamsComponent = (props: PropsFromReduxParams) => {

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [mail] = useState(props.user.mail)
    const [pwd, setPwd] = useState("")

    const [passwordUpperCase, setPasswordUpperCase] = useState(false);
    const [passwordLowerCase, setPasswordLowerCase] = useState(false);
    const [passwordNumber, setPasswordNumber] = useState(false);
    const [passwordLength, setPasswordLength] = useState(false);

    const checkPasswordForce = (e: any) => {
        setPwd(e.target.value)
        setPasswordUpperCase(/[A-Z]/.test(e.target.value))
        setPasswordLowerCase(/[a-z]/.test(e.target.value))
        setPasswordLength(e.target.value.length >= 8)
        setPasswordNumber(/[0-9]/.test(e.target.value))
    }

    const handleSubmit = (event: any) => {
        setError('');
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }
        props.newPwd(mail, pwd).then(() => setMessage(`Mot de passe changé avec succes`)).catch(e => setError(''))
    };


    const classes = useStyles()
    return <>
        <div className="params-card">
            <Paper elevation={2} className={classes.paperCard}>
                <ListItem
                    alignItems="flex-start"
                    className="home-card-header home-card-row"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: '15px'
                    }}
                >
                    <span className={classes.spanHeaderCard} />
                    Nouveau mot de passe
                </ListItem>
                <form onSubmit={handleSubmit} >
                    <ListItem className="home-card-title-row">
                        <Grid container direction="column" spacing={2} sx={
                            {
                                minWidth: '350px',
                                width: '100%',
                            }
                        }>
                            <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '16px 0 0 0'
                            }} >
                                <TextField
                                    sx={{width: '80%'}}
                                    type={(showPassword ? "text" : "password")}
                                    placeholder="Nouveau mot de passe"
                                    fullWidth
                                    name="pwd"
                                    onChange={checkPasswordForce}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '16px 0 0 0'
                            }}>
                                <Button className='ps-0' sx={{fontSize: '12px', margin: '0 0 5px 0', padding: '0'}}
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ?
                                        'Masquer le mot de passe'
                                        :
                                        'Afficher le mot de passe'
                                    }
                                </Button>
                            </Grid>
                            <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '16px 0 0 0'
                            }}>
                                Le mot de passe doit contenir au moins ...
                            </Grid>
                            <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '0'
                            }}><div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                    {passwordUpperCase ? <Check color='success' /> : <Close sx={{color: 'red'}} />} une majuscule
                                </div><div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {passwordLowerCase ? <Check color='success' /> : <Close sx={{color: 'red'}} />} une minuscule
                                </div>
                            </Grid>
                            <Grid item sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '0 !important'
                            }}><div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                    {passwordNumber ? <Check color='success' /> : <Close sx={{color: 'red'}} />} un chiffre
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {passwordLength ? <Check color='success' /> : <Close sx={{color: 'red'}} />} 8 caractères
                                </div>
                            </Grid>
                            <Grid item sx={{
                                paddingTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                {error && <Alert severity='error'>{error}</Alert>}
                                {message && <Alert severity='success'>{message}</Alert>}
                            </Grid>
                            <Grid item sx={{
                                paddingTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Button
                                    disabled={(passwordUpperCase && passwordLowerCase && passwordNumber && passwordLength) ? false : true}
                                    variant='contained'
                                    type='submit'
                                    sx={{
                                        background: '#9FDEEC',
                                        marginBottom: '15px'
                                    }
                                    }>Changer de mot de passe</Button>
                            </Grid>
                        </Grid>
                    </ListItem>
                </form>
            </Paper>
        </div>
    </>
}

export default ParamsComponent