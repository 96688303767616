import { useContext } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import Logo from '../../assets/icon/logo-seul.png'
import ListSidebar from './drawerMockUp.json'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HomeIcon from '@mui/icons-material/Home'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PersonIcon from '@mui/icons-material/Person'
import RouterIcon from '@mui/icons-material/Router'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'
import SavingsIcon from '@mui/icons-material/Savings'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ChatIcon from '@mui/icons-material/Chat'
import SellIcon from '@mui/icons-material/Sell'
import SettingsIcon from '@mui/icons-material/Settings'
import { PropsFromReduxSidebar } from './containers/SidebarContainer'
import { NavbarContext } from '../../routes'
import { Tooltip } from '@mui/material'
import history from '../../routes/history'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../auth/selectors/authSelector'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function Sidebar(props: PropsFromReduxSidebar) {
  const userRole = useSelector(
    (state: RootState) => state.authReducer.user?.roles[0].roleName,
  )

  const theme = useTheme()
  //@ts-ignore
  const { isOpen, setIsOpen } = useContext(NavbarContext)

  const handleDrawerOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleDrawerClose = () => {
    setIsOpen(!isOpen)
  }

  const handleClickMenu = (link: string) => {
    history.push(link)
  }

  const logOutFunc = () => {
    props.logOut().catch(() => toast.error('Erreur lors de la déconnexion'))
  }

  return (
    <>
      {props.isLogged ? (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* linear-gradient(60deg,#84c2ce,#96dcea) */}
          <AppBar position="fixed" open={isOpen} sx={{ background: '#F8F8F8' }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(isOpen && { display: 'none' }),
                }}
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
              >
                <MenuIcon />
              </IconButton>
              <AccountCircleIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
              >
                {`${props.user.firstName} ${props.user.userName}`}
              </Typography>
            </Toolbar>
          </AppBar>
          {/*  start drawer */}
          <Drawer variant="permanent" open={isOpen}>
            <DrawerHeader>
              <IconButton
                onClick={handleDrawerClose}
                disableRipple
                sx={{ padding: '0' }}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <div
                    className="header-logo logo"
                    style={isOpen ? { display: ' ' } : { display: 'none' }}
                  >
                    <img
                      src={Logo}
                      alt="logo-Promantis"
                      style={{ width: '35px', paddingRight: '5px' }}
                    />
                    <span>my</span>-promantis
                    <ChevronLeftIcon />
                  </div>
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List style={{ padding: 0 }}>
              {ListSidebar.map((el, index) => {
                let iconSidebar
                switch (el.logo) {
                  case 'HomeIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Accueil" placement="right">
                          <HomeIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'AddAlertIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Alertes" placement="right">
                          <NotificationsIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'PersonIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Patients" placement="right">
                          <PersonIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'RouterIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Transmetteurs" placement="right">
                          <RouterIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'HealthAndSafetyIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Médecins" placement="right">
                          <HealthAndSafetyIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'LocalHospitalIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Centres" placement="right">
                          <LocalHospitalIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'DesignServicesIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Fabricants" placement="right">
                          <DesignServicesIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'CandlestickChartIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Statistiques" placement="right">
                          <CandlestickChartIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'SavingsIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Facturation" placement="right">
                          <SavingsIcon />
                        </Tooltip>
                      </>
                    )

                    break
                  case 'ReceiptIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Ordonnance" placement="right">
                          <ReceiptIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'ChatIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Messagerie" placement="right">
                          <ChatIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'SellIcon':
                    iconSidebar = (
                      <>
                        <Tooltip
                          title="Références de Prothèse"
                          placement="right"
                        >
                          <SellIcon />
                        </Tooltip>
                      </>
                    )
                    break
                  case 'SettingsIcon':
                    iconSidebar = (
                      <>
                        <Tooltip title="Paramètres" placement="right">
                          <SettingsIcon />
                        </Tooltip>
                      </>
                    )
                    break
                }
                return (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: isOpen ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor:
                          history.location.pathname === el.link
                            ? '#87C6D2'
                            : 'white',
                        color:
                          history.location.pathname === el.link
                            ? 'white'
                            : 'rgb(117,117,117)',
                        pointerEvents:
                          el.disabledForRoles &&
                          el.disabledForRoles &&
                          el.disabledForRoles.includes(userRole || '')
                            ? 'none'
                            : 'auto',
                        opacity:
                          el.disabledForRoles &&
                          el.disabledForRoles &&
                          el.disabledForRoles.includes(userRole || '')
                            ? 0.5
                            : 1,
                      }}
                      onClick={() => {
                        if (
                          !(
                            el.disabledForRoles &&
                            el.disabledForRoles &&
                            el.disabledForRoles.includes(userRole || '')
                          )
                        ) {
                          handleClickMenu(el.link)
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isOpen ? 3 : 'auto',
                          justifyContent: 'center',
                          color:
                            history.location.pathname === el.link
                              ? 'white'
                              : 'rgb(117,117,117)',
                        }}
                      >
                        {iconSidebar}
                      </ListItemIcon>
                      <ListItemText
                        secondary={el.text}
                        sx={{ opacity: isOpen ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
            <Divider />
            <List>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  href="/login"
                  onClick={logOutFunc}
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title="Déconnexion" placement="right">
                      <LogoutIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    secondary={'Deconnexion'}
                    sx={{ opacity: isOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
