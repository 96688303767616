import { AnyAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { IResponseStatus } from '../../prescriptions/reducers'
import * as Services from '../services'
import { parseResponse } from '../../../store/saga/responseParser'
import * as Constants from '../constants'

function* getFullPdf(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.getFullPdf,
      api_key,
      action.path,
      action.url,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_FULL_PDF_SUCCESS,
      Constants.GET_FULL_PDF_FAILED,
    )
    response.status === 200
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_FULL_PDF_FAILED, error })
  }
}

export const pdfViewerSaga = [takeLatest(Constants.GET_FULL_PDF, getFullPdf)]
