import * as yup from 'yup'

export const patientSchema = yup.object().shape({
  gender: yup.string().oneOf(['M', 'F']).required('Le sexe est obligatoire'),
  patientName: yup
    .string()
    .trim()
    .min(2, "Le nom doit être composé d'au moins 2 caractères")
    .required('Le nom du patient est obligatoire'),
  firstName: yup
    .string()
    .trim()
    .min(2, "Le prénom doit être composé d'au moins 2 caractères")
    .required('Le prénom du patient est obligatoire'),
  birthDay: yup
    .string()
    .min(1, 'Veuillez choisir une date de naissance')
    .required('La date de naissance est obligatoire'),
  address: yup
    .string()
    .trim()
    .min(1, 'Veuillez renseigner une adresse')
    .required("L'adresse est obligatoire"),
  city: yup
    .string()
    .trim()
    .min(1, 'Veuillez renseigner une ville')
    .required('La ville est obligatoire'),
  zipCode: yup
    .string()
    .matches(/(\d){5}/, 'Veuillez renseigner un code postal valide')
    .required('Le code postal est obligatoire'),
  phone1: yup
    .string()
    // .matches(
    //   /^(\+33\s(\d\s){1}(\d\d\s){3}(\d\d){1})$/,
    //   "Le numéro de téléphone n'est pas au bon format",
    //)
    .required('Le numéro de téléphone est obligatoire'),
  // Optional or removed fields
  socialInsuranceNumber: yup
    .string()
    .trim()
    .matches(
      /[1-2]{1} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{3} [0-9]{3} [0-9]{2}/,
      'Veuillez renseigner un numéro de sécurité sociale valide',
    )
    .required('Le numéro de sécurité sociale est obligatoire'),
  doctor: yup.mixed().notRequired(),
  phone2: yup.string().notRequired(),
  phone3: yup.string().notRequired(),
  ald: yup.boolean().notRequired(),
  cpamCenter: yup.string().notRequired(),
})

export const updatePatientSchema = yup.object().shape({
  gender: yup.string().oneOf(['M', 'F']),
  patientName: yup
    .string()
    .trim()
    .min(2, "Le nom doit être composé d'au moins 2 caractères"),
  firstName: yup
    .string()
    .trim()
    .min(2, "Le prénom doit être composé d'au moins 2 caractères"),
  birthDay: yup.string().min(1, 'Veuillez choisir une date de naissance'),
  address: yup.string().trim().min(1, 'Veuillez renseigner une adresse'),
  zipCode: yup
    .string()
    .matches(/(\d){5}/, 'Veuillez renseigner un code postal valide'),
  city: yup.string().trim().min(1, 'Veuillez renseigner une ville'),
  socialInsuranceNumber: yup
    .string()
    .nullable()
    .matches(
      /([1,2]{1}) (\d{2}) (\d{2}) (\d{2}) (\d{3}) (\d{3}) (\d{2})/,
      "Le numéro de sécurité sociale n'est pas complet",
    ),
  doctor: yup.object().typeError('Veuillez renseigner un médecin traitant'),
  phone1: yup
    .string()
    .matches(
      /^(\+33\s(\d\s){1}(\d\d\s){3}(\d\d){1})$/,
      "Le numéro de téléphone n'est pas au bon format",
    ),
  phone2: yup.string(),
  phone3: yup.string(),
  ald: yup.boolean(),
  cpamCenter: yup
    .string()
    .min(1, "Veuillez renseigner la caisse d'affiliation"),
})

export const messageSchema = yup.object().shape({
  messageContent: yup.string().min(1, 'Veuillez entrer un message'),
  title: yup.string().min(1, 'Veuillez entrer un objet'),
})

export const doctorSchema = yup.object().shape({
  cardioImplant: yup.boolean(),
  cardioAttent: yup.boolean(),
  doctorAttent: yup.boolean(),
  lastName: yup
    .string()
    .trim()
    .min(2, "Le nom doit être composé d'au moins 2 caractères"),
  firstName: yup.string(),
  address: yup.string(),
  zipCode: yup.string(),
  city: yup.string(),
  country: yup.object().nullable(),
  rppsNumber: yup.string(),
  phone: yup.string().optional(),
  mail: yup.lazy((value) => (!value ? yup.string() : yup.string().email())),
  billing: yup.boolean(),
})

export const transmitterSchema = yup.object().shape({
  selectedReferenceId: yup.string().notOneOf(['not-defined']),
  serialNumber: yup
    .string()
    .trim()
    .min(1, 'Veuillez renseigner le numéro de série'),
  deliveryDate: yup
    .string()
    .trim()
    .min(1, 'Veuillez renseigner la date de remise'),
  centerId: yup.number().required(),
})

export const prothesisSchema = yup
  .object()
  .shape({
    model: yup.string().required(),
    reference: yup.string().required(),
    makerId: yup.number().required(),
    gammeId: yup.number(),
    rangeId: yup.number().optional(),
    facturation: yup.string().optional(),
  })
  .required()
