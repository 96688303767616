import { RootState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ForgotPswdComponent from '../components/ForgotPswdComponent';
import { forogtPwd } from '../actions';

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forogtPwd: (mail: string) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(forogtPwd(mail, resolve, reject));
            });
        },
    };
};

const connector = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export type PropsFromReduxForgot = ConnectedProps<typeof connector>

export default withRouter(connector(ForgotPswdComponent))