import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { ConstructorKeyDto } from 'src/helpers/dto'
import { FaKey } from 'react-icons/fa'
import AddNewKeyModal from './AddNewKeyModal'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { deleteConstructorKey, findConstructorKeys } from '../../thunks'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { companyIcons } from '../../../../utils/constants'

interface MakerKeyManagementProps {
  centerId: number
}

const MakerKeyManagement: React.FC<MakerKeyManagementProps> = ({
  centerId,
}) => {
  const dispatch = useAppDispatch()
  const [isAddKeyModalOpen, setIsAddKeyModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const keys = useAppSelector(
    ({ centersReducer }) => centersReducer.constructorKeys,
  )
  const [deletingKey, setDeletingKey] = useState<ConstructorKeyDto | null>(null)
  const [selectedKey, setSelectedKey] = useState<ConstructorKeyDto | null>(null)

  const handleOpenAddKeyModal = () => {
    setIsAddKeyModalOpen(true)
  }

  const handleCloseAddKeyModal = () => {
    setIsAddKeyModalOpen(false)
    setSelectedKey(null)
  }

  const handleDeleteKey = (key: ConstructorKeyDto) => {
    setDeletingKey(key)
  }

  const handleEditKey = (key: ConstructorKeyDto) => {
    setIsAddKeyModalOpen(true)
    setSelectedKey(key)
  }

  useEffect(() => {
    setIsLoading(true)
    dispatch(findConstructorKeys(centerId) as any)
      .unwrap()
      .then(() => {
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId])

  const columns = [
    {
      headerName: 'Nom du fichier',
      field: 'keyFileName',
      width: 200,
    },
    {
      headerName: 'ID Client',
      field: 'clientId',
      width: 200,
    },

    {
      headerName: 'Connecté au moins une fois',
      field: 'connectDone',
      width: 200,
      renderCell: (params: any) => {
        return params.row.connectDone ? 'Oui' : 'Non'
      },
    },
    {
      headerName: 'Fabricant',
      field: 'makerId',
      renderCell: (params: any) => {
        return <span>{companyIcons[params.row.makerId]}</span>
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params: any) => (
        <>
          <Tooltip title="Modifier">
            <IconButton
              onClick={() => handleEditKey(params.row)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer">
            <IconButton
              onClick={() => handleDeleteKey(params.row)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ]

  const confirmDelete = () => {
    if (!deletingKey) {
      return
    }
    dispatch(deleteConstructorKey(deletingKey.id) as any)
      .unwrap()
      .then(() => {
        setDeletingKey(null)
      })
  }

  return (
    <>
      <Toolbar
        sx={{
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          borderRadius: '15px 15px 0px 0px',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          Clés constructeurs
        </Typography>
        <Tooltip title="Ajouter une clé">
          <IconButton onClick={() => setIsAddKeyModalOpen(true)}>
            <FaKey />+
          </IconButton>
        </Tooltip>
      </Toolbar>
      {isLoading && <LinearProgress />}
      <DataGridPro
        rows={keys}
        loading={isLoading}
        columns={columns}
        style={{ minHeight: '400px' }}
        isRowSelectable={() => false}
      />
      {isAddKeyModalOpen && (
        <AddNewKeyModal
          open={isAddKeyModalOpen}
          setOpen={handleOpenAddKeyModal}
          centerId={centerId}
          onClose={handleCloseAddKeyModal}
          editingKey={selectedKey}
        />
      )}
      <Dialog
        open={!!deletingKey}
        onClose={() => setDeletingKey(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer ce cle ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletingKey(null)}>Fermer</Button>
          <Button onClick={confirmDelete} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MakerKeyManagement
