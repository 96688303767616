import PrescriptionsContainer from '../containers/PrescriptionsContainer';

export const PrescriptionsRoutes = [
  {
    key: 'prescriptions',
    exact: true,
    path: '/prescriptions',
    component: PrescriptionsContainer
  }
]
