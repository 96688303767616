import { createSlice } from '@reduxjs/toolkit'
import { CompanyType } from '../../../common/interfaces'
import { ICountry } from 'src/modules/patients/reducers'
import { IRole, IUser } from 'src/modules/messaging/reducers'
import {
  createConstructorKey,
  createUser,
  deleteConstructorKey,
  deleteUser,
  findConstructorKeys,
  findCountries,
  findRoles,
  updateConstructorKey,
  updateUser,
} from '../thunks'
import { findContacts } from '../thunks'
import { ConstructorKeyDto } from 'src/helpers/dto'

interface IState {
  centers: {
    data: ICompany[]
    totalPages: number
  }
  centersSummary: ICentersSummary
  centerDetails: ICompany | null
  countries: ICountry[]
  roles: IRole[]
  makers: {
    data: ICompany[]
    totalPages: number
  }
  contacts: IUser[]
  constructorKeys: ConstructorKeyDto[]
}

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface ICompany {
  id: number
  companyName: string | null
  address: string | null
  zipCode: string | null
  city: string | null
  countryId: number | null
  phone: string | null
  type: string | null
  Enum: ['CENTER', 'MAKER', 'OWNER'] | null
  logoFileId: number | null
}

export interface ICentersSummary {
  centersCount: number
  implantCentersCount: number
}

const initialState = {
  centers: {
    data: [],
    totalPages: 0,
  },
  makers: {
    data: [],
    totalPages: 0,
  },
  centersSummary: {
    centersCount: 0,
    implantCentersCount: 0,
  },
  centerDetails: null,
  countries: [],
  roles: [],
  contacts: [],
  constructorKeys: [],
} as IState

const centersSlice = createSlice({
  name: 'centers',
  initialState,
  reducers: {
    getCompanySuccess(state, action) {
      console.log({ action })
      if (action.payload.type === CompanyType.MAKER) {
        state.makers = {
          data: action.payload.data.data,
          totalPages: action.payload.data.totalPages,
        }
      } else {
        state.centers = {
          data: action.payload.data.data,
          totalPages: action.payload.data.totalPages,
        }
      }
    },
    getCompaniesSummarySuccess(state, action) {
      state.centersSummary.centersCount = action.payload.centersCount
      state.centersSummary.implantCentersCount =
        action.payload.implantCentersCount
    },
    getCompanyById(state, action) {
      state.centerDetails = action.payload
    },
    createCompanySuccess(state, action) {
      state.centers.data.push(action.payload)
    },
    updateCompanySuccess(state, action) {
      console.log({ action })
      const index = state.centers.data.findIndex(
        (center) => center.id === action.payload.id,
      )
      if (index !== -1) {
        state.centers.data[index] = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state, action) => {
        state.contacts.push(action.payload)
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.contacts.findIndex(
          (c) => c.id === action.payload.id,
        )

        if (index !== -1) {
          state.contacts[index] = action.payload
        }
      })
      .addCase(findCountries.fulfilled, (state, action) => {
        state.countries = action.payload
      })
      .addCase(findRoles.fulfilled, (state, action) => {
        state.roles = action.payload
      })
      .addCase(findContacts.fulfilled, (state, action) => {
        state.contacts = action.payload.data
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const index = state.contacts.findIndex(
          (c) => c.id === action.payload.id,
        )

        if (index !== -1) {
          state.contacts.splice(index, 1)
        }
      })
      .addCase(findConstructorKeys.fulfilled, (state, action) => {
        state.constructorKeys = action.payload
      })
      .addCase(createConstructorKey.fulfilled, (state, action) => {
        state.constructorKeys.push(action.payload)
      })
      .addCase(
        deleteConstructorKey.fulfilled,
        (state, action: { payload: { id: number } }) => {
          const index = state.constructorKeys.findIndex(
            (c) => c.id === action.payload.id,
          )

          if (index !== -1) {
            state.constructorKeys = state.constructorKeys.filter(
              (c) => c.id !== action.payload.id,
            )
          }
        },
      )
      .addCase(updateConstructorKey.fulfilled, (state, action) => {
        const index = state.constructorKeys.findIndex(
          (c) => c.id === action.payload.id,
        )

        if (index !== -1) {
          state.constructorKeys[index] = action.payload
        }
      })
  },
})

export default centersSlice.reducer
export const {
  getCompanySuccess,
  getCompaniesSummarySuccess,
  getCompanyById,
  createCompanySuccess,
  updateCompanySuccess,
} = centersSlice.actions
