import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* getUserRoles(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.userRolesService,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_USER_ROLES_SUCCESS,
      Constants.GET_USER_ROLES_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_USER_ROLES_FAILED, error })
  }
}

function* getMessages(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.messageService,
      api_key,
      action.page,
      action.size,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_MESSAGES_SUCCESS,
      Constants.GET_MESSAGES_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_MESSAGES_FAILED, error })
  }
}

function* getUser(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.userService,
      api_key,
      action.page,
      action.size,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_USER_SUCCESS,
      Constants.GET_USER_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_USER_FAILED, error })
  }
}

function* sendMessage(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.sendMessage,
      api_key,
      action.message,
    )

    yield call(
      parseResponse,
      response,
      Constants.SEND_MESSAGE_SUCCESS,
      Constants.SEND_MESSAGE_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.SEND_MESSAGE_FAILED, error })
  }
}

function* getMessage(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getMessage,
      api_key,
      action.id,
    )

    yield call(
      parseResponse,
      response,
      Constants.SET_MESSAGE_DETAILS_SUCCESS,
      Constants.SET_MESSAGE_DETAILS_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.SET_MESSAGE_DETAILS_FAILED, error })
  }
}

export const messagingSagas = [
  takeLatest(Constants.GET_USER_ROLES, getUserRoles),
  takeLatest(Constants.GET_MESSAGES, getMessages),
  takeLatest(Constants.GET_USER, getUser),
  takeLatest(Constants.SEND_MESSAGE, sendMessage),
  takeLatest(Constants.SET_MESSAGE_DETAILS, getMessage),
]
