import { Dialog, Paper, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { makeStyles } from '@mui/styles'

type loaderProps = {
  text: string
  open: boolean
}

const useStyles = makeStyles(() => ({
  loaderDialog: {},
  loaderPaper: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '300px'
  },
  loaderContentText: { paddingLeft: '10px' },
}))

const Loader = (props: loaderProps) => {

  const classes = useStyles()

  return (
    <Dialog
      open={props.open}
      className={classes.loaderDialog}
    >
      <Paper elevation={2} className={classes.loaderPaper}>
        <Box
          className="loaderContent"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px'
          }}
        >
          <CircularProgress size={'1rem'} />
          <Typography className={classes.loaderContentText}>
            {props.text}
          </Typography>
        </Box>
      </Paper>
    </Dialog>
  )
}

export default Loader
