import { Refresh, ViewColumn, PersonAdd } from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import {
  Tooltip,
  IconButton,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import {
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../../reducers'
import { ToolbarInput } from './../../../../utils/ToolbarInput'

export type ToolbarProps = {
  title: string
  icon: string
  onRefresh: () => void
  onAdd?: () => void
  onDelete?: () => void
  getDoctorAutocomplete: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getCountries: () => Promise<unknown>
  addDoctor: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  setOpenUserDialog: React.Dispatch<React.SetStateAction<boolean>>
  openUserDialog: boolean
  canCreateDoctor: boolean
}

export function Toolbar({
  title,
  onRefresh,
  canCreateDoctor,
  searchText,
  setSearchText,
  setOpenUserDialog,
}: ToolbarProps) {
  const apiRef = useGridApiContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field === event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }

  return (
    <GridToolbarContainer
      sx={{
        background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
        borderRadius: '0px 15px 0px 0px',
      }}
    >
      <Box
        ml={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ pr: 10 }}
      >
        <HealthAndSafetyIcon
          sx={{
            marginRight: '1rem',
            padding: '0px 10px 0px 0px',
            color: 'white',
            width: '10% ',
            height: '10%',
          }}
        />

        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 'bold', color: 'white' }}
        >
          {title}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport /> */}
      {/* <GridToolbarFilterButton /> */}
      {!!canCreateDoctor && (
        <Tooltip title={'Inscrire un médecin'} style={{ marginRight: '5px' }}>
          <IconButton onClick={() => setOpenUserDialog(true)}>
            <PersonAdd />
          </IconButton>
        </Tooltip>
      )}
      <ToolbarInput searchText={searchText} setSearchText={setSearchText} />
      <Tooltip title={'Colonne'}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((column, key) => (
          <MenuItem key={key} onClick={handleHide} id={column.field}>
            <FormControlLabel
              control={<Checkbox checked={!column.hide} />}
              label={column.headerName as string}
            />
          </MenuItem>
        ))}
      </Menu>

      <Tooltip title={'Rafraîchir'}>
        <IconButton onClick={onRefresh}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}
