import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../../../../modules/common/Loader'
import { IStatus } from '../../reducers'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  postNewPatientStatus: (status: IStatus) => Promise<unknown>
  userID: number
  patient: any
  statusModalType: 'STATUS' | 'PATHOLOGY'
  getPatientByIdService: (id: string) => Promise<unknown>
}

const ModalStatus = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [commentStatus, setCommentStatus] = useState('')
  const [statusType, setStatusType] = useState<string>('')

  const handleClose = () => {
    setStatusType('')
    props.setIsOpen(false)
  }

  const handleChangeStatus = async () => {
    setIsLoading(true)
    try {
      await props.postNewPatientStatus({
        comment: commentStatus,
        statusType,
        patientID: props.patient.id,
        userID: props.userID,
      })
      toast.success('Statut changé')
      handleClose()

      props.getPatientByIdService(props.patient.id.toString())
    } catch {
      toast.error('Erreur lors du changement de la pathologie')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const status = props.patient.statusList.length
      ? props.patient.statusList[0]
      : null

    if (!status) {
      return
    }

    setStatusType(status.statusType || '')
    setCommentStatus(status.comment || '')
  }, [props.patient])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader
        text="Enregistrement du changement de pathologie"
        open={isLoading}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          {props.statusModalType === 'PATHOLOGY'
            ? 'Changer la pathologie'
            : 'Arrêter le suivi'}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <RadioGroup
              aria-labelledby="status-type"
              name="radio-buttons-status-type"
              defaultValue={statusType}
              value={statusType}
              onChange={(e) => setStatusType(e.target.value)}
            >
              {props.statusModalType === 'PATHOLOGY' ? (
                <>
                  <FormControlLabel
                    value="CRM"
                    control={
                      <Radio
                        sx={{
                          color:
                            statusType === 'CRM' ? '#84c2ce !important' : '',
                        }}
                      />
                    }
                    label="Patient suivi pour prothèse cardiaque"
                  />
                  <FormControlLabel
                    value="HF"
                    control={
                      <Radio
                        sx={{
                          color:
                            statusType === 'HF' ? '#84c2ce !important' : '',
                        }}
                      />
                    }
                    label="Patient suivi pour une insuffisance cardiaque "
                  />
                  <FormControlLabel
                    value="CRM_HF"
                    control={
                      <Radio
                        sx={{
                          color:
                            statusType === 'CRM_HF' ? '#84c2ce !important' : '',
                        }}
                      />
                    }
                    label="Patient suivi pour une prothèse et une insuffisance cardiaque "
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    value="ARCHIVED"
                    control={
                      <Radio
                        sx={{
                          color:
                            statusType === 'ARCHIVED'
                              ? '#84c2ce !important'
                              : '',
                        }}
                      />
                    }
                    label="Patient archivé"
                  />
                  <FormControlLabel
                    value="DIED"
                    control={
                      <Radio
                        sx={{
                          color:
                            statusType === 'DIED' ? '#84c2ce !important' : '',
                        }}
                      />
                    }
                    label="Patient décédé"
                  />
                  <FormControlLabel
                    value="NOT_FOLLOWED"
                    control={
                      <Radio
                        sx={{
                          color:
                            statusType === 'NOT_FOLLOWED'
                              ? '#84c2ce !important'
                              : '',
                        }}
                      />
                    }
                    label="Patient non suivi"
                  />
                </>
              )}
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              margin: '10px 0',
            }}
          >
            <TextField
              name="comment"
              multiline
              variant="standard"
              label="Commentaire"
              value={commentStatus}
              onChange={(e) => setCommentStatus(e.target.value)}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
          >
            <IconButton onClick={handleChangeStatus}>
              <SaveIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default ModalStatus
