import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  patientExportSummary,
  transmitterSummary,
  patientSummary,
  billingSummary,
  prescriptionsSummary,
  refreshToken,
} from '../actions'
import { logOut } from '../../auth/actions'
import HomeComponent from '../components/HomeComponent'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    homeData: state.homeDataReducer,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    patientExportSummary: (token: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(patientExportSummary(token, resolve, reject))
      })
    },
    transmitterSummary: (token: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(transmitterSummary(token, resolve, reject))
      })
    },
    patientSummary: (token: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(patientSummary(token, resolve, reject))
      })
    },
    billingSummary: (token: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(billingSummary(token, resolve, reject))
      })
    },
    prescriptionsSummary: (token: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(prescriptionsSummary(token, resolve, reject))
      })
    },
    refreshToken: (token: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(refreshToken(token, resolve, reject))
      })
    },
    logOut: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(logOut(resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxHome = ConnectedProps<typeof connector>

export default withRouter(connector(HomeComponent))
