import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import React, { useEffect, useRef, useState } from 'react'
import { PropsFromReduxAlerts } from '../containers/AlertsContainer'
import { Box, Button, Tab, Tabs, Tooltip } from '@mui/material'
import { Toolbar } from './Table/Toolbar'
import {
  AddComment,
  Check,
  Comment,
  Help,
  PictureAsPdf,
  RadioButtonUnchecked,
  RemoveRedEye,
} from '@mui/icons-material'
import Loader from '../../../modules/common/Loader'
import { toast } from 'react-toastify'
import { AlertFilter, AlertType, IAlertPatient } from '../reducers'
import ReadingModal from './AlertTreatment/ReadingModal'
import WritingModal from './AlertTreatment/WritingModal'
import TreatmentModal from './AlertTreatment/TreatmentModal'
import { CustomTooltip } from '../../../utils/CustomTooltip'
import history from '../../../routes/history'
import PaginationComponent from './../../common/PaginationComponent'
import { IPatientRecord } from 'src/modules/messaging/reducers'
import parse from 'html-react-parser'
import moment from 'moment'
import PdfPreview from '../../../utils/PdfPreview'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { companyClickableIconsNameMap } from '../../../utils/constants'
import { capitalize, createSearchParams } from '../../../utils/utils'

import { useLocation } from 'react-router-dom'
import { canViewPatientData } from '../../auth/selectors/authSelector'
import { useSelector } from 'react-redux'
import { API_URL } from '../constants'

const getAlertTabIndex = (type: AlertType): number => {
  switch (type) {
    case 'ALERTS':
      return 0
    case 'TRANSMISSIONS':
      return 1
    case 'HISTORY':
      return 2
    case 'DISCONNECTED_DEVICES':
      return 3
    default:
      return 0
  }
}

interface IPreviewProsition {
  x: number
  y: number
  alertId: number
  fileId: number
}

const AlertsComponent = (
  props: PropsFromReduxAlerts & { patientId?: number },
) => {
  const location = useLocation()

  const q = useRef(new URLSearchParams(location.search)).current
  const initialPreviewPosition: IPreviewProsition = {
    x: 0,
    y: 0,
    alertId: -1,
    fileId: -1,
  }

  const [tab, setTab] = useState(
    getAlertTabIndex(q.get('type') as AlertType) || 0,
  )
  const [activePage, setActivePage] = useState(
    q.has('page') ? parseInt(q.get('page') as string) : 1,
  )
  const [rowsPerPage, setRowsPerPage] = useState(
    q.has('limit') ? parseInt(q.get('limit') as string) : 20,
  )
  const [searchText, setSearchText] = useState<string>(
    (q.get('search') as string) || '',
  )
  const [selectedGammes, setSelectedGammes] = useState<string[]>(
    q.getAll('gamme[]') || [],
  )
  const [selectedMakerIds, setSelectedMakerIds] = useState<number[]>(
    (q.getAll('maker[]') as string[])?.map((m) => parseInt(m)) || [],
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isReadingOpen, setIsReadingOpen] = useState(false)
  const [isWritingOpen, setIsWritingOpen] = useState(false)
  const [isTreatmentOpen, setIsTreatmentOpen] = useState(false)
  const [selectedAlert, setSelectedAlert] = useState<IAlertPatient>()
  const [filter, setFilter] = useState<AlertFilter>('ALL')
  const [seeMoreRecords, setSeeMoreRecords] = useState<number[]>([])
  const [sortModel, setSortModel] = useState<GridSortModel | null>(null)
  const [reportPreviewPosition, setReportPreviewPosition] = useState(
    initialPreviewPosition,
  )
  const [selectedCenterIds, setSelectedCenterIds] = useState<number[]>(
    (q.getAll('center[]') as string[])?.map((c) => parseInt(c)) || [],
  )
  const [delayPdfHover, setDelayPdfHover] = useState(setTimeout(() => {}, 1))

  const getAlertType = (): AlertType => {
    switch (tab) {
      case 0:
        return 'ALERTS'
      case 1:
        return 'TRANSMISSIONS'
      case 2:
        return 'HISTORY'
      case 3:
        return 'DISCONNECTED_DEVICES'
      default:
        return 'ALERTS'
    }
  }

  const fetchDatas = async (loader: boolean) => {
    !!loader && setIsLoading(true)

    try {
      await props.getAlerts(
        rowsPerPage,
        activePage - 1,
        getAlertType(),
        searchText,
        filter,
        selectedGammes,
        selectedMakerIds,
        selectedCenterIds,
        sortModel?.length ? sortModel[0] : null,
        props.patientId || null,
      )
    } catch {
      toast.error('Impossible de charger des alertes')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDisplay = async () => {
    try {
      await props.getProsthesisGammeRange(100, 0)
      // await props.getAlertsSummary()
      await props.getUserSettings()
    } catch {
      toast.error('Impossible de charger des alertes')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDisplay()
    props.getMakers()
    props.getCenters()
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchDatas(false)
    const obj: { [key: string]: string | string[] } = {
      type: getAlertType() || '',
      search: searchText || '',
      filter: filter || '',
      gamme: selectedGammes || '',
      maker: selectedMakerIds.map((s) => s.toString()) || '',
      center: selectedCenterIds.map((c) => c.toString()) || '',
      sortBy: (sortModel?.length ? sortModel[0].field : null) || '',
      sort: (sortModel?.length ? sortModel[0].sort : null) || '',
      page: activePage.toString() || '',
      limit: rowsPerPage.toString() || '',
    }

    const params = createSearchParams(obj)

    const q = params.toString()

    if (history.location.search !== q) {
      history.push({
        search: q,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsPerPage,
    tab,
    activePage,
    isReadingOpen,
    isWritingOpen,
    isTreatmentOpen,
    filter,
    selectedGammes,
    selectedMakerIds,
    selectedCenterIds,
    sortModel,
    searchText,
  ])

  useEffect(() => {
    const q = new URLSearchParams(location.search)
    const newTab = getAlertTabIndex(q.get('type') as AlertType)
    if (newTab !== undefined && newTab !== tab) {
      setTab(newTab)
    }

    const newPage = q.has('page') ? parseInt(q.get('page') as string) : 1
    if (newPage !== activePage) {
      setActivePage(newPage)
    }

    const newLimit = q.has('limit') ? parseInt(q.get('limit') as string) : 20
    if (newLimit !== rowsPerPage) {
      setRowsPerPage(newLimit)
    }

    const newSearchText = q.get('search') || ''
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }

    const newSelectedGammes = q.getAll('gamme[]') || []
    if (newSelectedGammes.length !== selectedGammes.length) {
      setSelectedGammes(newSelectedGammes)
    }

    const newSelectedMakerIds = (q.getAll('maker[]') as string[])?.map((m) =>
      parseInt(m),
    )

    if (newSelectedMakerIds.length !== selectedMakerIds.length) {
      setSelectedMakerIds(newSelectedMakerIds)
    }

    const newSelectedCenterIds = (q.getAll('center[]') as string[])?.map((c) =>
      parseInt(c),
    )
    if (newSelectedCenterIds.length !== selectedCenterIds.length) {
      setSelectedCenterIds(newSelectedCenterIds)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    props.getAlertCount(
      'ALERTS',
      searchText,
      filter,
      selectedGammes,
      selectedMakerIds,
      selectedCenterIds,
      props.patientId || null,
    )
    props.getAlertCount(
      'TRANSMISSIONS',
      searchText,
      filter,
      selectedGammes,
      selectedMakerIds,
      selectedCenterIds,
      props.patientId || null,
    )
    props.getAlertCount(
      'HISTORY',
      searchText,
      filter,
      selectedGammes,
      selectedMakerIds,
      selectedCenterIds,
      props.patientId || null,
    )
    props.getAlertCount(
      'DISCONNECTED_DEVICES',
      searchText,
      filter,
      selectedGammes,
      selectedMakerIds,
      selectedCenterIds,
      props.patientId || null,
    )

    props.getAlertCount(
      'CHECKED',
      searchText,
      filter,
      selectedGammes,
      selectedMakerIds,
      selectedCenterIds,
      props.patientId || null,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tab,
    isReadingOpen,
    isWritingOpen,
    isTreatmentOpen,
    filter,
    selectedGammes,
    selectedMakerIds,
    selectedCenterIds,
    searchText,
    props.patientId,
  ])
  useEffect(() => {
    fetchDatas(false)
  }, [searchText]) //eslint-disable-line

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChangeTab = (newTab: number) => {
    setActivePage(1)
    setTab(newTab)
  }

  const prosthesisGammeName = (value: string) => {
    switch (value) {
      case 'prosthesis.gamme.defib.cardiac':
        return 'Défibrillateur'
      case 'prosthesis.gamme.monit.implant':
        return 'Moniteur'
      case 'prosthesis.gamme.stim.cardiac':
        return 'Stimulateur'
      case 'prosthesis.gamme.null' || null:
        return 'Gamme non définie'
    }
  }

  const handleTreatmentRead = async (alert: IAlertPatient) => {
    setSelectedAlert(alert)
    if (!alert.isViewed) await props.patientExportAction('VIEW', alert.id)
    await props.getAlertActions(alert.id)
    setIsReadingOpen(true)
  }

  const handleTreatmentWriting = async (alert: IAlertPatient) => {
    setSelectedAlert(alert)
    setIsWritingOpen(true)
  }

  const handleTreatmentCheck = async (alert: IAlertPatient) => {
    if (!alert.checked) {
      setSelectedAlert(alert)
      setIsTreatmentOpen(true)
    }
  }

  const onReportHover = (event: React.MouseEvent<HTMLDivElement>, row: any) => {
    setDelayPdfHover(
      setTimeout(() => {
        const height = window.innerHeight * 0.8
        const width = (height * 210) / 297

        const x = event.clientX - (width + 150)
        const y = 80
        setReportPreviewPosition({
          x,
          y,
          alertId: row.id,
          fileId: row.records[0].id,
        })
      }, 500),
    )
  }

  const onReportLeave = () => {
    clearTimeout(delayPdfHover)
    setReportPreviewPosition(initialPreviewPosition)
  }

  const handleClickAttached = (alertId: number, reportId: number) => {
    clearTimeout(delayPdfHover)
    setReportPreviewPosition(initialPreviewPosition)
    history.push(`/full-pdf`, {
      path: API_URL,
      url: `patient/export/${alertId}/record/${reportId}`,
    })
  }

  const getFilterHeader = () => {
    switch (filter) {
      case 'ALL':
        return (
          <RadioButtonUnchecked sx={{ color: '#3F3F3F30', fontSize: '20px' }} />
        )
      case 'NOT_VIEWED':
        return <RadioButtonUnchecked sx={{ color: 'red', fontSize: '20px' }} />
      case 'VIEWED':
        return (
          <RadioButtonUnchecked
            sx={{ color: 'rgb(252, 165, 33)', fontSize: '20px' }}
          />
        )
      case 'CHECKED':
        return <Check sx={{ color: 'limegreen', fontSize: '20px' }} />
    }
  }

  const changeFilter = () => {
    setActivePage(1)
    switch (filter) {
      case 'ALL':
        setFilter('NOT_VIEWED')
        break
      case 'NOT_VIEWED':
        setFilter('VIEWED')
        break
      case 'VIEWED':
        setFilter('CHECKED')
        break
      case 'CHECKED':
        setFilter('ALL')
        break
    }
  }

  const canViewPatientInfos = useSelector(canViewPatientData)

  const columns: GridColumns = [
    {
      field: 'patientId',
      headerName: 'ID Patient',
      width: 120,
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => `${params.row.patientId}`,
    },
    ...(canViewPatientInfos && !props.patientId
      ? [
          {
            field: 'patientLastName',
            headerName: 'Nom ',
            width: 150,
            sortable: true,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.patientLastName,
          },
          {
            field: 'patientFirstName',
            headerName: 'Prénom ',
            width: 150,
            sortable: true,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.patientFirstName
                ? capitalize(params.row.patientFirstName.toLowerCase())
                : '',
          },
        ]
      : []),
    {
      field: 'createdAt',
      headerName: 'Date de réception',
      width: 150,
      sortable: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleDateString()}`,
      sortComparator: (v1, v2) => {
        const date1 = v1
          ? moment(v1, 'DD-MM-YYYY').toDate()
          : new Date('01-01-1990')

        const date2 = v2
          ? moment(v2, 'DD-MM-YYYY').toDate()
          : new Date('01-01-1990')

        return date1.getTime() - date2.getTime()
      },
    },
    {
      field: 'exportStatuts',
      headerName: 'Niveau',
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row.companyName === 'ABBOTT') {
          return (
            <Tooltip title="Inconnu">
              <Help style={{ color: '#96dcea' }} />
            </Tooltip>
          )
        }
        let color = 'red'

        if (params.row.exportStatus === 'Yellow') {
          color = 'rgb(252, 165, 33)'
        } else if (params.row.exportStatus === 'White') {
          color = 'lightgreen'
        }
        return <NotificationsIcon style={{ color }} />
      },
    },
    ...(canViewPatientInfos
      ? [
          {
            field: 'alertShort',
            headerName: tab === 1 ? 'Résumé transmission' : "Résumé d'alerte",
            width: 450,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
              <Box
                sx={{
                  cursor: 'default',
                  marginY: '10px',
                  fontWeight: params.row.isViewed ? 'normal' : 'bold',
                }}
              >
                {params.row.alertShort ? parse(params.row.alertShort) : ''}
              </Box>
            ),
          },
          {
            field: 'col5',
            headerName: 'Rapport',
            width: 100,

            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column',
                }}
              >
                {params.row.records ? (
                  <>
                    {params.row.records[0] &&
                      !seeMoreRecords.includes(params.row.id) && (
                        <div
                          onMouseEnter={(event) =>
                            onReportHover(event, params.row)
                          }
                          onMouseLeave={() => onReportLeave()}
                        >
                          <CustomTooltip>
                            <div
                              key={params.row.records[0]}
                              className="button-alert-pdf"
                              onClick={() =>
                                handleClickAttached(
                                  params.row.id,
                                  params.row.records[0].id,
                                )
                              }
                            >
                              <PictureAsPdf
                                sx={{ color: 'red', fontSize: '20px' }}
                              />
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: params.row.records[0].is_viewed
                                    ? 'normal'
                                    : 'bold',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: 'gray',
                                  }}
                                >
                                  Détails
                                </span>
                              </p>
                            </div>
                          </CustomTooltip>
                        </div>
                      )}
                    {params.row.records.length > 1 &&
                      !seeMoreRecords.includes(params.row.id) && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '5px',
                            width: '100%',
                          }}
                        >
                          <Button
                            sx={{ color: '#84c2ce', fontSize: '11px' }}
                            onClick={() =>
                              setSeeMoreRecords([
                                ...seeMoreRecords,
                                params.row.id,
                              ])
                            }
                          >
                            Voir plus
                          </Button>
                        </div>
                      )}
                    {params.row.records.length > 1 &&
                      seeMoreRecords.includes(params.row.id) &&
                      params.row.records.map(
                        (record: IPatientRecord, index: number) => (
                          <div
                            key={index}
                            onMouseEnter={(ev) => onReportHover(ev, params.row)}
                          >
                            <CustomTooltip text={record.record_file}>
                              <div
                                key={index}
                                className="button-alert-pdf"
                                onClick={() =>
                                  handleClickAttached(params.row.id, record.id)
                                }
                              >
                                <PictureAsPdf
                                  sx={{ color: 'red', fontSize: '20px' }}
                                />
                                <p
                                  style={{
                                    margin: 0,
                                    fontWeight: record.is_viewed
                                      ? 'normal'
                                      : 'bold',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: 'bold',
                                      color: 'gray',
                                    }}
                                  >
                                    Cliquer ici
                                  </span>
                                </p>
                              </div>
                            </CustomTooltip>
                          </div>
                        ),
                      )}
                  </>
                ) : (
                  'Pas de rapport'
                )}
              </div>
            ),
          },
        ]
      : []),

    {
      field: 'col7',
      headerName: 'ID Alerte',
      width: 100,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => `${params.row.id}`,
    },

    {
      field: 'col8',
      headerName: 'Prothèse',
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.prosthesisGamme
            ? prosthesisGammeName(params.row.prosthesisGamme)
            : ''
        }`,
    },
    {
      field: 'col9',
      headerName: 'Fabricant',
      width: 170,
      sortable: false,
      renderCell: (params) =>
        params.row.companyName ? (
          companyClickableIconsNameMap[params.row.companyName]
        ) : (
          <></>
        ),
    },
    ...(canViewPatientInfos
      ? [
          {
            field: 'col10',
            renderHeader: () => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ marginRight: '20px' }}>Actions</span>
                  <CustomTooltip text="Changer de filtre">
                    <Button
                      className="btn-alert-traitment"
                      onClick={changeFilter}
                    >
                      {getFilterHeader()}
                    </Button>
                  </CustomTooltip>
                </Box>
              )
            },
            sortable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <CustomTooltip
                    text={
                      params.row.checked
                        ? "L'alerte est traitée"
                        : params.row.isViewed
                          ? 'À traiter'
                          : "Doit d'abord être visualisée"
                    }
                  >
                    <Button
                      className="btn-alert-traitment"
                      onClick={() => handleTreatmentCheck(params.row)}
                    >
                      {params.row.checked ? (
                        <Check sx={{ color: 'limegreen', fontSize: '20px' }} />
                      ) : (
                        <RadioButtonUnchecked
                          sx={{
                            color: params.row.isViewed
                              ? 'rgb(252, 165, 33)'
                              : 'red',
                            fontSize: '20px',
                          }}
                        />
                      )}
                    </Button>
                  </CustomTooltip>
                  <CustomTooltip text="Liste des commentaires">
                    <Button
                      className="btn-alert-traitment"
                      onClick={() => handleTreatmentRead(params.row)}
                    >
                      <Comment sx={{ color: '#3F3F3F', fontSize: '20px' }} />
                    </Button>
                  </CustomTooltip>
                  <CustomTooltip text="Ajouter un commantaire">
                    <Button
                      className="btn-alert-traitment"
                      onClick={() => handleTreatmentWriting(params.row)}
                    >
                      <AddComment sx={{ color: '#3F3F3F', fontSize: '18px' }} />
                    </Button>
                  </CustomTooltip>
                  <CustomTooltip text="Voir patient">
                    <Button
                      className="btn-alert-traitment"
                      onClick={() =>
                        history.push(`/patients/${params.row.patientId}`)
                      }
                    >
                      <RemoveRedEye
                        sx={{ color: '#3F3F3F', fontSize: '20px' }}
                      />
                    </Button>
                  </CustomTooltip>
                </div>
              )
            },
          },
        ]
      : []),
  ]
  return (
    <Box
      sx={{
        height: 'calc(100vh - 240px)',
        width: '100%',
        position: 'relative',
      }}
    >
      <Loader open={isLoading} text="Chargement des alertes" />

      {!!selectedAlert && !!isReadingOpen && (
        <ReadingModal
          isOpen={isReadingOpen}
          setIsOpen={setIsReadingOpen}
          alert={selectedAlert}
          patientExportAction={props.patientExportAction}
          alertActions={props.alertActions}
        />
      )}
      {!!selectedAlert && !!isWritingOpen && (
        <WritingModal
          isOpen={isWritingOpen}
          setIsOpen={setIsWritingOpen}
          alert={selectedAlert}
          patientExportAction={props.patientExportAction}
        />
      )}
      {!!selectedAlert && !!isTreatmentOpen && (
        <TreatmentModal
          isOpen={isTreatmentOpen}
          setIsOpen={setIsTreatmentOpen}
          alert={selectedAlert}
          patientExportAction={props.patientExportAction}
        />
      )}

      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ffa726',
          },
        }}
        textColor="primary"
        value={tab}
        onChange={(_, newTab) => handleChangeTab(newTab)}
        aria-label="exams-tabs"
      >
        <Tab
          label={`alertes : ${
            props.summary?.nbNewAlerts ? props.summary.nbNewAlerts : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`transmissions : ${
            props.summary?.nbNewTransmissions
              ? props.summary.nbNewTransmissions
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Archives : ${
            props.summary?.nbArchived ? props.summary.nbArchived : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`dispositifs déconnectés : ${props.summary?.nbDisconnected ? props.summary.nbDisconnected : 0}`}
          {...a11yProps(0)}
          wrapped={false}
        />
      </Tabs>
      <DataGridPro
        rows={props.alerts ? props.alerts : []}
        columns={columns}
        components={{
          Toolbar: props.patientId ? undefined : Toolbar,
        }}
        headerHeight={80}
        componentsProps={{
          toolbar: {
            title: 'Alertes',
            icon: 'fa fa-syringe',
            onRefresh: fetchDatas,
            searchText,
            setSearchText: (value: string) => {
              setSearchText(value)
              setActivePage(1)
            },
            selectedGammes,
            setSelectedGammes,
            makers: props.makers,
            centers: props.centers,
            selectedMakerIds,
            setSelectedMakerIds,
            selectedCenterIds,
            setSelectedCenterIds,
          },
        }}
        sortingMode="server"
        pageSize={rowsPerPage}
        getRowHeight={() => 'auto'}
        disableColumnMenu
        hideFooter
        onSortModelChange={(model) => {
          setSortModel(model)
        }}
        disableSelectionOnClick
        sx={{
          border: 'none',
          margin: '3px 15px',
          borderBottom: '1px solid #E0E0E0',
        }}
      />
      <PaginationComponent
        pages={props.totalPages}
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      {reportPreviewPosition.alertId !== -1 && (
        <PdfPreview
          onClose={() => {
            setReportPreviewPosition(initialPreviewPosition)
          }}
          style={{
            position: 'fixed',
            top: reportPreviewPosition.y,
            left: reportPreviewPosition.x,
            zIndex: 1000,
          }}
          getPDF={() => {
            return props.getPDF(
              reportPreviewPosition.alertId,
              reportPreviewPosition.fileId,
            )
          }}
          alertId={reportPreviewPosition.alertId}
          reportId={reportPreviewPosition.fileId}
        />
      )}
    </Box>
  )
}

export default AlertsComponent
