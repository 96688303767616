import { CORE_URL } from '../../../helpers/env'

export const GET_BILLING = 'GET_BILLING'
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS'
export const GET_BILLING_FAILED = 'GET_BILLING_FAILED'

export const GET_BILLABLE_SUMMARY = 'GET_BILLABLE_SUMMARY'
export const GET_BILLABLE_SUMMARY_SUCCESS = 'GET_BILLABLE_SUMMARY_SUCCESS'
export const GET_BILLABLE_SUMMARY_FAILED = 'GET_BILLABLE_SUMMARY_FAILED'

export const GET_BILLABLE_PATIENT = 'GET_BILLABLE_PATIENT'
export const GET_BILLABLE_PATIENT_SUCCESS = 'GET_BILLABLE_PATIENT_SUCCESS'
export const GET_BILLABLE_PATIENT_FAILED = 'GET_BILLABLE_PATIENT_FAILED'

export const CREATE_BILL = 'CREATE_BILL'
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS'
export const CREATE_BILL_FAILED = 'CREATE_BILL_FAILED'

export const API_URL = CORE_URL
