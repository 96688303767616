import CenterContainer from '../containers/CenterContainer'
import * as centerRoutes from './center'

export const CenterRoutes = [
  {
    key: 'center',
    exact: true,
    path: centerRoutes.CENTER,
    component: CenterContainer,
  },
]
