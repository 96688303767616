import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import {Box, IconButton, TextField, Typography, Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {makeStyles} from '@mui/styles'
import SendIcon from '@mui/icons-material/Send'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'src/store'
import SearchReceiverModal from './SearchReceiverModal'
import {IInterlocutor, IMessage} from '../reducers'
import {toast} from 'react-toastify'
import {messageSchema} from '../../../schemas'
import {HtmlTooltip} from '../../../utils/CustomTooltip'
import {IFoundDoctor, INewDoctorAddress, INewDoctorInfos, INewDoctorStep1} from 'src/modules/doctors/reducers'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  getDoctors: (size: number, page: number, doctorType: string, search: string) => Promise<unknown>
  getCenters: (size: number, page: number, searchText: string, isImplantCenter: boolean) => Promise<unknown>
  sendMessage: (message: IMessage) => Promise<unknown>
  fetchMessages: () => void
  getDoctorsSummary: () => Promise<unknown>
  getCentersSummary: () => Promise<unknown>
  getDoctorAutocomplete: (name: string, page: number, limit: number) => Promise<IFoundDoctor[]>;
  getCountries: () => Promise<unknown>;
  addDoctor: {
    step1: (doctor: INewDoctorStep1) => Promise<number>;
    step2: (id: number) => Promise<unknown>;
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>;
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>;
  };
}

const useStyles = makeStyles(() => ({
  typographie: {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: '500',
    color: ' rgba(0, 0, 0, 0.6);',
  },
  inputTextRecipient: {
    margin: '0 !important',
    width: '80%',
  },
}))

export default function ResponsiveDialog (props: IProps) {

  const [title, setTitle] = useState('')
  const [messageContent, setMessageContent] = useState('')
  const [searchModal, setSearchModal] = useState(false)
  const [selectedReceivers, setSelectedReceivers] = useState<string[]>([])
  const [selectedDuplicates, setSelectedDuplicates] = useState<string[]>([])
  const [isReceiver, setIsReceiver] = useState(true)

  const user = useSelector((state: RootState) => state.authReducer.user)

  const classes = useStyles()
  const handleClose = () => {
    props.setIsOpen(false)
  }

  const handleReceiverSelection = (email: string) => {
    if (email && email !== '')
      if (!selectedReceivers.includes(email) && !selectedDuplicates.includes(email))
        !!isReceiver ? setSelectedReceivers([...selectedReceivers, email]) : setSelectedDuplicates([...selectedDuplicates, email])
      else toast.error('Ce destinataire est déjà sélectionné')
    setSearchModal(false)
  }

  const handleDeleteInterlocutor = (interlocutor: 'receiver' | 'duplicate', email: string) => {
    interlocutor === 'receiver' ? setSelectedReceivers(selectedReceivers.filter((receiver) => receiver !== email)) : setSelectedDuplicates(selectedDuplicates.filter((duplicate) => duplicate !== email))
  }

  const handleSend = async () => {
    if (selectedReceivers.length > 0) {
      try {
        await messageSchema.validate({title, messageContent})
        let sendTo: IInterlocutor[] = []
        let copyTo: IInterlocutor[] = []
        selectedReceivers.forEach((receiver) => {sendTo.push({email: receiver, copy: false, read: false})})
        selectedDuplicates.forEach((duplicates) => {copyTo.push({email: duplicates, copy: true, read: false})})
        props.sendMessage({sendTo, copyTo, messageContent, title})
          .then(() => {
            toast.success('Message envoyé');
            props.fetchMessages();
            props.setIsOpen(false);
          })
          .catch(() => toast.error('Impossible d\'envoyer le message'))
      } catch (e: any) {
        toast.error(e.errors[0])
      }
    } else toast.error('Veuillez séléctionner un destinataire')
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{padding: '30px auto'}}
      PaperProps={{
        style: {borderRadius: 20},
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
            position: 'relative'
          }}
        >
          {'Nouveau message'}
          <IconButton sx={{position: 'absolute', right: '20px'}} onClick={handleClose}>
            <CloseIcon sx={{color: 'white'}} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: ' 20px 100px',
            marginTop: '15px',
          }}
        >
          <Box>
            <Box>
              <Typography
                variant="h6"
                component="h2"
                className={classes.typographie}
              >
                {`De : ${user.mail}`}
              </Typography>
            </Box>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '-12px'}}>
              <IconButton
                aria-label="de"
                size="large"
                onClick={() => {setSearchModal(true); setIsReceiver(true)}}
              >
                <ContactMailIcon
                  fontSize="inherit"
                />
              </IconButton>

              <Typography
                variant="h6"
                component="h2"
                className={classes.typographie}
                sx={{display: 'flex'}}
              >
                À :
                {selectedReceivers.map((receiver, index) => <>
                  <HtmlTooltip
                    key={receiver + index}
                    title={<Button className='group-button' sx={{height: '30px'}} onClick={() => handleDeleteInterlocutor('receiver', receiver)}>Supprimer</Button>}
                    placement="bottom">
                    <Box sx={{marginLeft: '5px', cursor: 'pointer'}}>
                      {receiver}
                    </Box>
                  </HtmlTooltip>
                  {index < (selectedReceivers.length - 1) && selectedReceivers.length > 1 && ','}
                </>
                )}
              </Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '-12px'}}>
              <IconButton
                aria-label="cc"
                size="large"
                onClick={() => {setSearchModal(true); setIsReceiver(false)}}
              >
                <ContactMailIcon
                  fontSize="inherit"
                />
              </IconButton>

              <Typography
                variant="h6"
                component="h2"
                className={classes.typographie}
                sx={{display: 'flex'}}
              >
                CC :
                {selectedDuplicates.map((duplicate, index) => <>
                  <HtmlTooltip
                    key={duplicate + index}
                    title={<Button className='group-button' sx={{height: '30px'}} onClick={() => handleDeleteInterlocutor('duplicate', duplicate)}>Supprimer</Button>}
                    placement="bottom">
                    <Box sx={{marginLeft: '5px', cursor: 'pointer'}}>
                      {duplicate}
                    </Box>
                  </HtmlTooltip>
                  {index < (selectedDuplicates.length - 1) && selectedDuplicates.length > 1 && ','}
                </>
                )}
              </Typography>
            </div>
          </Box>

          <Box>
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              label="Objet"
              type="text"
              variant="standard"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </Box>
          <br />
          <Box sx={{width: '100%'}}>
            <TextField
              id="outlined-multiline-static"
              label="Message"
              multiline
              rows={4}
              fullWidth
              placeholder="Votre message ici..."
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={handleSend}>
            <SendIcon />
          </IconButton>
        </DialogActions>
      </div>
      <SearchReceiverModal
        isOpen={searchModal}
        isReceiver={isReceiver}
        setIsOpen={setSearchModal}
        handleReceiverSelection={handleReceiverSelection}
        getDoctors={props.getDoctors}
        getCenters={props.getCenters}
        getDoctorsSummary={props.getDoctorsSummary}
        getCentersSummary={props.getCentersSummary}
        addDoctor={props.addDoctor}
        getDoctorAutocomplete={props.getDoctorAutocomplete}
        getCountries={props.getCountries}
      />
    </Dialog>
  )
}
