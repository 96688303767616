import { all } from 'redux-saga/effects'
import { authSagas } from '../../modules/auth/saga'
import { homeSagas } from '../../modules/home/saga'
import { alertSagas } from '../../modules/alerts/saga'
import { patientsSagas } from '../../modules/patients/saga'
import { doctorsSagas } from '../../modules/doctors/saga'
import { centerSagas } from '../../modules/center/saga'
import { messagingSagas } from '../../modules/messaging/saga'
import { prothesesSagas } from '../../modules/protheses/saga'
import { billingSagas } from '../../modules/billing/saga'
import { manufacturerSaga } from '../../modules/manufacturers/saga'
import { prescriptionsSagas } from '../../modules/prescriptions/saga'
import { pdfViewerSaga } from '../../modules/pdfViewer/saga'

export function* rootSaga() {
  yield all([
    ...authSagas,
    ...homeSagas,
    ...alertSagas,
    ...patientsSagas,
    ...doctorsSagas,
    ...centerSagas,
    ...messagingSagas,
    ...prothesesSagas,
    ...billingSagas,
    ...prescriptionsSagas,
    ...manufacturerSaga,
    ...pdfViewerSaga,
  ])
}
