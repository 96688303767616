import { AnyAction } from 'redux'
import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects'
import * as Constants from '../constants'
import {
  createCompanySuccess,
  getCompaniesSummarySuccess,
  getCompanySuccess,
  IResponseStatus,
  updateCompanySuccess,
} from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* getCompany(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.companyService,
      api_key,
      action.page,
      action.size,
      action.searchText,
      action.isImplantCenter,
      action.companyType,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_COMPANY_SUCCESS,
      Constants.GET_COMPANY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
    // dispatch getCompanySuccess
    if (response.status === 200) {
      console.log('Action 2', action)
      yield put(
        getCompanySuccess({ ...response.data, type: action.companyType }),
      )
    }
  } catch (error) {
    yield put({ type: Constants.GET_COMPANY_FAILED, error })
  }
}

function* getSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getCompaniesSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_COMPANIES_SUMMARY_SUCCESS,
      Constants.GET_COMPANIES_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)

    if (response.status === 200) {
      yield put(getCompaniesSummarySuccess(response.data))
    }
  } catch (error) {
    yield put({ type: Constants.GET_COMPANIES_SUMMARY_FAILED, error })
  }
}

function* getCompanyById(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getCompanyById,
      action.id,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_COMPANY_BY_ID_SUCCESS,
      Constants.GET_COMPANY_BY_ID_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (err) {}
}

function* createCompany(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.createCompany,
      api_key,
      action.company,
    )

    yield call(
      parseResponse,
      response,
      Constants.CREATE_COMPANY_SUCCESS,
      Constants.CREATE_COMPANY_FAILED,
    )

    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)

    if (response.status === 200) {
      yield put(createCompanySuccess(response.data))
    }
  } catch (err) {}
}

function* updateCompany(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updateCompany,
      api_key,
      action.id,
      action.company,
    )

    yield call(
      parseResponse,
      response,
      Constants.UPDATE_COMPANY_SUCCESS,
      Constants.UPDATE_COMPANY_FAILED,
    )

    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)

    if (response.status === 200) {
      yield put(updateCompanySuccess(response.data))
    }
  } catch (err) {}
}
export const centerSagas = [
  takeEvery(Constants.GET_COMPANY, getCompany),
  takeLatest(Constants.GET_COMPANIES_SUMMARY, getSummary),
  takeLatest(Constants.GET_COMPANY_BY_ID, getCompanyById),
  takeLatest(Constants.CREATE_COMPANY, createCompany),
  takeLatest(Constants.UPDATE_COMPANY, updateCompany),
]
