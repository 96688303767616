import { PRESCRIPTION_URL, REFACTOR_URL } from '../../../helpers/env'
import { API_URL } from '../../billing/constants'
import { IPrescriptions } from '../reducers'

export enum PrescriptionsState {
  SIGNED = 'SIGNED',
  REFUSED = 'REFUSED',
  GENERATED = 'GENERATED',
  MISSINGDATA = 'MISSINGDATA',
}
export const prescriptionsSummary = (token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/prescriptions/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      console.log(`second then in service`)
      return { data, status }
    })
    .catch((e) => {
      console.log(`catch error in service `)
      console.error(e)
    })
}

export const prescriptions = (
  token: string,
  limit: number,
  page: number,
  state: PrescriptionsState,
  read?: boolean,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/prescriptions?page=${page}&limit=${limit}&state=${state}${read ? '&read=true' : '&read=false'}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      data.data.forEach((prescription: IPrescriptions) => {
        prescription.checked = false
      })

      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePrescriptionMakerReaded = (
  token: string,
  prescription_id: string,
  maker_readed: boolean,
  maker_id: number,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/prescriptions/readed`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({
      prescription_id,
      maker_readed,
      maker_id,
    }),
  }

  let status = 0
  console.log(`parameters body ${parameters.body}`)
  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.blob()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(`Get fetch error ${e}`)
    })
}

export const getPrescriptionPdf = (token: string, pdfName: string) => {
  const ENDPOINT: RequestInfo = `${PRESCRIPTION_URL}/attached-file/${pdfName}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0
  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.blob()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(`Get fetch error ${e}`)
    })
}
