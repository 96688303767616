import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import history from '../../routes/history'
import authReducer from '../../modules/auth/reducers'
import homeDataReducer from '../../modules/home/reducers'
import alertsReducer from '../../modules/alerts/reducers'
import patientsReducer from '../../modules/patients/reducers'
import doctorsReducer from '../../modules/doctors/reducers'
import centersReducer from '../../modules/center/reducers'
import messagingReducer from '../../modules/messaging/reducers'
import prosthesisReducer from '../../modules/protheses/reducers'
import billingReducer from '../../modules/billing/reducers'
import manufacturerReducer from '../../modules/manufacturers/reducers'
import prescriptionsReducer from '../../modules/prescriptions/reducers'

export default combineReducers({
  authReducer,
  alertsReducer,
  homeDataReducer,
  patientsReducer,
  doctorsReducer,
  centersReducer,
  messagingReducer,
  prosthesisReducer,
  billingReducer,
  manufacturerReducer,
  prescriptionsReducer,
  router: connectRouter(history),
})
