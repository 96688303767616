import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../../../common/Loader'
import { IPatientInfos } from '../../reducers'
import dateformat from 'dateformat'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateTreatment: (patientId: number, treatment: number[]) => Promise<unknown>
  patientInfo: IPatientInfos
  updatePatient: (patient: IPatientInfos) => Promise<unknown>
}

const ModalTreatment = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTreatments, setSelectedTreatments] = useState<Array<number>>(
    [],
  )
  const [selectedAC, setSelectedAC] = useState<number>(0)
  const [treatmentComment, setTreatmentComment] = useState('')

  useEffect(() => {
    setIsLoading(true)
    const treatTemp: number[] = []
    props.patientInfo?.treatmentList &&
      props.patientInfo?.treatmentList.forEach((treat) => {
        treat.treatmentTypeId === 12 ||
        treat.treatmentTypeId === 13 ||
        treat.treatmentTypeId === 6
          ? setSelectedAC(treat.treatmentTypeId)
          : treatTemp.push(treat.treatmentTypeId)
      })
    setSelectedTreatments(treatTemp)
    setTreatmentComment(
      props.patientInfo?.treatmentComment
        ? props.patientInfo?.treatmentComment
        : '',
    )
    setIsLoading(false)
  }, [props.patientInfo]) //eslint-disable-line

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      selectedAC !== 0
        ? await props.updateTreatment(props.patientInfo?.id, [
            ...selectedTreatments,
            selectedAC,
          ])
        : await props.updateTreatment(props.patientInfo?.id, [
            ...selectedTreatments,
          ])
      const historyList: any[] = []
      selectedAC !== 0
        ? [...selectedTreatments, selectedAC].forEach((h) =>
            historyList.push({
              historyTypeId: h,
              patientId: props.patientInfo?.id,
            }),
          )
        : [...selectedTreatments].forEach((h) =>
            historyList.push({
              historyTypeId: h,
              patientId: props.patientInfo?.id,
            }),
          )
      await props.updatePatient({
        ...props.patientInfo,
        historyList,
        treatmentComment,
        birthDay: dateformat(
          new Date(props.patientInfo?.birthDay),
          'yyyy-mm-dd',
        ),
        countryId: 1,
      })
      toast.success('Modifications effectuées')
      props.setIsOpen(false)
    } catch {
      toast.error("Erreur lors de l'ajout de l'antécédent")
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheck = (id: number) => {
    if (id === 1 && selectedTreatments.includes(1)) setSelectedAC(0)
    if (id === 7) {
      setSelectedAC(0)
      setSelectedTreatments([7])
    } else
      selectedTreatments.includes(id)
        ? setSelectedTreatments(
            [...selectedTreatments].filter((h) => h !== id && h !== 7),
          )
        : setSelectedTreatments([
            ...selectedTreatments.filter((h) => h !== 7),
            id,
          ])
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto', height: '100%' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement de l'antécédent" open={isLoading} />
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          color: 'white',
          fontWeight: '700',
          fontSize: '24px',
        }}
      >
        {'Ajouter un traitement'}
        <IconButton
          sx={{ position: 'absolute', right: '20px' }}
          onClick={() => props.setIsOpen(false)}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
          height: '40%',
          padding: '0 40px',
          margin: '20px 0',
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}
        >
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(1)}
            label="Anticoagulants"
            onChange={() => handleCheck(1)}
          />
          {selectedTreatments.includes(1) && (
            <RadioGroup
              sx={{ marginLeft: '30px' }}
              onChange={(e) => setSelectedAC(parseInt(e.target.value))}
              value={selectedAC}
            >
              <FormControlLabel
                value={12}
                control={
                  <Radio
                    sx={{
                      color: selectedAC === 12 ? '#84c2ce !important' : '',
                    }}
                  />
                }
                label="AOD"
              />
              <FormControlLabel
                value={6}
                control={
                  <Radio
                    sx={{ color: selectedAC === 6 ? '#84c2ce !important' : '' }}
                  />
                }
                label="AVK"
              />
              <FormControlLabel
                value={13}
                control={
                  <Radio
                    sx={{
                      color: selectedAC === 13 ? '#84c2ce !important' : '',
                    }}
                  />
                }
                label="Autre"
              />
            </RadioGroup>
          )}
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(2)}
            label="Anti-agrégants plaquettaires"
            onChange={() => handleCheck(2)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(3)}
            label="Bêta-bloquants"
            onChange={() => handleCheck(3)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(4)}
            label="Inhibiteurs calciques bradycardisants"
            onChange={() => handleCheck(4)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(8)}
            label="Digoxine"
            onChange={() => handleCheck(8)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(10)}
            label="Anti-arythmique classe Ic"
            onChange={() => handleCheck(10)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(9)}
            label="Amiodarone"
            onChange={() => handleCheck(9)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(11)}
            label="Sotalol"
            onChange={() => handleCheck(11)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(14)}
            label={
              <>
                Autre(s) anti-arythmique(s):{' '}
                <span style={{ fontStyle: 'italic' }}>
                  à préciser en commentaire
                </span>
              </>
            }
            onChange={() => handleCheck(14)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedTreatments.includes(7)}
            label={<span style={{ fontWeight: 'bold' }}>Aucun traitement</span>}
            onChange={() => handleCheck(7)}
          />

          <Box
            sx={{
              marginTop: '30px',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <span>Commentaires / autres traitements:</span>
            <textarea
              style={{
                marginTop: '10px',
                border: '1px solid #84c2ce',
                borderRadius: '8px',
                padding: '10px',
                fontFamily: 'Roboto',
                fontSize: '14px',
              }}
              value={treatmentComment}
              onChange={(e) => setTreatmentComment(e.target.value)}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '15px',
            }}
          >
            <IconButton onClick={handleSubmit} sx={{ alignSelf: 'right' }}>
              <SaveIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalTreatment
