import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  CREATE_PROSTHESIS_SUCCESS,
  GET_GAMME_RANGE_SUCCESS,
  GET_PROSTHESIS_REFERENCES_SUCCESS,
  GET_TRANSMITTER_REFERENCE_SUCCESS,
} from '../constants'

interface IState {
  prosthesisReferences: IProsthesisReference[]
}

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface ICreateProsthesis {
  model: string
  reference: string
  makerId: number
  gammeId: number
  rangeId: number
  facturation?: number
}

export interface IBillable {
  createdAt: string
  deletedAt: string | null
  amount: number
  applicableDate: string
  id: number
  modifiedAt: string | null
  prosthesisReferenceId: number
}

export interface IProsthesisReference {
  billableList: IBillable[]
  createdAt: string
  deletedAt: string | null
  gammeId: number
  id: number
  maker: {}
  modifiedAt: string | null
  prosthesisGamme: {}
  prosthesisMakerId: number
  prosthesisModel: string
  prosthesisRange: {}
  rangeId: number
  referenceName: string
}

export interface IUpdatePatientProsthesis {
  serialNumber: string
  referenceId: number
  id?: number
}

const initialState = {
  prosthesisReferences: [],
} as IState

const prosthesisReducer = produce((draft = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_GAMME_RANGE_SUCCESS:
      draft.gammeRange = action.payload.data
      break
    case GET_TRANSMITTER_REFERENCE_SUCCESS:
      draft.transmitterReference = action.payload.data
      break
    case GET_PROSTHESIS_REFERENCES_SUCCESS:
      draft.prosthesisReferences = action.payload.data
      break
    case CREATE_PROSTHESIS_SUCCESS:
      draft.prosthesisReferences = [
        action.payload.data,
        ...draft.prosthesisReferences,
      ]
      break
    default:
      return draft
  }
})

export default prosthesisReducer
