import { CORE_URL, REFACTOR_URL } from '../../../helpers/env'

export const TOGGLE_LOGIN = 'TOGGLE_LOGIN'
export const TOGGLE_LOGIN_PSC = 'TOGGLE_LOGIN_PSC'
export const TOGGLE_LOGIN_SUCCESS = 'TOGGLE_LOGIN_SUCCESS'
export const TOGGLE_LOGIN_FAILED = 'TOGGLE_LOGIN_FAILED'

export const TOGGLE_NEWPWD = 'TOGGLE_NEWPWD'
export const TOGGLE_NEWPWD_SUCCESS = 'TOGGLE_NEWPWD_SUCCESS'
export const TOGGLE_NEWPWD_FAILED = 'TOGGLE_NEWPWD_FAILED'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const TOGGLE_LOGOUT = 'TOGGLE_LOGOUT'
export const TOGGLE_LOGOUT_SUCCESS = 'TOGGLE_LOGOUT_SUCCESS'
export const TOGGLE_LOGOUT_FAILED = 'TOGGLE_LOGOUT_FAILED'

export const TOKEN_IS_VALID = 'TOKEN_IS_VALID'
export const TOKEN_IS_VALID_FAILED = 'TOKEN_IS_VALID_FAILED'

export const SET_IS_LOGGED = 'SET_IS_LOGGED'
export const SET_IS_LOGGED_TRUE = 'SET_IS_LOGGED_TRUE'
export const SET_IS_LOGGED_FALSE = 'SET_IS_LOGGED_FALSE'

export const TOGGLE_FORGOT_PWD = 'TOGGLE_FORGOT_PWD'
export const TOGGLE_FORGOT_PWD_SUCCESS = 'TOGGLE_FORGOT_PWD_SUCCESS'
export const TOGGLE_FORGOT_PWD_FAILED = 'TOGGLE_FORGOT_PWD_FAILED'

export const API_URL = CORE_URL
export const REFACTOR_API_URL = REFACTOR_URL
