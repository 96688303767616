import { Refresh, ViewColumn } from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import AddCommentIcon from '@mui/icons-material/AddComment'
import MessageIcon from '@mui/icons-material/Message'
import {
  Tooltip,
  IconButton,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
} from '@mui/material'
import NewMessageComponent from '../NewMessageComponent'
import { useState } from 'react'
import { IMessage } from '../../reducers'
import { ToolbarInput } from '../../../../utils/ToolbarInput'
import {
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from 'src/modules/doctors/reducers'

export type ToolbarProps = {
  title: string
  icon: string
  fetchMessages: () => void
  onAdd?: () => void
  onDelete?: () => void
  getDoctors: (
    size: number,
    page: number,
    doctorType: string,
    search: string,
  ) => Promise<unknown>
  getCenters: (
    size: number,
    page: number,
    searchText: string,
    isImplantCenter: boolean,
  ) => Promise<unknown>
  sendMessage: (message: IMessage) => Promise<unknown>
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  getDoctorsSummary: () => Promise<unknown>
  getCentersSummary: () => Promise<unknown>
  getDoctorAutocomplete: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getCountries: () => Promise<unknown>
  addDoctor: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
}

export function Toolbar({
  title,
  fetchMessages,
  getDoctors,
  getCenters,
  sendMessage,
  searchText,
  setSearchText,
  getDoctorsSummary,
  getCentersSummary,
  addDoctor,
  getCountries,
  getDoctorAutocomplete,
}: ToolbarProps) {
  const apiRef = useGridApiContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openNewMessageDialog, setOpenNewMessageDialog] = useState(false)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openModal = () => {
    setOpenNewMessageDialog(true)
  }

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field === event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }

  return (
    <GridToolbarContainer
      sx={{
        background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
        borderRadius: '0px 15px 0px 0px',
      }}
    >
      {openNewMessageDialog && (
        <NewMessageComponent
          isOpen={openNewMessageDialog}
          setIsOpen={setOpenNewMessageDialog}
          getDoctors={getDoctors}
          getCenters={getCenters}
          sendMessage={sendMessage}
          fetchMessages={fetchMessages}
          getDoctorsSummary={getDoctorsSummary}
          getCentersSummary={getCentersSummary}
          addDoctor={addDoctor}
          getDoctorAutocomplete={getDoctorAutocomplete}
          getCountries={getCountries}
        />
      )}

      <Box
        ml={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ pr: 10 }}
      >
        <MessageIcon
          sx={{
            marginRight: '1rem',
            padding: '0px 10px 0px 0px',
            color: 'white',
            width: '10% ',
            height: '10%',
          }}
        />

        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 'bold', color: 'white' }}
        >
          {title}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport /> */}
      {/* <GridToolbarFilterButton /> */}
      <Tooltip title={'Nouveau message'} style={{ marginRight: 5 }}>
        <IconButton onClick={openModal}>
          <AddCommentIcon />
        </IconButton>
      </Tooltip>
      <ToolbarInput searchText={searchText} setSearchText={setSearchText} />
      <Tooltip title={'Colonnes'}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((column, key) => (
          <MenuItem key={key} onClick={handleHide} id={column.field}>
            <FormControlLabel
              control={<Checkbox checked={!column.hide} />}
              label={column.headerName as string}
            />
          </MenuItem>
        ))}
      </Menu>

      <Tooltip title={'Rafraîchir'}>
        <IconButton onClick={fetchMessages}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}
