import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from 'react';
import DoctorsGrid from './searchReceiver/DoctorsGrid';
import CentersGrid from './searchReceiver/CentersGrid';
import PromantisGrid from './searchReceiver/PromantisGrid';
import {IFoundDoctor, INewDoctorAddress, INewDoctorInfos, INewDoctorStep1} from 'src/modules/doctors/reducers';
// import MakersGrid from './searchReceiver/MakersGrid';

interface IProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleReceiverSelection: (mail: string) => void;
    getDoctors: (size: number, page: number, doctorType: string, search: string) => Promise<unknown>;
    getCenters: (size: number, page: number, searchText: string, isImplantCenter: boolean) => Promise<unknown>;
    isReceiver: boolean;
    getDoctorsSummary: () => Promise<unknown>;
    getCentersSummary: () => Promise<unknown>;
    getDoctorAutocomplete: (name: string, page: number, limit: number) => Promise<IFoundDoctor[]>;
    getCountries: () => Promise<unknown>;
    addDoctor: {
        step1: (doctor: INewDoctorStep1) => Promise<number>;
        step2: (id: number) => Promise<unknown>;
        step3: (id: number, address: INewDoctorAddress) => Promise<unknown>;
        step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>;
    };
}

type Category = 'makers' | 'centers' | 'promantis' | 'doctors'

const SearchReceiverModal = (props: IProps) => {

    const [selectedCategory, setSelectedCategory] = useState<Category>('doctors')

    useEffect(() => {
        props.getDoctorsSummary()
        props.getCentersSummary()
    }, []) //eslint-disable-line

    const handleClose = () => {
        props.setIsOpen(false);
    }

    return <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{padding: '30px auto'}}
        PaperProps={{
            style: {borderRadius: 20}
        }}
    >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <DialogTitle id="responsive-dialog-title" sx={{display: 'flex', position: 'relative', justifyContent: 'center', background: 'linear-gradient(60deg, #84c2ce, #96dcea)', color: 'white', fontWeight: '700', fontSize: '24px'}}>
                {"Choisir un destinataire"}
                <IconButton sx={{position: 'absolute', right: '20px'}} onClick={handleClose}>
                    <CloseIcon sx={{color: 'white'}} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{padding: '20px 30px', height: 'calc(100vh - 134px)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box className='button-group-box' sx={{paddingTop: '15px'}}>
                    {/* <Button className={selectedCategory === 'makers' ? 'group-button-selected' : 'group-button'} onClick={() => setSelectedCategory('makers')}>Fabricants</Button> */}
                    <Button className={selectedCategory === 'centers' ? 'group-button-selected' : 'group-button'} onClick={() => setSelectedCategory('centers')}>Centres</Button>
                    <Button className={selectedCategory === 'promantis' ? 'group-button-selected' : 'group-button'} onClick={() => setSelectedCategory('promantis')}>Promantis</Button>
                    <Button className={selectedCategory === 'doctors' ? 'group-button-selected' : 'group-button'} onClick={() => setSelectedCategory('doctors')}>Docteurs</Button>
                </Box>
                {/* {selectedCategory === 'makers' && <MakersGrid getDoctors={props.getDoctors} handleReceiverSelection={props.handleReceiverSelection} />} */}
                {selectedCategory === 'centers' &&
                    <CentersGrid
                        getCenters={props.getCenters}
                        handleReceiverSelection={props.handleReceiverSelection}
                    />
                }
                {selectedCategory === 'promantis' &&
                    <PromantisGrid
                        getDoctors={props.getDoctors}
                        handleReceiverSelection={props.handleReceiverSelection}
                    />
                }
                {selectedCategory === 'doctors' &&
                    <DoctorsGrid
                        getDoctors={props.getDoctors}
                        handleReceiverSelection={props.handleReceiverSelection}
                        addDoctor={props.addDoctor}
                        getDoctorAutocomplete={props.getDoctorAutocomplete}
                        getCountries={props.getCountries}
                    />
                }
            </DialogContent>
        </div>
    </Dialog>
}

export default SearchReceiverModal