import {KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight} from '@mui/icons-material';
import {NativeSelect} from '@mui/material';

interface IProps {
    pages: number,
    activePage: number,
    setActivePage: React.Dispatch<React.SetStateAction<number>>
    rowsPerPage: number
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
}

const PaginationComponent = (props: IProps) => {

    const items: any[] = []

    const getMin = () => {
        if (props.pages < 3) return 1
        if (props.activePage === props.pages) return props.activePage - 2
        if (props.activePage === 1) return props.activePage
        else return props.activePage - 1
    }

    const getMax = () => {
        if (props.pages < 3) return props.pages
        if (props.activePage === 1) return props.activePage + 2
        if (props.activePage === props.pages) return props.activePage
        else return props.activePage + 1
    }

    const getItems = () => {
        items.push(
            <button key={'first'} disabled={props.activePage === 1} className='pagination-button align-items-center' onClick={() => props.setActivePage(1)}>
                <KeyboardDoubleArrowLeft />
            </button>
        )

        for (let number = getMin(); number <= getMax(); number++) {
            items.push(
                <button key={number} className={'align-items-center ' + (props.activePage === number ? 'pagination-button-active' : 'pagination-button')} onClick={() => props.setActivePage(number)}>
                    {number}
                </button>,
            );
        }

        items.push(
            <button key={'last'} disabled={props.activePage === props.pages} className='pagination-button align-items-center' onClick={() => props.setActivePage(props.pages)}>
                <KeyboardDoubleArrowRight />
            </button>
        )

        return items
    }

    return <>
        <div style={{position: 'relative', marginTop: '20px'}}>
            <div style={{display: 'flex', alignItems: 'baseline', marginLeft: '30px'}}>
                <p style={{marginRight: '10px'}}>Nombre de lignes:</p>
                <NativeSelect
                    className='test-pagination'
                    value={props.rowsPerPage}
                    onChange={(event) => {props.setActivePage(1); props.setRowsPerPage(parseInt(event.target.value))}}
                    sx={{width: '60px'}}
                >
                    {[5, 10, 20, 50, 100].map((rows) => <option key={'rows' + rows} value={rows}>{rows}</option>)}
                </NativeSelect>
            </div>
            <div className='pagination-container' style={{position: 'absolute', top: 'calc(50% - 15px)', left: 'calc(50% - 105px)'}}>
                {getItems()}
            </div>
            <div></div>
        </div>
    </>
}
export default PaginationComponent;