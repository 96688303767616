import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MessagingComponent from '../components/MessagingComponent'
import { getMessages, getUserRoles, sendMessage } from '../actions'
import {
  addDoctorStep1,
  addDoctorStep2,
  addDoctorStep3,
  addDoctorStep4,
  getDoctorAutocomplete,
  getDoctors,
  getDoctorsSummary,
} from '../../../modules/doctors/actions'
import { IMessage } from '../reducers'
import { getCompany, getSummary } from '../../../modules/center/actions'
import {
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from 'src/modules/doctors/reducers'
import { getCountry } from '../../patients/actions'
import { SET_MESSAGE_DETAILS } from '../constants'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    userSettings: state.alertsReducer.userSettings,
    userRoles: state.messagingReducer.userRoles,
    messages: state.messagingReducer.message,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMessages: (size: number, page: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getMessages(size, page, resolve, reject))
      })
    },
    getUserRoles: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getUserRoles(resolve, reject))
      })
    },
    getDoctors: (
      size: number,
      page: number,
      doctorType: string,
      search: string,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctors(size, page, doctorType, search, resolve, reject))
      })
    },
    sendMessage: (message: IMessage) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(sendMessage(message, resolve, reject))
      })
    },
    getDoctorsSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctorsSummary(resolve, reject))
      })
    },
    getCompany: (
      size: number,
      page: number,
      searchText: string,
      isImplantCenter: boolean,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getCompany(
            size,
            page,
            searchText,
            isImplantCenter,
            undefined,
            resolve,
            reject,
          ),
        )
      })
    },
    getCompaniesSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getSummary(resolve, reject))
      })
    },
    getDoctorAutocomplete: (name: string, page: number, limit: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctorAutocomplete(name, page, limit, resolve, reject))
      })
    },
    getCountries: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCountry(resolve, reject))
      })
    },
    addDoctor: {
      step1: (doctor: INewDoctorStep1) => {
        return new Promise((resolve: (id: number) => void, reject: any) => {
          dispatch(addDoctorStep1(doctor, resolve, reject))
        })
      },
      step2: (id: number) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep2(id, resolve, reject))
        })
      },
      step3: (id: number, address: INewDoctorAddress) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep3(id, address, resolve, reject))
        })
      },
      step4: (id: number, doctorInfos: INewDoctorInfos) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep4(id, doctorInfos, resolve, reject))
        })
      },
    },

    setMessageDetails: (messageId: number) => {
      dispatch({ type: SET_MESSAGE_DETAILS, payload: messageId })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxMessaging = ConnectedProps<typeof connector>

export default withRouter(connector(MessagingComponent))
