import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import { GET_DOCTORS_FAILED, GET_DOCTORS_SUCCESS, GET_DOCTORS_SUMMARY_SUCCESS } from '../constants'
import { ICountry, IDoctor } from 'src/modules/patients/reducers'

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface IFoundDoctor {
  id: number,
  rppsNumber: string,
  title: string,
  firstName: string,
  lastName: string,
  professionCode: string,
  professionLabel: string,
  categoryCode: string,
  categoryLabel: string,
  skillCode: string,
  skillLabel: string,
  zipCode: string,
  createAt: string,
  updateAt: string,
  deletedAt: null
}

export interface INewDoctor {
  cardioImplant: boolean,
  cardioAttent: boolean,
  doctorAttent: boolean,
  userName: string,
  firstName: string,
  street: string,
  zipCode: string,
  city: string,
  country: ICountry,
  rppsNumber: string,
  phone: string,
  mail: string,
  billing: boolean
}

export interface INewDoctorStep1 {
  mail: string,
  userName: string,
  firstName: string,
  title: string,
  phone: string
}

export interface INewDoctorAddress {
  street: string,
  zipCode: string,
  city: string,
  countryId: number
}

export interface INewDoctorInfos {
  cardioImplant: boolean,
  cardioAttent: boolean,
  doctorAttent: boolean,
  rppsNumber: string,
  billing: boolean
}

export interface IDoctorSummary {
  implantCount: number,
  cardiologCount: number,
  doctorCount: number,
  billingCount: number
}

interface IState {
  doctors: {
    data: IDoctor[],
    totalPages: number
  },
  doctorsAutocomplete: IFoundDoctor[],
  summary: IDoctorSummary
}

export type DoctorType = 'Implant' | 'Cardiolog' | 'FamilyDoctor' | 'Billing'

const initialState = {
  doctors: {
    data: [],
    totalPages: 0
  },
  doctorsAutocomplete: [],
  summary: {
    billingCount: 0,
    cardiologCount: 0,
    implantCount: 0,
    doctorCount: 0
  }
} as IState

const doctorsReducer = produce((draft = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_DOCTORS_SUCCESS:
      draft.doctors.data = action.payload.data.data
      draft.doctors.totalPages = action.payload.data.totalPages
      break
    case GET_DOCTORS_FAILED:
      draft.doctors.data = []
      draft.doctors.totalPages = 0
      break
    case GET_DOCTORS_SUMMARY_SUCCESS:
      draft.summary = action.payload.data
      break
    default:
      return draft
  }
})

export default doctorsReducer
