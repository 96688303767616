function Icon ({width, height}) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      version="1"
      viewBox="-0.741 -0.741 196.05 26.184"
    >
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M35.51 22.185V2.44h10.492c1.038 0 2.737.112 4.025 1.021 1.287.909 2.251 2.496 2.251 4.171 0 2.185-1.227 3.858-3.145 4.285v.057c2.155.367 3.887 1.985 3.887 4.48 0 4.882-3.546 5.732-7.25 5.732H35.51zm6.08-12.171h2.982c.997 0 1.751-.396 1.751-1.673 0-1.503-1.068-1.675-2.145-1.675h-2.589v3.348zm0 7.774h3.087c1.023 0 2.04-.424 2.04-1.758 0-1.534-1.2-1.817-2.303-1.817H41.59v3.575z"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M55.648 22.186h6.225V2.439h-6.225v19.747z"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M83.902 12.34c0 5.76-3.52 10.333-9.641 10.333-6.095 0-9.614-4.573-9.614-10.332 0-6.018 3.493-10.418 9.614-10.418 6.147 0 9.641 4.4 9.641 10.418m-12.662.029c0 1.968.21 5.702 3.02 5.702 2.838 0 3.022-3.734 3.022-5.702s-.29-5.903-3.021-5.903c-2.733 0-3.021 3.935-3.021 5.903"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M95.436 7.205v14.98H89.21V7.205H84.3V2.44h15.943v4.766h-4.806z"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M120.748 22.185h-7.171l-3.392-7.362h-1.45v7.362h-6.227V2.44h9.639c1.76 0 3.678.256 5.176 1.219 1.655 1.05 2.469 2.782 2.469 4.712 0 2.58-1.098 4.325-3.46 5.403l4.416 8.412zm-12.014-11.59h1.679c1.341 0 2.969-.384 2.969-2.084 0-.653-.368-1.306-.973-1.617-.524-.256-1.181-.256-1.759-.256h-1.916v3.957z"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M140.94 12.34c0 5.76-3.52 10.333-9.642 10.333-6.095 0-9.614-4.573-9.614-10.332 0-6.018 3.494-10.418 9.614-10.418 6.148 0 9.642 4.4 9.642 10.418m-12.662.029c0 1.968.21 5.702 3.02 5.702 2.838 0 3.023-3.734 3.023-5.702s-.29-5.903-3.023-5.903c-2.731 0-3.02 3.935-3.02 5.903"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M165.752 22.186h6.225V2.439h-6.225v19.747z"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M188.264 9.674l5.5-7.235h-6.773l-4.756 6.806V2.439H176.008v19.746H182.234V17.01l1.681-2.287 3.441 7.46h7.21l-6.303-12.51z"
      ></path>
      <path
        fill="#002f5c"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M161.711 22.185H155.2l-5.53-10.992c-.106-.21-.478-1.154-.478-1.154s.15 1.345.15 1.967v10.18h-5.632V2.438h7.24l4.714 9.516c.115.236.587 1.35.587 1.35s-.173-1.424-.173-2.024l.005-8.842h5.628v19.746z"
      ></path>
      <path
        fill="#688ca3"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M19.614 22.49c-1.955 1.404-4.053 2.054-6.44 2.212v-7.53H23.69c-.886 2.216-2.131 3.922-4.077 5.318m-14.542 0C3.103 21.1 1.834 19.397.925 17.173H11.56v7.529c-2.405-.16-4.51-.813-6.488-2.212"
      ></path>
      <path
        fill="#688ca3"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        d="M16.957 7.683c.342-.637.956-.978 1.793-1.04.871.022 1.525.345 1.916.99.377.62.544 1.32.508 2.094-.083 1.4-.811 2.945-2.427 2.955-.708-.072-1.27-.386-1.647-.997-.353-.574-.543-1.228-.57-1.96-.039-.752.1-1.434.427-2.042m-1.28 5.18c.792.845 1.806 1.306 3.048 1.38 2.587.002 4.275-2.056 4.409-4.523-.003-1.269-.407-2.33-1.21-3.195-.802-.861-1.826-1.324-3.084-1.388-1.273.013-2.308.457-3.117 1.336-.817.88-1.237 1.958-1.264 3.247.014 1.251.421 2.295 1.217 3.143M4.98 8.98V6.813h2.054c.62.008 1.146.403 1.166 1.056a1.077 1.077 0 01-.372.795c-.23.204-.51.31-.823.317H4.98zm0 3.843v-2.538h1.996c.734.01 1.339.459 1.424 1.214.061.898-.644 1.313-1.453 1.324H4.98zM3.107 5.45v8.678h4.21c1.774.015 3.07-.886 3.07-2.471 0-1.292-1.027-1.994-1.713-2.17.469-.175 1.342-.803 1.342-1.9 0-1.44-1.066-2.14-2.456-2.137H3.108zM.54 15.672l-.097-.098A11.55 11.55 0 010 12.366c.039-4.241 1.978-8.08 5.546-10.425C7.392.727 9.338.176 11.53.028v14.044h1.67L13.174 0c2.197.138 4.157.679 6.007 1.895 3.589 2.354 5.486 6.224 5.523 10.471-.004 1.152-.18 2.2-.49 3.306H.539z"
      ></path>
    </svg>
  );
}

export default Icon;
