import React, { useEffect } from 'react'
import { PropsFromReduxMessaging } from '../containers/MessageDetailsContainer'
import { useLocation } from 'react-router-dom'
import { Container, Divider, Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'

const MessageDetailsComponent = (props: PropsFromReduxMessaging) => {
  const location = useLocation()

  useEffect(() => {
    const id = parseInt(location.pathname.split('/').pop() || '0')
    props.setMessageDetails(id)
  }, [location, props])

  return (
    <Container>
      <Paper
        style={{
          padding: '20px',
        }}
      >
        <Typography
          variant="h3"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {props.messageDetails?.title}
        </Typography>
        <Typography
          variant="caption"
          style={{
            fontSize: '0.8rem',
          }}
        >
          Envoyée le{' '}
          {moment(props.messageDetails?.createdAt).format('DD-MM-YYYY à HH:mm')}
        </Typography>
        <Grid
          container
          style={{
            margin: '10px 0',
          }}
        >
          <Grid item xs={6}>
            <h4>Expéditeur</h4>

            <Typography variant="body1">
              {props.messageDetails?.senderEmail}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <h4>
              {props.messageDetails?.sendTo.length > 1
                ? 'Destinataires'
                : 'Destinataire'}
            </h4>
            {props.messageDetails?.sendTo.map((m: any) => (
              <Typography variant="body1">{m.email}</Typography>
            ))}
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 40 }} />

        <Typography
          variant="body1"
          style={{ marginTop: '10px', fontStyle: 'italic' }}
        >
          {props.messageDetails?.messageContent}
        </Typography>
      </Paper>
    </Container>
  )
}

export default MessageDetailsComponent
