import { Dialog, DialogContent, Box, Button } from '@mui/material';
import { useState } from 'react';
import { IAlertPatient } from '../../reducers';
import Loader from '../../../common/Loader';
import { toast } from 'react-toastify';

interface IProps {
    alert: IAlertPatient,
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    patientExportAction: (exportAction: 'VIEW' | 'COMMENT' | 'CHECK', patientExportId: number, exportActionComment?: string) => Promise<unknown>
}

const TreatmentModal = (props: IProps) => {

    const [loading, setLoading] = useState(false);

    const checkAlert = async () => {
        setLoading(true)
        try {
            await props.patientExportAction('CHECK', props.alert.id)
            toast.success('Alerte traitée')
            props.setIsOpen(false)
        } catch {
            toast.error('Impossible de traiter l\'alerte')
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        props.setIsOpen(false);
    }

    return <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
            style: { borderRadius: 20 }
        }}
    >

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <DialogContent sx={{ padding: '30px', height: '100%' }}>
                <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Loader open={loading} text="Traitement de l'alerte" />

                    <p style={{ margin: 0 }}>Vous confirmez que l'alerte est traitée ? </p>

                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: '30px', height: '30px' }}>
                        <Button className='group-button' onClick={() => props.setIsOpen(false)}>
                            Retour
                        </Button>
                        <Button className='group-button-success' onClick={checkAlert}>
                            Confirmer
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </div >
    </Dialog >
}

export default TreatmentModal