import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

import { RiContactsBook2Fill } from 'react-icons/ri'
import AddNewCenterContactModal from '../Modals/AddCenterContactModal'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteUser, findContacts } from '../../thunks'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { IUser } from 'src/modules/messaging/reducers'
import { AddressDto } from 'src/helpers/dto'

interface CenterContactsProps {
  centerId: number
}

const CenterContacts: React.FC<CenterContactsProps> = ({ centerId }) => {
  const dispatch = useAppDispatch()
  const [openCenterContactDialog, setOpenCenterContactDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editingUser, setEditingUser] = useState<IUser | null>(null)
  const [deletingUser, setdeletingUser] = useState<IUser | null>(null)

  const contacts =
    useAppSelector((state) => state.centersReducer.contacts) || []

  const fetchContacts = async () => {
    setIsLoading(true)
    dispatch(findContacts(centerId) as any)
      .unwrap()
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId])

  const handleEditContact = (user: IUser) => {
    setOpenCenterContactDialog(true)
    setEditingUser(user)
  }

  const handleDeleteContact = (user: IUser) => {
    setdeletingUser(user)
  }

  const confirmDelete = () => {
    if (deletingUser) {
      dispatch(deleteUser(deletingUser.id) as any)
        .unwrap()
        .finally(() => {
          setdeletingUser(null)
        })
    }
  }

  useEffect(() => {
    if (!openCenterContactDialog && editingUser) {
      setEditingUser(null)
    }
  }, [openCenterContactDialog, editingUser])

  return (
    <>
      <Toolbar
        sx={{
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          borderRadius: '15px 15px 0px 0px',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          Contacts
        </Typography>
        <Tooltip title="Ajouter un contact">
          <IconButton onClick={() => setOpenCenterContactDialog(true)}>
            <RiContactsBook2Fill />+
          </IconButton>
        </Tooltip>
      </Toolbar>
      {openCenterContactDialog && (
        <AddNewCenterContactModal
          open={openCenterContactDialog}
          setOpen={setOpenCenterContactDialog}
          centerId={centerId}
          editingUser={editingUser || undefined}
        />
      )}
      <TableContainer
        component={Paper}
        style={{
          borderRadius: '0px 0px 15px 15px',
          maxHeight: 'calc(100vh - 340px)', // Adjust 100px based on your toolbar/header height
          overflow: 'auto',
        }}
      >
        {isLoading && <LinearProgress />}
        <Table aria-label="contacts table">
          <TableHead>
            <TableRow
              style={{
                backgroundColor: '#84c2ce40',
                textAlign: 'center',
                textTransform: 'uppercase',
              }}
            >
              <TableCell>Fonction</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Code Postal</TableCell>
              <TableCell>Ville</TableCell>
              <TableCell>Pays</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {contacts.map((contact, index) => {
                const address: AddressDto = contact.addresses.length
                  ? (contact.addresses[0] as any as AddressDto)
                  : ({} as AddressDto)

                return (
                  <TableRow key={index}>
                    <TableCell>{contact.title}</TableCell>
                    <TableCell>{contact.firstName}</TableCell>
                    <TableCell>{contact.userName}</TableCell>
                    <TableCell>{contact.mail}</TableCell>
                    <TableCell>{contact.phone}</TableCell>
                    <TableCell>{address.city}</TableCell>
                    <TableCell>{address.zipCode}</TableCell>
                    <TableCell>{address.city}</TableCell>
                    <TableCell>
                      {address.country?.countryName?.toString()}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditContact(contact)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteContact(contact)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog
        open={!!deletingUser}
        onClose={() => setdeletingUser(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer ce contact ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdeletingUser(null)}>Fermer</Button>
          <Button onClick={confirmDelete} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CenterContacts
