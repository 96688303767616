import { CORE_URL } from '../../../helpers/env'

export const GET_USER_ROLES = 'GET_USER_ROLES'
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS'
export const GET_USER_ROLES_FAILED = 'GET_USER_ROLES_FAILED'

export const GET_MESSAGES = 'GET_MESSAGES'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED'

export const SET_MESSAGE_DETAILS = 'SET_MESSAGE_DETAILS'
export const SET_MESSAGE_DETAILS_SUCCESS = 'SET_MESSAGE_DETAILS_SUCCESS'
export const SET_MESSAGE_DETAILS_FAILED = 'SET_MESSAGE_DETAILS_FAILED'

export const API_URL = CORE_URL
