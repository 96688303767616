export const CORE_URL = 'https://my-promantis.fr/rest'
export const REFACTOR_URL = 'https://my-promantis.fr/rest-nest'
export const PRESCRIPTION_URL = 'https://my-promantis.fr/ordo'
export const STELLAIR_FSE = 'https://fse.stellair.fr'
export const LICENCE_KEY =
  '77ad817c2c5c22742f27d01dee8ef10aTz02NzUyNixFPTE3MTcwNTUwODQyMjcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
export const CLIENT_ID = '7ttbrneasa3v6hlv90qnnlhkh9'
export const STELLAIR_AUTH_URL = 'https://auth.stellair.fr/oauth2/authorize'
export const VID_SIGNER_URL = 'https://centralized.vidsigner.net/'
export const REACT_APP_OIDC_AUTHORIZATION_URI =
  'https://wallet.esw.esante.gouv.fr/auth'
export const REACT_APP_OIDC_PROVIDER_URI = 'https://auth.esw.esante.gouv.fr'
export const REACT_APP_OIDC_CLIENT_ID = 'mypromantis'
export const REACT_APP_OIDC_REDIRECT_URI = 'https://my-promantis.fr/login'
