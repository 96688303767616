import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Loader from '../../../../modules/common/Loader'
import { CenterDTO, CreateCompanyDto } from '../../../../helpers/dto'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { createCompany, updateCompany } from '../../actions'

const centerSchema = yup.object().shape({
  type: yup.string().required('Profil est obligatoire'),
  name: yup.string().required('Nom est obligatoire'),
  city: yup.string().required('Ville est obligatoire'),
  phone: yup.string().optional(),
  FINESS: yup
    .string()
    .required('FINESS est obligatoire')
    .matches(/^\d{9}$/, 'FINESS doit contenir 9 chiffres'),
  isImplantationCenter: yup.boolean(),
  isSatelliteCenter: yup.boolean(),
  zipCode: yup.string().required('Code postal est obligatoire'),
  parentCenterId: yup.string().optional(),
})

interface IProps {
  open: boolean
  setOpen: (isOpen: boolean) => void
  editingCenter?: CenterDTO
}

export default function CenterFormDialog(props: IProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(centerSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: props.editingCenter && {
      type:
        props.editingCenter.centerDetails.center_type ||
        props.editingCenter.centerDetails.centerType,
      name: props.editingCenter.companyName,
      city: props.editingCenter.city,
      email: props.editingCenter.centerDetails.email_contact || '',
      phone: props.editingCenter.phone,
      FINESS: props.editingCenter.centerDetails.finess || '',
      isImplantationCenter:
        (props.editingCenter.centerDetails.is_implant_center as any) ||
        (props.editingCenter.centerDetails.implantCenter as any),

      zipCode: props.editingCenter.zipCode,
      parentCenterId:
        props.editingCenter.centerDetails.parent_center_id.toString(),
    },
  })
  const dispatch = useAppDispatch()

  const onSubmit = async (values: any) => {
    const dto: CreateCompanyDto = {
      type: 'CENTER',
      companyName: values.name,
      city: values.city,
      phone: values.phone,
      address: '',
      countryId: 1,
      zipCode: values.zipCode,
      centerDetails: {
        finess: values.FINESS,
        implantCenter: values.isImplantationCenter,
        centerType: values.type,
        parentCenterId: parseInt(values.parentCenterId),
        subCenter:
          values.isSatelliteCenter !== undefined
            ? values.isSatelliteCenter
            : null,
      },
    }

    await new Promise((resolve, reject) => {
      if (props.editingCenter) {
        dispatch(updateCompany(props.editingCenter.id, dto, resolve, reject))
      } else {
        dispatch(createCompany(dto, resolve, reject))
      }
    }).then(() => {
      props.setOpen(false)
    })
  }

  const hasParentCenter = watch('isSatelliteCenter')
  const centers = useAppSelector((state) => state.centersReducer.centers.data)

  useEffect(() => {
    if (!props.open) {
      reset()
    }
  }, [props.open, reset])

  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = event.target.value
      .replace(/\D/g, '')
      .replace(/(\d{2})/g, '$1 ')
      .trim()
    setValue('phone', formattedPhoneNumber as any, { shouldValidate: true })
  }

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: '50%',
        },
      }}
    >
      <Loader text="Enregistrement du centre" open={false} />
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          color: 'white',
          fontWeight: '700',
          fontSize: '20px',
        }}
      >
        {props.editingCenter ? 'Modifier un centre' : 'Ajouter un centre'}
        <IconButton
          sx={{ position: 'absolute', right: '20px' }}
          onClick={() => props.setOpen(false)}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            sx={{
              width: '100%',
              padding: '20px',
            }}
          >
            <FormLabel>Profil *</FormLabel>
            <Select
              {...register('type')}
              error={Boolean(errors.type)}
              fullWidth
              margin="dense"
              variant="outlined"
              defaultValue={
                props.editingCenter?.centerDetails.center_type ||
                props.editingCenter?.centerDetails.centerType
              }
            >
              <MenuItem value="CLINIC">Clinique</MenuItem>
              <MenuItem value="HOSPITAL">Hôpital</MenuItem>
              <MenuItem value="CABINET">Cabinet</MenuItem>
            </Select>
            <p>{errors.type?.message}</p>

            <TextField
              {...register('name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              fullWidth
              margin="dense"
              label="Nom"
              variant="outlined"
            />

            <TextField
              {...register('city')}
              error={Boolean(errors.city)}
              helperText={errors.city?.message}
              fullWidth
              margin="dense"
              label="Ville"
              variant="outlined"
            />

            <TextField
              {...register('phone')}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              fullWidth
              margin="dense"
              label="Téléphone"
              variant="outlined"
              onChange={handlePhoneInput}
            />

            <TextField
              {...register('FINESS')}
              error={Boolean(errors.FINESS)}
              helperText={errors.FINESS?.message}
              fullWidth
              margin="dense"
              label="FINESS"
              variant="outlined"
              // only allow numbers
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.currentTarget.value = e.currentTarget.value?.replace(
                  /\D/g,
                  '',
                )
              }}
            />

            <TextField
              {...register('zipCode')}
              error={Boolean(errors.zipCode)}
              helperText={errors.zipCode?.message}
              fullWidth
              margin="dense"
              label="Code postal"
              variant="outlined"
            />

            {/* Checkbox for isImplantationCenter */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '50%',
                margin: '8px 0',
              }}
            >
              <FormLabel sx={{ width: '100%' }}>
                Centre d'implantation
              </FormLabel>
              <input
                type="checkbox"
                {...register('isImplantationCenter')}
                defaultChecked={false}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '50%',
                margin: '8px 0',
              }}
            >
              <FormLabel sx={{ width: '100%' }}>Centre Satellite</FormLabel>
              <input
                type="checkbox"
                {...register('isSatelliteCenter')}
                defaultChecked={false}
              />
            </Box>
            {hasParentCenter && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '50%',
                    margin: '8px 0',
                  }}
                >
                  <FormLabel sx={{ width: '100%' }}>
                    Centre de rattachement
                  </FormLabel>
                </Box>
                <Autocomplete
                  options={centers.map((c) => ({
                    value: c.id,
                    name: c.companyName,
                  }))}
                  {...register('parentCenterId')}
                  onChange={(_, value) => {
                    setValue(
                      'parentCenterId',
                      (value?.value?.toString() as any) || '',
                    )
                  }}
                  getOptionLabel={({ name }) => name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Center"
                      margin="dense"
                      variant="outlined"
                    />
                  )}
                />
              </>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <IconButton type="submit">
                <SaveIcon
                  sx={{
                    fontSize: '36px',
                    color: isValid && isDirty ? '#40D172' : 'lightgrey',
                  }}
                />
              </IconButton>
            </Box>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  )
}
