import { IDoctor } from '../../../modules/patients/reducers'
import {
  GET_PROSTHESIS_REFERENCES,
  GET_GAMME_RANGE,
  GET_TRANSMITTER_REFERENCE,
  UPDATE_PATIENT_PROSTHESIS,
  DELETE_PATIENT_PROSTHESIS,
  CREATE_PROSTHESIS,
} from '../constants'
import { ICreateProsthesis, IUpdatePatientProsthesis } from '../reducers'

export function getProsthesisReferences(
  searchText: string,
  selectedGammes: string[],
  selectedMakerIds: number[],
  resolve: any,
  reject: any,
) {
  return {
    type: GET_PROSTHESIS_REFERENCES,
    searchText,
    selectedGammes,
    selectedMakerIds,
    reject,
    resolve,
  }
}

export function getTransmitterReference(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return {
    type: GET_TRANSMITTER_REFERENCE,
    size,
    page,
    resolve,
    reject,
  }
}

export function getGammeRange(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return {
    type: GET_GAMME_RANGE,
    size,
    page,
    resolve,
    reject,
  }
}

export function updatePatientProsthesis(
  infos: IUpdatePatientProsthesis,
  method: string,
  implantationId: number,
  patientId: number,
  cardioAttent: IDoctor,
  cardioBilling: IDoctor,
  cardioImplant: IDoctor,
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_PATIENT_PROSTHESIS,
    infos,
    method,
    implantationId,
    patientId,
    cardioAttent,
    cardioBilling,
    cardioImplant,
    reject,
    resolve,
  }
}

export function createProsthesis(
  data: ICreateProsthesis,
  resolve: any,
  reject: any,
) {
  return {
    type: CREATE_PROSTHESIS,
    data,
    reject,
    resolve,
  }
}

export function deletePatientProsthesis(id: number, resolve: any, reject: any) {
  return { type: DELETE_PATIENT_PROSTHESIS, id, reject, resolve }
}
