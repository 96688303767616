import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCompany, getSummary } from '../actions'
import CenterComponent from '../components/CenterComponent'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    centers: state.centersReducer.centers,
    centersSummary: state.centersReducer.centersSummary,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCompany: (
      size: number,
      page: number,
      searchText: string,
      isImplantCenter: boolean,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          getCompany(
            size,
            page,
            searchText,
            isImplantCenter,
            undefined,
            resolve,
            reject,
          ),
        )
      })
    },
    getCompaniesSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getSummary(resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxCenter = ConnectedProps<typeof connector>

export default withRouter(connector(CenterComponent))
