import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { logOut } from '../../auth/actions'
import Sidebard from '../Sidebar'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logOut: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(logOut(resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxSidebar = ConnectedProps<typeof connector>

export default withRouter(connector(Sidebard))
