import alertContainer from '../containers/AlertsContainer'
import ReportPDFContainer from '../containers/ReportPDFContainer'
import * as alertsRoutes from './alert'


export const AlertsRoutes = [
    {
        key: "alerts",
        exact: true,
        path: alertsRoutes.ALERTS,
        component: alertContainer,
    },
    {
        key: "pdfviewer",
        exact: true,
        path: alertsRoutes.PDF_VIEWER,
        component: ReportPDFContainer,
    },
]