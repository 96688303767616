import React, { ReactElement, ReactNode, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { styled } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export const CustomTooltip = (props: {
  text?: ReactNode
  fileUrl?: string
  children: ReactElement
}) => {
  const [numPages, setNumPages] = useState<number | null>(null)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <Tooltip
      sx={{ overflow: 'hidden' }}
      title={
        props.fileUrl ? (
          <Document file={props.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {numPages && numPages > 0 && <Page pageNumber={1} />}
          </Document>
        ) : (
          props.text
        )
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: props.fileUrl
              ? 'transparent'
              : 'rgb(67, 136, 148)',
            cursor: 'pointer',
          },
        },
      }}
    >
      {props.children}
    </Tooltip>
  )
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    height: '30px',
    margin: '0px',
    padding: '0px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginLeft: '5px',
  },
}))
