export const GET_DOCTORS = 'GET_DOCTORS'
export const GET_DOCTORS_SUCCESS = 'GET_DOCTORS_SUCCESS'
export const GET_DOCTORS_FAILED = 'GET_DOCTORS_FAILED'

export const GET_DOCTOR_AUTOCOMPLETE = 'GET_DOCTOR_AUTOCOMPLETE'
export const GET_DOCTOR_AUTOCOMPLETE_SUCCESS = 'GET_DOCTOR_AUTOCOMPLETE_SUCCESS'
export const GET_DOCTOR_AUTOCOMPLETE_FAILED = 'GET_DOCTOR_AUTOCOMPLETE_FAILED'

export const ADD_DOCTOR_STEP_1 = 'ADD_DOCTOR_STEP_1'
export const ADD_DOCTOR_STEP_2 = 'ADD_DOCTOR_STEP_2'
export const ADD_DOCTOR_STEP_3 = 'ADD_DOCTOR_STEP_3'
export const ADD_DOCTOR_STEP_4 = 'ADD_DOCTOR_STEP_4'

export const GET_DOCTORS_SUMMARY = 'GET_DOCTORS_SUMMARY'
export const GET_DOCTORS_SUMMARY_SUCCESS = 'GET_DOCTORS_SUMMARY_SUCCESS'
export const GET_DOCTORS_SUMMARY_FAILED = 'GET_DOCTORS_SUMMARY_FAILED'

export const UPDATE_DOCTOR_STEP_1 = 'UPDATE_DOCTOR_STEP_1'
export const UPDATE_DOCTOR_STEP_3 = 'UPDATE_DOCTOR_STEP_3'
export const UPDATE_DOCTOR_STEP_4 = 'UPDATE_DOCTOR_STEP_4'

export const DELETE_DOCTOR = 'DELETE_DOCTOR'
export const DELETE_DOCTOR_SUCCESS = 'DELETE_DOCTOR_SUCCESS'
export const DELETE_DOCTOR_FAILED = 'DELETE_DOCTOR_FAILED'
