import { CORE_URL } from '../../../helpers/env'
export const API_URL = CORE_URL

export const TOGGLE_ALERT = 'TOGGLE_ALERT'
export const TOGGLE_ALERT_SUCCESS = 'TOGGLE_ALERT_SUCCESS'
export const TOGGLE_ALERT_FAILED = 'TOGGLE_ALERT_FAILED'

export const TOGGLE_TRANSMISSION = 'TOGGLE_TRANSMISSION'
export const TOGGLE_TRANSMISSION_SUCCESS = 'TOGGLE_TRANSMISSION_SUCCESS'
export const TOGGLE_TRANSMISSION_FAILED = 'TOGGLE_TRANSMISSION_FAILED'

export const TOGGLE_ALERT_HISTORIC = 'TOGGLE_ALERT_HISTORIC'
export const TOGGLE_ALERT_HISTORIC_SUCCESS = 'TOGGLE_ALERT_HISTORIC_SUCCESS'
export const TOGGLE_ALERT_HISTORIC_FAILED = 'TOGGLE_ALERT_HISTORIC_FAILED'

export const TOGGLE_ALERT_USER_SETTINGS = 'TOGGLE_ALERT_USER_SETTINGS'
export const TOGGLE_ALERT_USER_SETTINGS_SUCCESS =
  'TOGGLE_ALERT_USER_SETTINGS_SUCCESS'
export const TOGGLE_ALERT_USER_SETTINGS_FAILED =
  'TOGGLE_ALERT_USER_SETTINGS_FAILED'

export const PATIENT_EXPORT_ACTION = 'PATIENT_EXPORT_ACTION'

export const SET_PDF_VIEWED = 'SET_PDF_VIEWED'
export const GET_PDF = 'GET_PDF'

export const GET_ALERTS_SUMMARY = 'GET_ALERTS_SUMMARY'
export const GET_ALERTS_SUMMARY_SUCCESS = 'GET_ALERTS_SUMMARY_SUCCESS'
export const GET_ALERTS_SUMMARY_FAILED = 'GET_ALERTS_SUMMARY_FAILED'

export const GET_ALERT_ACTIONS = 'GET_ALERT_ACTIONS'
export const GET_ALERT_ACTIONS_SUCCESS = 'GET_ALERT_ACTIONS_SUCCESS'
export const GET_ALERT_ACTIONS_FAILED = 'GET_ALERT_ACTIONS_FAILED'

export const GET_ALERT_COUNT = 'GET_ALERT_COUNT'
export const GET_ALERT_COUNT_SUCCESS = 'GET_ALERT_COUNT_SUCCESS'
export const GET_ALERT_COUNT_FAILED = 'GET_ALERT_COUNT_FAILED'

export const SET_ALERT_PATIENT = 'SET_ALERT_PATIENT'
