import {
  GET_PRESCRIPTION_PDF,
  GET_PRESCRIPTIONS,
  GET_PRESCRIPTIONS_SUMMARY,
  SET_PRESCRIPTION_USER_SETTINGS,
  UPDATE_PRESCRIPTION_MAKER_READED,
} from '../constants'
import { PrescriptionsState } from '../services'
import { IUserSettings } from '../reducers'

export function getPrescriptionsSummary(resolve: any, reject: any) {
  return { type: GET_PRESCRIPTIONS_SUMMARY, resolve, reject }
}

export function getPrescriptions(
  limit: number,
  page: number,
  state: PrescriptionsState,
  read: boolean,
  resolve: any,
  reject: any,
) {
  return { type: GET_PRESCRIPTIONS, limit, page, state, read, resolve, reject }
}

export function getPrescriptionPdf(pdfName: string, resolve: any, reject: any) {
  return { type: GET_PRESCRIPTION_PDF, resolve, reject, pdfName }
}

export function setPrescriptionUserSettings(userSettings: IUserSettings) {
  console.log(`action is called with ${JSON.stringify(userSettings)}`)
  return {
    type: SET_PRESCRIPTION_USER_SETTINGS,
    userSettings,
  }
}

export function updatePrescriptionMakerReaded(
  prescription_id: string,
  maker_readed: boolean,
  maker_id: number,
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_PRESCRIPTION_MAKER_READED,
    prescription_id,
    maker_readed,
    maker_id,
    resolve,
    reject,
  }
}
