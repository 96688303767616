export const GET_PRESCRIPTIONS_SUMMARY = 'GET_PRESCRIPTIONS_SUMMARY'
export const GET_PRESCRIPTIONS_SUMMARY_FAILED =
  'GET_PRESCRIPTIONS_SUMMARY_FAILED'
export const GET_PRESCRIPTIONS_SUMMARY_SUCCESS =
  'GET_PRESCRIPTIONS_SUMMARY_SUCCESS'

export const GET_PRESCRIPTIONS = 'GET_PRESCRIPTIONS'
export const GET_PRESCRIPTIONS_SUCCESS = 'GET_PRESCRIPTIONS_SUCCESS'
export const GET_PRESCRIPTIONS_FAILED = 'GET_PRESCRIPTIONS_FAILED'

export const GET_PRESCRIPTION_PDF = 'GET_PRESCRIPTION_PDF'
export const GET_PRESCRIPTION_PDF_SUCCESS = 'GET_PRESCRIPTION_PDF_SUCCESS'
export const GET_PRESCRIPTION_PDF_FAILED = 'GET_PRESCRIPTION_PDF_FAILED'

export const SET_PRESCRIPTION_USER_SETTINGS = 'SET_PRESCRIPTION_USER_SETTINGS'

export const UPDATE_PRESCRIPTION_MAKER_READED =
  'UPDATE_PRESCRIPTION_MAKER_READED'
export const UPDATE_PRESCRIPTION_MAKER_READED_SUCCESS =
  'UPDATE_PRESCRIPTION_MAKER_READED_SUCCESS'
export const UPDATE_PRESCRIPTION_MAKER_READED_FAILED =
  'UPDATE_PRESCRIPTION_MAKER_READED_FAILED'
