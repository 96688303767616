import { CORE_URL } from '../../../helpers/env'

export const GET_DATA_PATIENT_EXPORT_SUMMARY = 'GET_DATA_PATIENT_EXPORT_SUMMARY'
export const GET_DATA_PATIENT_EXPORT_SUMMARY_SUCCESS =
  'GET_DATA_PATIENT_EXPORT_SUMMARY_SUCCESS'
export const GET_DATA_PATIENT_EXPORT_SUMMARY_FAILED =
  'GET_DATA_PATIENT_EXPORT_SUMMARY_FAILED'

export const GET_DATA_TRANSMITTER_SUMMARY = 'GET_DATA_TRANSMITTER_SUMMARY'
export const GET_DATA_TRANSMITTER_SUMMARY_SUCCESS =
  'GET_DATA_TRANSMITTER_SUMMARY_SUCCESS'
export const GET_DATA_TRANSMITTER_SUMMARY_FAILED =
  'GET_DATA_TRANSMITTER_SUMMARY_FAILED'

export const GET_DATA_PATIENT_SUMMARY = 'GET_DATA_PATIENT_SUMMARY'
export const GET_DATA_PATIENT_SUMMARY_SUCCESS =
  'GET_DATA_PATIENT_SUMMARY_SUCCESS'
export const GET_DATA_PATIENT_SUMMARY_FAILED = 'GET_DATA_PATIENT_SUMMARY_FAILED'

export const GET_DATA_BILLING_SUMMARY = 'GET_DATA_BILLING_SUMMARY'
export const GET_DATA_BILLING_SUMMARY_SUCCESS =
  'GET_DATA_BILLING_SUMMARY_SUCCESS'
export const GET_DATA_BILLING_SUMMARY_FAILED = 'GET_DATA_BILLING_SUMMARY_FAILED'

export const GET_DATA_PRESCRIPTION_SUMMARY = 'GET_DATA_PRESCRIPTION_SUMMARY'
export const GET_DATA_PRESCRIPTION_SUMMARY_SUCCESS =
  'GET_DATA_PRESCRIPTION_SUMMARY_SUCCESS'
export const GET_DATA_PRESCRIPTION_SUMMARY_FAILED =
  'GET_DATA_PRESCRIPTION_SUMMARY_FAILED'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED'

export const API_URL = CORE_URL
