import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* patientExportSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.homePatientExportSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DATA_PATIENT_EXPORT_SUMMARY_SUCCESS,
      Constants.GET_DATA_PATIENT_EXPORT_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DATA_PATIENT_EXPORT_SUMMARY_FAILED, error })
  }
}

function* transmitterSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.homeTransmitterSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DATA_TRANSMITTER_SUMMARY_SUCCESS,
      Constants.GET_DATA_TRANSMITTER_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DATA_TRANSMITTER_SUMMARY_FAILED, error })
  }
}

function* patientSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.homePatientSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DATA_PATIENT_SUMMARY_SUCCESS,
      Constants.GET_DATA_PATIENT_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DATA_PATIENT_SUMMARY_FAILED, error })
  }
}

function* billingSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.homeBillingSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DATA_BILLING_SUMMARY_SUCCESS,
      Constants.GET_DATA_BILLING_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DATA_BILLING_SUMMARY_FAILED, error })
  }
}
function* prescriptionsSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  console.log(`saga action ${action}`)

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.homePrescriptionsSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DATA_PRESCRIPTION_SUMMARY_SUCCESS,
      Constants.GET_DATA_PRESCRIPTION_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DATA_PRESCRIPTION_SUMMARY_FAILED, error })
  }
}

function* refreshToken(action: AnyAction) {
  // const getApiKey = (state: RootState) => state.authReducer.token

  try {
    // const api_key: string = yield select(getApiKey)

    // const refreshToken: IResponseStatus = yield call(
    //   Services.refreshTokenService,
    //   api_key,
    // )

    yield put({
      type: Constants.REFRESH_TOKEN_SUCCESS,
    })
  } catch (error) {
    yield put({ type: Constants.REFRESH_TOKEN_FAILED, error })
  }
}

export const homeSagas = [
  takeLatest(Constants.GET_DATA_PATIENT_EXPORT_SUMMARY, patientExportSummary),
  takeLatest(Constants.GET_DATA_TRANSMITTER_SUMMARY, transmitterSummary),
  takeLatest(Constants.GET_DATA_PATIENT_SUMMARY, patientSummary),
  takeLatest(Constants.GET_DATA_BILLING_SUMMARY, billingSummary),
  takeLatest(Constants.REFRESH_TOKEN, refreshToken),
  takeLatest(Constants.GET_DATA_PRESCRIPTION_SUMMARY, prescriptionsSummary),
]
