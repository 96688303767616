import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { CenterDTO } from 'src/helpers/dto'
import CenterContacts from './CenterContacts'
import MakerKeyManagement from './MakerKeyManagement'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'

interface CenterDetailsModalProps {
  open: boolean
  onClose: () => void
  center: CenterDTO
  hasPermissionToManageMakerKey: boolean
}

const CenterDetailsModal: React.FC<CenterDetailsModalProps> = ({
  open,
  onClose,
  center,
  hasPermissionToManageMakerKey,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  if (!center) return null

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  // Define content for each tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <CenterContacts centerId={center.id} />
      case 1:
        return <MakerKeyManagement centerId={center.id} />
      default:
        return null
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '90%',
          height: '90%',
          maxWidth: '90%',
          maxHeight: '90%',
          borderRadius: '20px',
          padding: 0,
          margin: 0,
          overflow: 'hidden',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LocalHospitalIcon
            sx={{
              color: '#ffa726',
              marginRight: 1,
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#ffa726',
            }}
          >
            Gestion du centre
          </Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            style={{
              color: '#ffa726',
              fontSize: '3rem',
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#ffa726',
            },
          }}
          textColor="primary"
        >
          <Tab label="Contacts" wrapped={false} />
          {hasPermissionToManageMakerKey && (
            <Tab label="Clés Constructeurs" wrapped={false} />
          )}
        </Tabs>
        <Box sx={{ marginTop: 2 }}>{renderTabContent()}</Box>
      </DialogContent>
    </Dialog>
  )
}

export default CenterDetailsModal
