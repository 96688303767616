import { PropsFromReduxManufacturers } from '../containers/ManufacturersContainer'
import {
  DataGridPro,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useState } from 'react'
import { Toolbar } from './Table/Toolbar'
import { Box, Tab, Tabs } from '@mui/material'

import AbbottLogo from './assets/AbbottLogo'
import BiotronikLogo from './assets/BiotronikLogo'
import BostonLogo from './assets/BostonLogo'
import MedTronicLogo from './assets/MedTronicLogo'
import MicroportLogo from './assets/MicroportLogo'

const ManufacturersComponent = (props: PropsFromReduxManufacturers) => {
  const [rowPerPage, setrowPerPage] = useState<number>(100) //eslint-disable-line

  const { page, limit, makerId } = props.filter

  const onRefresh = useCallback(() => {}, [])

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    props.setFilters({
      ...props.filter,
      makerId: newValue,
    })
  }

  useEffect(() => {
    props.getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, makerId])

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const columns: GridColumns = [
    // {
    //   field: 'col1',
    //   headerName: 'Fonction',
    //   flex: 1,
    //   sortable: false,
    //   valueGetter: (params: GridValueGetterParams) => ``,
    // },
    {
      field: 'col2',
      headerName: 'Nom Prénom',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.userName} ${params.row.firstName}`,
    },
    {
      field: 'col3',
      headerName: 'Adresse',
      flex: 1,
      minWidth: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.company.address,
    },
    {
      field: 'col4',
      headerName: 'Téléphone',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.phone,
    },
    {
      field: 'col5',
      headerName: 'Email',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.mail,
    },
  ]

  return (
    <Box sx={{ height: 'calc(100vh - 133px)', width: '100%' }}>
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ffa726',
            display: 'flexWrap',
          },
        }}
        onChange={handleTabChange}
        textColor="primary"
        value={makerId}
      >
        <Tab
          icon={<AbbottLogo />}
          {...a11yProps(0)}
          wrapped={false}
          value={2}
        />
        <Tab
          icon={<BiotronikLogo />}
          {...a11yProps(0)}
          wrapped={false}
          value={3}
        />
        <Tab
          icon={<BostonLogo width={100} height={60} />}
          {...a11yProps(0)}
          wrapped={false}
          value={4}
        />
        <Tab
          icon={<MedTronicLogo />}
          {...a11yProps(0)}
          wrapped={false}
          value={5}
        />
        <Tab
          icon={<MicroportLogo />}
          {...a11yProps(0)}
          wrapped={false}
          value={6}
        />
      </Tabs>

      <DataGridPro
        rows={props.data}
        columns={columns}
        components={{
          Toolbar,
        }}
        componentsProps={{
          toolbar: {
            title: 'Fabricants',
            onRefresh,
          },
        }}
        pageSize={rowPerPage}
        pagination
        initialState={{
          pagination: {
            page: 0,
          },
        }}
        rowHeight={60}
        disableColumnMenu
        disableSelectionOnClick
        sx={{
          border: 'none',
          padding: '3px 15px',
        }}
      />
    </Box>
  )
}

export default ManufacturersComponent
