import {
  ADD_DOCTOR_STEP_1,
  ADD_DOCTOR_STEP_2,
  ADD_DOCTOR_STEP_3,
  ADD_DOCTOR_STEP_4,
  DELETE_DOCTOR,
  GET_DOCTORS,
  GET_DOCTORS_SUMMARY,
  GET_DOCTOR_AUTOCOMPLETE,
  UPDATE_DOCTOR_STEP_1,
  UPDATE_DOCTOR_STEP_3,
  UPDATE_DOCTOR_STEP_4,
} from '../constants'
import {
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../reducers'

export function getDoctors(
  size: number,
  page: number,
  doctorType: string,
  search: string,
  resolve: any,
  reject: any,
) {
  return { type: GET_DOCTORS, size, page, doctorType, search, resolve, reject }
}

export function getDoctorAutocomplete(
  name: string,
  page: number,
  limit: number,
  resolve: any,
  reject: any,
) {
  return { type: GET_DOCTOR_AUTOCOMPLETE, name, page, limit, resolve, reject }
}

export function addDoctorStep1(
  doctor: INewDoctorStep1,
  resolve: any,
  reject: any,
) {
  return { type: ADD_DOCTOR_STEP_1, doctor, resolve, reject }
}

export function addDoctorStep2(id: number, resolve: any, reject: any) {
  return { type: ADD_DOCTOR_STEP_2, id, resolve, reject }
}

export function addDoctorStep3(
  id: number,
  address: INewDoctorAddress,
  resolve: any,
  reject: any,
) {
  return { type: ADD_DOCTOR_STEP_3, id, address, resolve, reject }
}

export function addDoctorStep4(
  id: number,
  doctorInfos: INewDoctorInfos,
  resolve: any,
  reject: any,
) {
  return { type: ADD_DOCTOR_STEP_4, id, doctorInfos, resolve, reject }
}

export function getDoctorsSummary(resolve: any, reject: any) {
  return { type: GET_DOCTORS_SUMMARY, resolve, reject }
}

export function updateDoctorStep1Action(
  id: number,
  doctor: INewDoctorStep1,
  resolve: any,
  reject: any,
) {
  return { type: UPDATE_DOCTOR_STEP_1, id, doctor, resolve, reject }
}

export function updateDoctorStep2Action(
  id: number,
  addressId: number,
  address: INewDoctorAddress,
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_DOCTOR_STEP_3,
    id,
    addressId,
    address,
    resolve,
    reject,
  }
}

export function updateDoctorStep4Action(
  id: number,
  doctorId: number,
  doctorInfos: INewDoctorInfos,
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_DOCTOR_STEP_4,
    id,
    doctorId,
    doctorInfos,
    resolve,
    reject,
  }
}

export function deleteDoctorAction(id: number, resolve: any, reject: any) {
  return { type: DELETE_DOCTOR, id, resolve, reject }
}
