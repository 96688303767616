import { LICENCE_KEY } from './helpers/env'
import Routes from './routes'

import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(LICENCE_KEY)

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  )
}

export default App
