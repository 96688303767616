export interface Pagination {
  page: number
  totalPages: number
  limit: number
}

export enum CompanyType {
  CENTER = 'CENTER',
  MAKER = 'MAKER',
  OWNER = 'OWNER',
}
