import ManufacturersContainer from '../containers/ManufacturersContainer'
import * as manufacturersRoutes from './manufacturers'

export const ManufacturersRoutes = [
  {
    key: 'manufacturers',
    exact: true,
    path: manufacturersRoutes.MANUFACTURERS,
    component: ManufacturersContainer,
  },
]
