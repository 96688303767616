import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CreateConstructorKeyDto,
  CreateUserAddressDto,
  CreateUserDto,
  CreateUserRoleDto,
} from 'src/helpers/dto'
import { RootState } from 'src/store'
import * as Constants from '../constants'
import * as Services from '../services'

export const createUser = createAsyncThunk(
  Constants.CREATE_USER,
  async (
    payload: {
      userDto: CreateUserDto
      addressDto: CreateUserAddressDto
      roleDto: CreateUserRoleDto
    },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const createUserResponse = await Services.createCenterUser(
      token,
      payload.userDto,
    )

    if (!createUserResponse || createUserResponse.status !== 200) {
      return thunkAPI.rejectWithValue("Couldn't create user")
    }

    const addressResponse = await Services.createUserAddress(
      token,
      createUserResponse.data.id,
      payload.addressDto,
    )

    await Services.createUserRole(
      token,
      createUserResponse.data.id,
      payload.roleDto,
    )

    if (!addressResponse || addressResponse.status !== 200) {
      return thunkAPI.rejectWithValue("Couldn't create user address")
    }

    return addressResponse.data
  },
)

export const updateUser = createAsyncThunk(
  Constants.UPDATE_USER,
  async (
    payload: {
      id: number
      userDto: CreateUserDto
      addressDto: CreateUserAddressDto
    },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const userRes = await Services.updateCenterUser(
      token,
      payload.id,
      payload.userDto,
    )

    const addressRes = await Services.updateUserAddress(
      token,
      payload.id,
      payload.addressDto,
    )
    if (
      userRes &&
      userRes.status === 200 &&
      addressRes &&
      addressRes.status === 200
    ) {
      return addressRes.data
    }

    return thunkAPI.rejectWithValue("Couldn't update user")
  },
)

export const createUserRole = createAsyncThunk(
  Constants.CREATE_USER_ROLE,
  async (payload: { userId: number; dto: CreateUserRoleDto }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const getApiKey = state.authReducer.token
    const token = await getApiKey()

    const response = await Services.createUserRole(
      token,
      payload.userId,
      payload.dto,
    )

    return response
  },
)

export const createUserAddress = createAsyncThunk(
  Constants.CREATE_USER_ADDRESS,
  async (payload: { userId: number; dto: any }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const getApiKey = state.authReducer.token
    const token = await getApiKey()

    const response = await Services.createUserAddress(
      token,
      payload.userId,
      payload.dto,
    )

    return response
  },
)

export const deleteUser = createAsyncThunk(
  Constants.DELETE_USER,
  async (id: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.deleteUser(token, id)
    if (response && response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue("Couldn't delete user")
  },
)

export const updateUserAddress = createAsyncThunk(
  Constants.UPDATE_USER_ADDRESS,
  async (payload: { userId: number; dto: CreateUserAddressDto }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const getApiKey = state.authReducer.token
    const token = await getApiKey()

    const response = await Services.updateUserAddress(
      token,
      payload.userId,
      payload.dto,
    )

    return response
  },
)

export const findCountries = createAsyncThunk(
  Constants.FIND_COUNTRIES,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.findCountries(token)
    if (response && response.status === 200) {
      return response.data
    }
    return []
  },
)

export const findRoles = createAsyncThunk(
  Constants.FIND_ROLES,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.findRoles(token)

    if (response && response.status === 200) {
      return response.data
    }

    return []
  },
)

export const findContacts = createAsyncThunk(
  Constants.FIND_CENTER_USERS,
  async (id: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.findContacts(token, id)

    if (response && response.status === 200) {
      return response.data
    }

    return []
  },
)

export const createConstructorKey = createAsyncThunk(
  Constants.CREATE_CONSTR_KEY,
  async (dto: CreateConstructorKeyDto, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.createConstructorKey(token, dto)

    if (response && response.status === 200) {
      return response.data
    }

    return thunkAPI.rejectWithValue("Couldn't create constructor key")
  },
)

export const updateConstructorKey = createAsyncThunk(
  Constants.UPDATE_CONSTR_KEY,
  async (payload: { dto: CreateConstructorKeyDto; id: number }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.updateConstructorKey(
      token,
      payload.id,
      payload.dto,
    )

    if (response && response.status === 200) {
      return response.data
    }

    return thunkAPI.rejectWithValue("Couldn't update constructor key")
  },
)

export const deleteConstructorKey = createAsyncThunk(
  Constants.DELETE_CONSTR_KEY,
  async (id: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.deleteConstructorKey(token, id)

    if (response && response.status === 200) {
      return response.data
    }

    return thunkAPI.rejectWithValue("Couldn't delete constructor key")
  },
)

export const findConstructorKeys = createAsyncThunk(
  Constants.GET_CONSTR_KEYS,
  async (centerId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const token = state.authReducer.token

    const response = await Services.findConstructorKeys(token, centerId)

    if (response && response.status === 200) {
      return response.data
    }

    return thunkAPI.rejectWithValue("Couldn't get constructor keys")
  },
)
