function BiotronikLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 9">
      <g>
        <path
          fill="#002F5C"
          d="M12.387 7.875V1.098h3.586c.355 0 .937.039 1.379.351.437.313.77.856.77 1.43 0 .75-.423 1.324-1.079 1.473v.02c.738.124 1.328.679 1.328 1.534 0 1.676-1.21 1.969-2.476 1.969zm2.078-4.176h1.02c.343 0 .6-.136.6-.574 0-.516-.366-.574-.733-.574h-.887zm0 2.668h1.058c.348 0 .696-.148.696-.605 0-.528-.41-.621-.785-.621h-.97zm0 0M19.27 7.875h2.128V1.098H19.27zm0 0M28.926 4.496c0 1.977-1.203 3.547-3.293 3.547-2.082 0-3.285-1.57-3.285-3.547 0-2.062 1.191-3.574 3.285-3.574 2.101 0 3.293 1.512 3.293 3.574m-4.328.012c0 .676.074 1.957 1.035 1.957.969 0 1.031-1.281 1.031-1.957 0-.676-.098-2.028-1.031-2.028-.934 0-1.035 1.352-1.035 2.028M32.867 2.734v5.141h-2.125v-5.14h-1.68V1.097h5.45v1.636zm0 0M41.52 7.875h-2.454L37.91 5.348h-.496v2.527h-2.129V1.098h3.293c.602 0 1.258.09 1.77.418.566.363.843.957.843 1.617 0 .887-.375 1.484-1.183 1.855zm-4.106-3.977h.574c.457 0 1.012-.132 1.012-.714a.626.626 0 00-.332-.555c-.18-.09-.402-.09-.602-.09h-.652zm0 0M48.418 4.496c0 1.977-1.203 3.547-3.293 3.547-2.082 0-3.285-1.57-3.285-3.547 0-2.062 1.191-3.574 3.285-3.574 2.102 0 3.293 1.512 3.293 3.574m-4.324.012c0 .676.07 1.957 1.031 1.957.969 0 1.031-1.281 1.031-1.957 0-.676-.097-2.028-1.031-2.028-.934 0-1.031 1.352-1.031 2.028M56.898 7.875h2.13V1.098h-2.13zm0 0M64.594 3.582l1.879-2.484h-2.317l-1.625 2.336V1.098h-2.129v6.777h2.13V6.098l.573-.785 1.176 2.562h2.465zm0 0M55.52 7.875h-2.227l-1.89-3.773c-.036-.07-.165-.395-.165-.395s.051.461.051.676v3.492h-1.922V1.098h2.473l1.613 3.265c.04.082.2.465.2.465s-.06-.488-.06-.695V1.098h1.927zm0 0"
        ></path>
        <path
          fill="#688CA3"
          d="M6.957 7.98c-.668.48-1.387.704-2.203.758V6.156h3.598c-.305.758-.73 1.344-1.395 1.824m-4.969 0C1.313 7.5.878 6.918.57 6.156h3.633v2.582c-.82-.054-1.539-.277-2.215-.758M6.047 2.898c.117-.218.328-.336.613-.355.297.008.524.117.656.34.13.21.184.453.172.719-.027.48-.277 1.007-.828 1.011a.708.708 0 01-.562-.34 1.353 1.353 0 01-.196-.675 1.342 1.342 0 01.145-.7m-.438 1.778c.274.289.618.449 1.043.472.883 0 1.461-.703 1.508-1.55 0-.438-.14-.801-.414-1.098a1.498 1.498 0 00-1.055-.477c-.433.004-.789.157-1.066.461-.277.301-.422.672-.43 1.114.004.43.145.789.414 1.078M1.957 3.344v-.742h.7c.21 0 .39.136.398.359a.375.375 0 01-.125.273.436.436 0 01-.282.11zm0 1.32v-.871h.68c.25.004.457.156.488.414.02.309-.223.453-.496.457zm-.64-2.531v2.976h1.437c.605.004 1.05-.304 1.05-.847 0-.442-.35-.684-.585-.746.16-.059.457-.274.457-.649 0-.496-.364-.734-.84-.734zM.436 5.64l-.03-.036a3.886 3.886 0 01-.153-1.101C.266 3.05.93 1.734 2.148.93 2.781.512 3.445.324 4.195.273V5.09h.57L4.755.262c.754.047 1.422.234 2.055.652 1.226.805 1.875 2.133 1.886 3.59 0 .394-.062.758-.168 1.137zm0 0"
        ></path>
      </g>
    </svg>
  )
}

export default BiotronikLogo
