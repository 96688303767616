import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import React from 'react'
import { Download, Receipt, Refresh } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { VID_SIGNER_URL } from '../../../../helpers/env'

export type ToolbarProps = {
  title: string
  icon: string
  onRefresh: () => void
  onDownload: () => void
  onAdd?: () => void
  onDelete?: () => void
}

export function Toolbar({ title, onRefresh, onDownload }: ToolbarProps) {
  return (
    <>
      <GridToolbarContainer
        sx={{
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          borderRadius: '0px 15px 0px 0px',
        }}
      >
        <Box
          ml={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ pr: 10 }}
        >
          <Receipt
            sx={{
              marginRight: '1rem',
              padding: '0px 10px 0px 0px',
              color: 'white',
              width: '10% ',
              height: '10%',
            }}
          />

          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: 'bold', color: 'white' }}
          >
            {title}
          </Typography>
        </Box>

        <Button
          onClick={onDownload}
          size="small"
          color="primary"
          aria-label="info"
          variant="contained"
          sx={{ marginRight: '1rem', background: '#ffa726' }}
        >
          <Download />
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', marginLeft: 1 }}
          >
            Charger les documents
          </Typography>
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <a href={VID_SIGNER_URL} target="_blank" rel="noreferrer">
          <Button
            size="small"
            color="primary"
            aria-label="info"
            variant="contained"
            sx={{ marginRight: '1rem', background: '#ffa726' }}
          >
            <LaunchIcon />
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', marginLeft: 1 }}
            >
              VIDSigner
            </Typography>
          </Button>
        </a>

        <Tooltip title={'Rafraîchir'}>
          <IconButton onClick={onRefresh}>
            <Refresh />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>
    </>
  )
}
