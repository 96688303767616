import { PropsFromReduxProtheses } from '../containers/ProtheseContainer'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useState } from 'react'
import { Toolbar } from './Table/Toolbar'
import Loader from '../../common/Loader'
import { Box, Tab, Tabs } from '@mui/material'
import {
  prosthesisGammeName,
  prosthesisRangeName,
} from '../../../utils/patientInfos'
import moment from 'moment'
import { companyClickableIconsNameMap } from '../../../utils/constants'

const ProtheseComponent = (props: PropsFromReduxProtheses) => {
  const [data, setData] = useState([])
  const [value, setValue] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState('')
  const [selectedGammes, setSelectedGammes] = useState<string[]>([])
  const [selectedMakerIds, setSelectedMakerIds] = useState<number[]>([])
  const [sortModel, setSortModel] = useState<GridSortModel | null>(null)

  useEffect(() => {
    // props.getCompany(20, 0, '', false)
    props.getCountry()
    props.getGammeRange(20, 0)
    props.getTransmitterReference(20, 0)
    props.getMakers()
  }, []) //eslint-disable-line

  useEffect(() => {
    props.getProsthesisReference(searchText, selectedGammes, selectedMakerIds)
  }, [searchText, selectedGammes, selectedMakerIds]) //eslint-disable-line

  useEffect(() => {
    if (props.gammeRange) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [props.gammeRange])

  useEffect(() => {
    loading === false ? setData(setDataFiltered(0)) : setData([])
  }, [loading, props.prosthesisReferences]) //eslint-disable-line

  const setDataFiltered = (index: number) => {
    let response: any

    switch (index) {
      case 0:
        response = props.prosthesisReferences
        break
      case 1:
        response = props.transmitterReference
        break
      case 2:
        response = []
        break
    }
    return response
  }

  const onRefresh = useCallback(() => {}, [])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    switch (newValue) {
      case 0:
        setData(setDataFiltered(0))
        break
      case 1:
        setData(setDataFiltered(1))
        break
      case 2:
        setData(setDataFiltered(2))
        break
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const getColumns = (): GridColDef[] => {
    const commonColumns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => `${params.row.id}`,
      },
      {
        field: 'col1',
        headerName: 'Modèle',
        width: 150,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.referenceName}`,
      },
      {
        field: 'col2',
        headerName: 'Référence',
        width: 200,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.prosthesisModel}`,
      },
    ]
    if (value === 0) {
      commonColumns.push(
        {
          field: 'col3',
          headerName: 'Gamme',
          width: 200,
          sortable: false,
          valueGetter: (params: GridValueGetterParams) =>
            `${prosthesisGammeName(params.row?.prosthesisGamme?.stringKey)}`,
        },
        {
          field: 'col4',
          headerName: 'Segment',
          width: 200,
          sortable: false,
          valueGetter: (params: GridValueGetterParams) =>
            `${prosthesisRangeName(params.row?.prosthesisRange?.stringKey)}`,
        },
      )
    }
    commonColumns.push(
      {
        field: 'col5',
        headerName: 'Fabricant',
        width: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          // Ensure 'params' is correctly typed
          return params.row.maker.companyName ? (
            companyClickableIconsNameMap[params.row.maker.companyName]
          ) : (
            <></>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'Date de création',
        width: 100,
        minWidth: 250,
        sortable: true,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.createdAt
            ? `${moment(params.row.createdAt).format('DD-MM-YYYY')}`
            : '',
        sortComparator: (v1, v2) => {
          const date1 = v1
            ? moment(v1, 'DD-MM-YYYY').toDate()
            : new Date('01-01-1990')

          const date2 = v2
            ? moment(v2, 'DD-MM-YYYY').toDate()
            : new Date('01-01-1990')

          const diff = date1.getTime() - date2.getTime()
          return diff
        },
      },
      {
        field: 'col6',
        headerName: '€',
        width: 100,
        minWidth: 250,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          `${
            params.row?.billableList && params.row.billableList.length > 0
              ? 'Référence facturable'
              : ''
          } `,
      },
    )
    return commonColumns
  }

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 133px)', width: '100%' }}>
        <Loader open={loading} text="En attente" />
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: '#ffa726',
              display: 'flexWrap',
            },
          }}
          onChange={handleTabChange}
          textColor="primary"
          value={value}
        >
          <Tab
            label={`Références de prothèses : ${
              loading ? 0 : setDataFiltered(0)?.length
            }`}
            {...a11yProps(0)}
            wrapped={false}
          />
          <Tab
            label={`Références de transmetteurs : ${
              loading ? 0 : setDataFiltered(1)?.length
            }`}
            {...a11yProps(0)}
            wrapped={false}
          />
          <Tab
            label={`Références de sondes : ${
              loading ? 0 : setDataFiltered(2)?.length
            }`}
            {...a11yProps(0)}
            wrapped={false}
          />
        </Tabs>

        <DataGridPro
          rows={data}
          columns={getColumns()}
          components={{
            Toolbar,
          }}
          sortModel={sortModel || []}
          onSortModelChange={(model) => setSortModel(model)}
          componentsProps={{
            toolbar: {
              title: 'Références Prothèses',
              onRefresh,
              searchText,
              setSearchText,
              selectedGammes,
              setSelectedGammes,
              selectedMakerIds,
              setSelectedMakerIds,
              makers: props.makers.data,
            },
          }}
          pagination
          initialState={{
            pagination: {
              page: 0,
            },
          }}
          rowHeight={60}
          disableColumnMenu
          disableSelectionOnClick
          sx={{
            border: 'none',
            padding: '3px 15px',
          }}
        />
      </Box>
    </>
  )
}

export default ProtheseComponent
