import { REFACTOR_API_URL } from '../../auth/constants'

export const fetchManufacturers = (
  token: string,
  page: number,
  size: number,
  makerId: number,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/users?page=${page}&size=${size}&role=Contact&makerId=${makerId}`
  const paramters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }
  let status: number
  return fetch(ENDPOINT, paramters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => ({ data, status }))
    .catch((error) => error)
}
