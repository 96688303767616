import {
  Refresh,
  ViewColumn,
  // CalendarMonth,
  // Close,
  Savings,
} from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import {
  Tooltip,
  IconButton,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
  Button,
  // styled,
  // InputBase,
  // alpha,
  // Dialog,
  // Paper,
  // DialogTitle,
} from '@mui/material'
import { useState } from 'react'
import LaunchIcon from '@mui/icons-material/Launch'
import {STELLAIR_FSE} from '../../../../helpers/env';

export type ToolbarProps = {
  title: string
  icon: string
  onRefresh: () => void
  onAdd?: () => void
  onDelete?: () => void
}

export function Toolbar({
  title,
  onRefresh,
}: ToolbarProps) {
  const apiRef = useGridApiContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const [openCalendar, setOpenCalendar] = useState(false)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()

  // const handleOpenCalendar = () => {
  //   setOpenCalendar(true)
  // }
  // const handleCloseCalendar = () => {
  //   setOpenCalendar(false)
  // }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field === event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }

  // const Search = styled('div')(({ theme }) => ({
  //   position: 'relative',
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   '&:hover': {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginLeft: 0,
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     marginLeft: theme.spacing(1),
  //     width: 'auto',
  //   },
  // }))

  // const SearchIconWrapper = styled('div')(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // }))

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: 'inherit',
  //   '& .MuiInputBase-input': {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create('width'),
  //     width: '100%',
  //     [theme.breakpoints.up('sm')]: {
  //       width: '12ch',
  //       '&:focus': {
  //         width: '20ch',
  //       },
  //     },
  //   },
  // }))

  return (
    <>
      <GridToolbarContainer
        sx={{
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          borderRadius: '0px 15px 0px 0px',
        }}
      >
        <Box
          ml={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ pr: 10 }}
        >
          <Savings
            sx={{
              marginRight: '1rem',
              padding: '0px 10px 0px 0px',
              color: 'white',
              width: '10% ',
              height: '10%',
            }}
          />

          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: 'bold', color: 'white' }}
          >
            {title}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        {/* <GridToolbarDensitySelector /> */}
        {/* <GridToolbarExport /> */}
        {/* <GridToolbarFilterButton /> */}
        {/* <Tooltip title={'Calendrier de facturation'}>
          <IconButton onClick={handleOpenCalendar}>
            <CalendarMonth />
          </IconButton>
        </Tooltip> */}
        {/* <Search>
          <SearchIconWrapper>
            <Search sx={{ color: '#44646a' }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Rechercher…"
            inputProps={{ 'aria-label': 'search' }}
            sx={{ color: '#44646a' }}
          />
        </Search> */}
        <a href={STELLAIR_FSE} target="_blank" rel="noreferrer">
          <Button
            size="small"
            color="primary"
            aria-label="info"
            variant="contained"
            sx={{ marginRight: '1rem', background: '#ffa726' }}
          >
            <LaunchIcon />
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', marginLeft: 1 }}
            >
              Stellair
            </Typography>
          </Button>
        </a>

        <Tooltip title={'Colonnes'}>
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <ViewColumn />
          </IconButton>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {columns.map((column, key) => (
            <MenuItem key={key} onClick={handleHide} id={column.field}>
              <FormControlLabel
                control={<Checkbox checked={!column.hide} />}
                label={column.headerName as string}
              />
            </MenuItem>
          ))}
        </Menu>

        <Tooltip title={'Rafraîchir'}>
          <IconButton onClick={onRefresh}>
            <Refresh />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>

      {/* <Dialog open={openCalendar}>
        <Paper elevation={2}>
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
              color: 'white',
              fontWeight: '700',
              fontSize: '24px',
            }}
          >
            {'Calendrier de facturation'}
            <IconButton onClick={handleCloseCalendar}>
              <Close sx={{ color: 'white' }} />
            </IconButton>
          </DialogTitle>
        </Paper>
        <Box></Box>
      </Dialog> */}
    </>
  )
}
