import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'src/store';
import { DataGridPro, GridColumns, GridValueGetterParams } from '@mui/x-data-grid-pro';
import Loader from '../../../common/Loader';
import { Box } from '@mui/material';
import { getDoctorType } from '../../../../utils/addPatient';
import { IDoctor } from '../../reducers';

interface IProps {
    handleReceiverSelection: (mail: string) => void;
    getDoctors: (size: number, page: number, doctorType: string, search: string) => Promise<unknown>;
}

const PromantisGrid = (props: IProps) => {

    const doctors: { data: IDoctor[], totalPages: number } = useSelector((state: RootState) => state.doctorsReducer.doctors);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(20);
    const [activePage, setActivePage] = useState(0);
    // const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true)
        props.getDoctors(10000, activePage, '', '')
            .then(() => setLoading(false))
            .catch(() => toast.error('Impossible de charger les médecins'))
    }, [])//eslint-disable-line
    // }, [tab, rows, activePage])

    const setDataFiltered = () => {
        return doctors.data.filter((doctor: any) => (doctor.company && doctor.company.companyName) === 'PROMANTIS')
    }

    const columns: GridColumns = [
        {
            field: 'col2',
            headerName: 'Fonction',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.doctor ? getDoctorType(params.row.doctor) : params.row.title,
        },
        {
            field: 'col3',
            headerName: 'Nom Prénom',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.firstName && params.row.userName) ? `${params.row.userName} ${params.row.firstName}` : '',
        },
        {
            field: 'col4',
            headerName: 'Adresse',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                (params.row.addresses && params.row.addresses[0]) ? `${params.row.addresses[0].street} ${params.row.addresses[0].zipCode} ${params.row.addresses[0].city}` : '',
        },
        {
            field: 'col5',
            headerName: 'Téléphone',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.phone ? `${params.row.phone}` : '',
        },
        {
            field: 'col6',
            headerName: 'Email',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.mail ? `${params.row.mail}` : ''
        }
    ]


    return (
        <Box sx={{ height: 'calc(100vh - 133px)', width: '100%' }}>
            <Loader open={loading} text="Chargement des médecins" />
            <DataGridPro
                rows={setDataFiltered()}
                columns={columns}
                onRowClick={(selected) => props.handleReceiverSelection(selected.row.mail)}
                pageSize={rows}
                onPageSizeChange={(newPageSize) => { setRows(newPageSize); setActivePage(0) }}
                pagination
                page={activePage}
                onPageChange={(newPage) => setActivePage(newPage)}
                initialState={{
                    pagination: {
                        page: activePage,
                    },
                }}
                rowHeight={60}
                disableColumnMenu
                disableSelectionOnClick
                sx={{
                    border: 'none',
                    padding: '3px 15px',
                    cursor: 'pointer'
                }}
            />
        </Box>
    )
}

export default PromantisGrid