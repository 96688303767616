import BillingContainer from '../containers/BillingContainer'
import * as billingRoutes from './billing'

export const BillingRoutes = [
  {
    key: 'billing',
    exact: true,
    path: billingRoutes.BILLING,
    component: BillingContainer,
  },
]
