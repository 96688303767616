import { AnyAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import {
  GET_MANUFACTURERS_SUCCESS,
  SET_MANUFACTURER_FILTER,
} from '../constants'

export interface ManufacturerState {
  filter: ManufacturerFilter
}
export interface ManufacturerFilter {
  page: number
  size: number
  limit: number
  role: string
  makerId: number
}

const initialState = {
  data: [],
  filter: {
    page: 0,
    size: 10,
    limit: 10,
    role: 'manufacturer',
    makerId: 2,
  },
} as ManufacturerState

const manufacturerReducer = produce(
  (draft = initialState, action: AnyAction) => {
    switch (action.type) {
      case GET_MANUFACTURERS_SUCCESS:
        draft.data = action.payload.data.data
        break
      case SET_MANUFACTURER_FILTER:
        draft.filter = action.filter
        break
      default:
        return draft
    }
  },
)

export default manufacturerReducer
