import {
  GET_MESSAGES,
  GET_USER_ROLES,
  GET_USER,
  SEND_MESSAGE,
  SET_MESSAGE_DETAILS,
} from '../constants'
import { IMessage } from '../reducers'

export function getMessages(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return { type: GET_MESSAGES, size, page, resolve, reject }
}
export function getUserRoles(resolve: any, reject: any) {
  return {
    type: GET_USER_ROLES,
    resolve,
    reject,
  }
}

export function getUser(size: number, page: number, resolve: any, reject: any) {
  return { type: GET_USER, size, page, resolve, reject }
}
export function sendMessage(message: IMessage, resolve: any, reject: any) {
  return { type: SEND_MESSAGE, message, resolve, reject }
}

export function setMessageDetails(id: number, resolve: any, reject: any) {
  return { type: SET_MESSAGE_DETAILS, id, resolve, reject }
}
