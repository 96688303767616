

function Icon ({width, height}) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 267.973 68.492"
    >
      <g transform="translate(154.91 -104.516)">
        <g strokeWidth="0.265" transform="translate(-790.752 -646.404)">
          <path
            fill="#159bd7"
            d="M708.918 751.92h-72.075v11.076h68.477v.01a3.552 3.552 0 013.565 3.563v37.194l-.01.031a3.568 3.568 0 01-3.571 3.544H651.49a3.579 3.579 0 01-3.582-3.575v-15.026a3.585 3.585 0 013.582-3.576h46.298V774.1h-49.901c-6.1 0-11.044 4.935-11.044 11.03v22.24c0 6.099 4.944 11.042 11.044 11.042h61.03c6.105 0 11.042-4.943 11.042-11.042v-44.405c0-6.099-4.937-11.046-11.042-11.046"
          ></path>
          <path d="M749.957 786.433h7.496l-3.713-11.125h-.103zm10.92-18.812l8.634 25.829c1.025 3.151 1.75 3.88 3.55 3.88h1.755v4.547h-18.854v-4.547h1.546c1.55 0 2.84-.206 2.84-.767 0-.531-.052-1.099-.26-1.875l-1.078-3.712h-10.54l-.63 2.068c-.564 1.803-.773 2.57-.773 3.295 0 .833 1.814.991 3.219.991h1.486v4.547h-15.189v-4.547h1.76c1.808 0 2.52-.729 3.568-3.88l8.627-25.829z"></path>
          <path d="M786.398 789.777c0 5.28 1.184 8.426 4.075 8.426 2.909 0 4.084-3.146 4.084-8.426 0-5.265-1.175-8.414-4.084-8.414-2.89 0-4.075 3.15-4.075 8.414m-8.585-15.798c0-1.46-.36-2.226-1.858-2.226h-2.375v-4.132h12.818v12.859c1.908-2.171 4.341-3.565 6.655-3.565 7.753 0 10.642 6.057 10.642 12.862 0 6.824-2.889 12.866-10.642 12.866-3.254 0-6.15-1.135-7.952-3.978l-1.493 3.216h-5.795z"></path>
          <path d="M815.381 789.777c0 5.28 1.2 8.426 4.086 8.426 2.896 0 4.079-3.146 4.079-8.426 0-5.265-1.183-8.414-4.079-8.414-2.886 0-4.086 3.15-4.086 8.414M806.8 773.98c0-1.46-.364-2.226-1.847-2.226h-2.382v-4.132h12.81v12.859c1.918-2.171 4.342-3.565 6.666-3.565 7.753 0 10.65 6.057 10.65 12.862 0 6.824-2.897 12.866-10.65 12.866-3.254 0-6.15-1.135-7.955-3.978l-1.504 3.216H806.8z"></path>
          <path d="M844.32 789.779c0 6.095 1.084 8.732 4.186 8.732 3.096 0 4.177-2.637 4.177-8.732 0-6.095-1.081-8.728-4.177-8.728-3.102 0-4.185 2.633-4.185 8.728m17.515 0c0 8.27-5.636 12.864-13.33 12.864-7.711 0-13.334-4.593-13.334-12.864 0-8.266 5.623-12.864 13.334-12.864 7.694 0 13.33 4.598 13.33 12.864"></path>
          <path d="M898.675 790.198v4.029c0 2.374-.513 3.779-2.477 3.779-1.187 0-1.75-.885-1.75-1.974v-14.206h6.342v-4.136h-6.342v-9.597h-5.125c-.42 4.28-1.758 9.597-6.923 9.597h-8.058v-9.597h-5.111c-.417 4.28-1.763 9.597-6.923 9.597v4.136h3.46v12.966c0 5.272 2.943 7.85 8.256 7.85 6.214 0 8.679-2.782 8.679-7.85v-4.595h-4.12v4.03c0 2.374-.521 3.779-2.479 3.779-1.199 0-1.763-.885-1.763-1.974v-14.206h11.536v12.966c0 5.272 2.931 7.85 8.255 7.85 6.197 0 8.684-2.782 8.684-7.85v-4.595h-4.141z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;