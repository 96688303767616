import React, { useState } from 'react'
import { PropsFromReduxForgot } from '../containers/ForgotPswdContainer'
import Logo from '../../../assets/icon/logo-seul.png'
import Name from '../../../assets/icon/my-promantis-brand.png'
import { Alert, Box, Button, Grid, Paper, TextField } from '@mui/material'
import { Link } from 'react-router-dom'


const ForgotPswdComponent = (props: PropsFromReduxForgot) => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [mail, setMail] = useState("")

    const handleSubmit = (event: any) => {
        setError('');
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }
        props.forogtPwd(mail)
            .then(() => setMessage(`Un e-mail vient de vous être envoyé`))
            .catch(e => setError(`L'email n'existe pas`))
        setLoading(true)
    };


    return <>
        <div className='container'>
            <Box sx={
                {
                    backgroundColor: '#F8F9F9',
                    margin: 'auto',
                }
            }>
                <Box sx={
                    {
                        display: 'flex ',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }
                } className='Logo'>
                    <img src={Logo} alt="" />
                    <img src={Name} alt="" />
                </Box>

                <Paper sx={
                    {
                        display: 'flex ',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        margin: '15px',
                        padding: '35px'
                    }
                }>
                    <Paper sx={
                        {
                            display: 'flex ',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'linear-gradient(to right, #85C4D0, #9FDEEC)',
                            borderRadius: '5px',
                            maxWidth: '300px',
                            width: '100%',
                            maxHeight: '40px',
                            margin: '-50px 15px 20px 0',
                            color: 'white',
                        }}>
                        <h3>Mot de passe oublié</h3>
                    </Paper>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={2} sx={
                            {
                                minWidth: '350px',
                                width: '100%',
                            }
                        }>
                            <Grid item>
                                <TextField
                                    type="email"
                                    placeholder="Email"
                                    fullWidth
                                    name="mail"
                                    variant="outlined"
                                    onChange={e => setMail(e.target.value)}
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid sx={{
                                paddingTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                {error && <Alert severity='error'>{error}</Alert>}
                                {message && <Alert severity='success'>{message}</Alert>}
                            </Grid>
                            <Grid sx={{
                                paddingTop: '16px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Button
                                    type="submit"
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    sx={
                                        {
                                            background: '#9FDEEC'
                                        }
                                    }>Envoyer un nouveau mot de passe</Button>
                            </Grid>
                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <div className='forgotpswd'>
                                    <Link to='/login' >S'identifier</Link>
                                </div>
                            </Grid>
                        </Grid>
                    </form>

                </Paper>
            </Box>
        </div>
    </>
}

export default ForgotPswdComponent
