import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DoctorsComponent from '../components/DoctorsComponent'
import {
  addDoctorStep1,
  addDoctorStep2,
  addDoctorStep3,
  addDoctorStep4,
  getDoctorAutocomplete,
  getDoctors,
  getDoctorsSummary,
} from '../actions'
import {
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../reducers'
import { getCountry } from '../../../modules/patients/actions'

const mapStateToProps = (state: RootState) => {
  return {
    isLogged: state.authReducer.isLogged,
    user: state.authReducer.user,
    token: state.authReducer.token,
    userSettings: state.alertsReducer.userSettings,
    doctors: state.doctorsReducer.doctors,
    doctorsSummary: state.doctorsReducer.summary,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDoctors: (
      size: number,
      page: number,
      doctorType: string,
      search: string,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctors(size, page, doctorType, search, resolve, reject))
      })
    },
    getDoctorAutocomplete: (name: string, page: number, limit: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctorAutocomplete(name, page, limit, resolve, reject))
      })
    },
    getCountries: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCountry(resolve, reject))
      })
    },
    addDoctor: {
      step1: (doctor: INewDoctorStep1) => {
        return new Promise((resolve: (id: number) => void, reject: any) => {
          dispatch(addDoctorStep1(doctor, resolve, reject))
        })
      },
      step2: (id: number) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep2(id, resolve, reject))
        })
      },
      step3: (id: number, address: INewDoctorAddress) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep3(id, address, resolve, reject))
        })
      },
      step4: (id: number, doctorInfos: INewDoctorInfos) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep4(id, doctorInfos, resolve, reject))
        })
      },
    },
    getDoctorsSummary: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctorsSummary(resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxDoctors = ConnectedProps<typeof connector>

export default withRouter(connector(DoctorsComponent))
