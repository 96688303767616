import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Loader from '../../../../modules/common/Loader'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { ConstructorKeyDto, CreateConstructorKeyDto } from 'src/helpers/dto'
import { getCompany } from '../../actions'
import { CompanyType } from '../../../../common/interfaces'
import { createConstructorKey, updateConstructorKey } from '../../thunks'

const makerKeySchema = yup.object().shape({
  clientId: yup.string().required('ID Client est obligatoire'),
  clientPassword: yup.string().required('Mot de passe client est obligatoire'),
  filePassword: yup.string().required('Mot de passe fichier est obligatoire'),
  makerId: yup.number().required('Constructeur est obligatoire'),
  file: yup.mixed().required('Fichier est obligatoire'),
})

interface IProps {
  open: boolean
  setOpen: (isOpen: boolean) => void
  onClose?: () => void
  centerId: number
  editingKey: ConstructorKeyDto | null
}

export default function ResponsiveDialog(props: IProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(makerKeySchema),
    mode: 'onChange',
    defaultValues:
      props.editingKey &&
      ({
        clientId: props.editingKey.clientId,
        clientPassword: props.editingKey.clientSecret,
        filePassword: props.editingKey.keyPassword,
        makerId: props.editingKey.makerId,
      } as any),
  })
  const [showClientPassword, setShowClientPassword] = useState(false)
  const [showFilePassword, setShowFilePassword] = useState(false)

  const makers = useAppSelector(
    ({ centersReducer }) => centersReducer.makers.data,
  )

  const dispatch = useAppDispatch()

  const toggleClientPasswordVisibility = () => {
    setShowClientPassword(!showClientPassword)
  }

  const toggleFilePasswordVisibility = () => {
    setShowFilePassword(!showFilePassword)
  }

  const onSubmit = (values: any) => {
    const dto: CreateConstructorKeyDto = {
      clientSecret: values.clientPassword,
      filePassword: values.filePassword,
      centerId: props.centerId,
      file: values.file,
      makerId: values.makerId,
      clientId: values.clientId,
    }

    if (props.editingKey) {
      dispatch(
        updateConstructorKey({
          id: props.editingKey.id,
          dto,
        }) as any,
      )
        .unwrap()
        .then(() => {
          onClose()
        })
    } else {
      dispatch(createConstructorKey(dto) as any)
        .unwrap()
        .then(() => {
          onClose()
        })
    }
  }

  useEffect(() => {
    if (!props.open) {
      reset()
    }
  }, [props.open, reset])

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(
        getCompany(1000, 0, '', false, CompanyType.MAKER, resolve, reject),
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const center = {
    name: 'Centre de test',
    clientId: '123456789',
  }
  const maker = {
    name: 'BIOTRONIK',
    makerId: '12',
  }

  const onClose = () => {
    props.setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: 20,
          width: '50%',
        },
      }}
    >
      <Loader text="Enregistrement de la clé" open={false} />
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          color: 'white',
          fontWeight: '700',
          fontSize: '21px',
        }}
      >
        {'Ajout une clé constructeur'}
        <IconButton
          sx={{ position: 'absolute', right: '20px' }}
          onClick={onClose}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            sx={{
              width: '100%',
              padding: '20px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#3f3f3f',
                textTransform: 'uppercase',
                fontSize: '1rem',
              }}
            >
              Centre : {center.name}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: '#3f3f3f',
                textTransform: 'uppercase',
                fontSize: '1rem',
              }}
            >
              Constructeur : {maker.name}
            </Typography>

            {/* File input */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                margin: '8px 0',
              }}
            >
              <FormLabel sx={{ width: '100%' }}>Fichier</FormLabel>
              <input
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    setValue('file', e.target.files[0] as any)
                  }
                }}
              />
              {errors.file?.message && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  {errors.file?.message as string}
                </Typography>
              )}
            </Box>

            <TextField
              {...register('clientId')}
              error={Boolean(errors.clientId)}
              helperText={errors.clientId?.message as string}
              fullWidth
              margin="dense"
              label="ID Client"
              variant="outlined"
            />

            <TextField
              {...register('clientPassword')}
              type={showClientPassword ? 'text' : 'password'}
              error={Boolean(errors.clientPassword)}
              helperText={errors.clientPassword?.message as string}
              fullWidth
              margin="dense"
              label="Mot de passe client"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleClientPasswordVisibility}
                      edge="end"
                    >
                      {showClientPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              {...register('filePassword')}
              type={showFilePassword ? 'text' : 'password'}
              error={Boolean(errors.filePassword)}
              helperText={errors.filePassword?.message as string}
              fullWidth
              margin="dense"
              label="Mot de passe fichier"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleFilePasswordVisibility}
                      edge="end"
                    >
                      {showFilePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!props.editingKey && (
              <Select {...register('makerId')} fullWidth margin="dense">
                {makers.map((maker) => (
                  <MenuItem key={maker.id} value={maker.id}>
                    {maker.companyName}
                  </MenuItem>
                ))}
              </Select>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <IconButton type="submit">
                <SaveIcon
                  sx={{
                    fontSize: '36px',
                    color: '#32CD32',
                  }}
                />
              </IconButton>
            </Box>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  )
}
