import { PropsFromReduxMessaging } from '../containers/MessagingContainer'
import {
  DataGridPro,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { Toolbar } from './Table/Toolbar'
import Loader from '../../common/Loader'
import { Box, Tab, Tabs } from '@mui/material'
import history from '../../../routes/history'
import { toast } from 'react-toastify'

const MessagingComponent = (props: PropsFromReduxMessaging) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(20)
  const [tab, setTab] = useState<0 | 1 | 2>(0)
  const [page, setPage] = useState<number>(0)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setLoading(true)
    props
      .getUserRoles()
      .then(() => {
        fetchMessages()
      })
      .catch(() => toast.error('Impossible de charger les messages'))
  }, [rowsPerPage, page]) //eslint-disable-line

  useEffect(() => {
    setData(setDataFiltered(tab))
  }, [tab, searchText]) //eslint-disable-line

  const fetchMessages = () => {
    props
      .getMessages(rowsPerPage, page)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        toast.error('Impossible de charger les messages')
      })
  }

  const setDataFiltered = (index: number) => {
    const messages = props.messages ? [...props.messages] : []

    messages
      .filter(
        (m) =>
          m.senderEmail.includes(searchText) ||
          m.messageContent.includes(searchText) ||
          m.title.includes(searchText),
      )
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )

    let response = []

    if (messages) {
      switch (index) {
        case 0:
          response = messages.filter(
            (el: any) =>
              el.senderEmail !== props.user.mail &&
              el.sendTo[0]?.read === false,
          )
          break
        case 1:
          response = messages.filter(
            (el: any) =>
              el.senderEmail !== props.user.mail && el.sendTo[0]?.read === true,
          )
          break
        case 2:
          response = messages.filter(
            (el: any) => el.senderEmail === props.user.mail,
          )
          break
      }
    }
    return response
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: tab === 2 ? 'À' : 'De',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.senderEmail}`,
    },
    {
      field: 'col2',
      headerName: 'Objet',
      flex: 1,
      sortable: false,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => `${params.row.title}`,
    },
    {
      field: 'col3',
      headerName: 'Envoyé le',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const createdDate: Date = new Date(params.row.createdAt)
        return `${createdDate.toLocaleDateString()} à ${
          createdDate.getHours() > 9
            ? createdDate.getHours()
            : '0' + createdDate.getHours()
        }:${
          createdDate.getMinutes() > 9
            ? createdDate.getMinutes()
            : '0' + createdDate.getMinutes()
        }`
      },
    },
    {
      field: 'col4',
      headerName: 'Message',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.entity.messageContent}`,
    },
    {
      field: 'col5',
      headerName: 'Fichier',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.storedFiles ? '' : ''}`,
    },
  ]

  return (
    <Box sx={{ height: 'calc(100vh - 133px)', width: '100%' }}>
      <Loader open={loading} text="En attente" />
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ffa726',
            display: 'flexWrap',
          },
        }}
        onChange={(_, newTab) => setTab(newTab)}
        textColor="primary"
        value={tab}
      >
        <Tab
          label={`Messages non lus : ${
            loading ? 0 : setDataFiltered(0).length
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Messages lus : ${loading ? 0 : setDataFiltered(1).length}`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Messages envoyés : ${
            loading ? 0 : setDataFiltered(2).length
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
      </Tabs>

      <DataGridPro
        rows={data}
        columns={columns}
        onRowClick={(message) => {
          props.setMessageDetails(message.id as number)
          history.push(`messages/${message.id}`)
        }}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => {
          setRowsPerPage(newPageSize)
          setPage(0)
        }}
        components={{
          Toolbar,
        }}
        onPageChange={(newPage) => setPage(newPage)}
        componentsProps={{
          toolbar: {
            title: 'Messages',
            fetchMessages,
            getDoctors: props.getDoctors,
            getCenters: props.getCompany,
            sendMessage: props.sendMessage,
            searchText,
            setSearchText,
            getDoctorsSummary: props.getDoctorsSummary,
            getCentersSummary: props.getCompaniesSummary,
            addDoctor: props.addDoctor,
            getDoctorAutocomplete: props.getDoctorAutocomplete,
            getCountries: props.getCountries,
          },
        }}
        pagination
        initialState={{
          pagination: {
            page,
          },
        }}
        rowHeight={30}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        disableColumnMenu
        disableSelectionOnClick
        sx={{
          border: 'none',
          padding: '3px 15px',
          cursor: 'pointer',
        }}
      />
    </Box>
  )
}

export default MessagingComponent
