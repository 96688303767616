import { REFACTOR_API_URL } from '../../auth/constants'
import { API_URL } from '../constants'

export const homePatientExportSummary = (token: string): {} => {
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/alerts/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const homeTransmitterSummary = (token: string): {} => {
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/transmitter/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${REFACTOR_API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const homePatientSummary = (token: string): {} => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const homeBillingSummary = (token: string): {} => {
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/billing/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
export const homePrescriptionsSummary = (token: string): {} => {
  console.log(`service`)
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/prescriptions/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const refreshTokenService = (token: string): {} => {
  const ENDPOINT: RequestInfo = `${API_URL}/refresh-token`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
