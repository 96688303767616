export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED'

export const GET_COMPANIES_SUMMARY = 'GET_COMPANIES'
export const GET_COMPANIES_SUMMARY_SUCCESS = 'GET_COMPANIES_SUMMARY_SUCCESS'
export const GET_COMPANIES_SUMMARY_FAILED = 'GET_COMPANIES_SUMMARY_FAILED'

export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID'
export const GET_COMPANY_BY_ID_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS'
export const GET_COMPANY_BY_ID_FAILED = 'GET_COMPANY_BY_ID_FAILED'

export const CREATE_COMPANY = 'CREATE_COMPANY'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

export const CREATE_USER_ROLE = 'CREATE_USER_ROLE'
export const CREATE_USER_ROLE_SUCCESS = 'CREATE_USER_ROLE_SUCCESS'
export const CREATE_USER_ROLE_FAILED = 'CREATE_USER_ROLE_FAILED'

export const CREATE_USER_ADDRESS = 'CREATE_USER_ADDRESS'
export const CREATE_USER_ADDRESS_SUCCESS = 'CREATE_USER_ADDRESS_SUCCESS'
export const CREATE_USER_ADDRESS_FAILED = 'CREATE_USER_ADDRESS_FAILED'

export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS'
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS'
export const UPDATE_USER_ADDRESS_FAILED = 'UPDATE_USER_ADDRESS_FAILED'

export const FIND_COUNTRIES = 'FIND_COUNTRIES'
export const FIND_COUNTRIES_SUCCESS = 'FIND_COUNTRIES_SUCCESS'
export const FIND_COUNTRIES_FAILED = 'FIND_COUNTRIES_FAILED'

export const FIND_ROLES = 'FIND_ROLES'
export const FIND_ROLES_SUCCESS = 'FIND_ROLES_SUCCESS'
export const FIND_ROLES_FAILED = 'FIND_ROLES_FAILED'

export const FIND_CENTER_USERS = 'FIND_CENTER_USERS'
export const FIND_CENTER_USERS_SUCCESS = 'FIND_CENTER_USERS_SUCCESS'
export const FIND_CENTER_USERS_FAILED = 'FIND_CENTER_USERS_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const CREATE_CONSTR_KEY = 'CREATE_CONSTR_KEY'
export const CREATE_CONSTR_KEY_SUCCESS = 'CREATE_CONSTR_KEY_SUCCESS'
export const CREATE_CONSTR_KEY_FAILED = 'CREATE_CONSTR_KEY_FAILED'

export const UPDATE_CONSTR_KEY = 'UPDATE_CONSTR_KEY'
export const UPDATE_CONSTR_KEY_SUCCESS = 'UPDATE_CONSTR_KEY_SUCCESS'
export const UPDATE_CONSTR_KEY_FAILED = 'UPDATE_CONSTR_KEY_FAILED'

export const GET_CONSTR_KEYS = 'GET_CONSTR_KEYS'
export const GET_CONSTR_KEYS_SUCCESS = 'GET_CONSTR_KEYS_SUCCESS'
export const GET_CONSTR_KEYS_FAILED = 'GET_CONSTR_KEYS_FAILED'

export const DELETE_CONSTR_KEY = 'DELETE_CONSTR_KEY'
export const DELETE_CONSTR_KEY_SUCCESS = 'DELETE_CONSTR_KEY_SUCCESS'
export const DELETE_CONSTR_KEY_FAILED = 'DELETE_CONSTR_KEY_FAILED'
