import MessageDetailsContainer from '../containers/MessageDetailsContainer'
import MessageContainer from '../containers/MessagingContainer'
import * as messagingRoutes from './messaging'

export const messageRoutes = [
  {
    key: 'message',
    exact: true,
    path: messagingRoutes.MESSAGE,
    component: MessageContainer,
  },
  {
    key: 'message-details',
    exact: true,
    path: messagingRoutes.MESSAGE_DETAILS,
    component: MessageDetailsContainer,
  },
]
