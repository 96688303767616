import {RootState} from '../../../store'
import {connect, ConnectedProps} from 'react-redux'
import {withRouter} from 'react-router-dom'
import ReportPDF from '../components/ReportPDF'
import {getPDF, setPDFViewed} from '../actions'

const mapStateToProps = (state: RootState) => {
    return {

    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setPDFViewed: (exportId: number, reportId: number) => {
            return new Promise((resolve: any, reject: any) => {
                dispatch(setPDFViewed(exportId, reportId, resolve, reject))
            })
        },
        getPDF: (exportId: number, reportId: number) => {
            return new Promise((resolve: (pdf: Blob) => void, reject: any) => {
                dispatch(getPDF(exportId, reportId, resolve, reject))
            })
        },
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxReportPDF = ConnectedProps<typeof connector>

export default withRouter(connector(ReportPDF))
