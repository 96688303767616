import { REACT_APP_OIDC_REDIRECT_URI } from '../../../helpers/env'
import { API_URL, REFACTOR_API_URL } from '../constants'

export const loginUserService = (mail: string, pwd: string): {} => {
  const ENDPOINT: RequestInfo = `${API_URL}/login`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
    },
    mode: 'cors',
    body: JSON.stringify({ mail, pwd }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getTokenPSCService = (code: string) => {
  const ENDPOINT: RequestInfo = `${API_URL}/psc-token`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
    },
    mode: 'cors',
    body: JSON.stringify({ redirect_uri: REACT_APP_OIDC_REDIRECT_URI, code }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const loginPSCService = (access_token: string) => {
  const ENDPOINT: RequestInfo = `${API_URL}/psc`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
    },
    mode: 'cors',
    body: JSON.stringify({ access_token }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const resetPwdService = (mail: string) => {
  const ENDPOINT: RequestInfo = `${API_URL}/reset_pwd/${mail}`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `${mail}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      // console.log(e)
    })
}

export const changePwdService = (token: string, mail: string, pwd: string) => {
  const ENDPOINT: RequestInfo = `${API_URL}/update_pwd`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({ mail, pwd }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      // console.log(e)
    })
}
export const getBillingTokenService = (token: string, code: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_API_URL}/auth/stellair`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({ code }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      // console.log(e)
    })
}
