export const getFullPdf = (token: string, path: string, url: string) => {
  const ENDPOINT: RequestInfo = `${path}/${url}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${path}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.blob()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}
